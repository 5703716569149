import app from '../app';
import { closeModal, nonceGenerator, openModal, s3_ProcessPaxInfo, formatQuarterEndDate, getQuarter } from '../utils';

app.controller('ViewBookingController', function ($scope, $rootScope, $location, RESOURCES, viewBookingService, afterSalesService, globalData, updateEmployeeService, md5, $translate) {

    //init(); // function moved to ng-init on the page

  $scope.init = function () {
        $scope.loggedUser = $rootScope.loggedInUser;
        $scope.showBooking = false;
        $scope.retrieveError = '';
        $scope.booking = {};
        $scope.seats = {};
        $scope.ssr = [];
        $scope.showExch = false;
    $scope.showCancel = false;
    $scope.showEmail2Cancel = false;
        $scope.refCode = globalData.getRefCode();
        $scope.bookingReference = globalData.getRefCode();
        $scope.currency = $scope.loggedUser.ARIA_User.Default_Currency;
        $scope.showMeals = false;
        $scope.showSeats = false;
        $scope.paxMealList = $rootScope.paxMealList;
        $scope.erShowWarningSameQuarter = false;
        $scope.erShowWarningPastQuarter = false;
        $scope.erQuarterEndDate = '';
        $scope.allSegmentsCancelled = false;

        if ($scope.bookingReference) {
            $scope.noResults2 = false;
            //console.log("Booking Reference = " + $scope.bookingReference);
            if (globalData.getElgBkgData()) {
                var msgs = [];
                if (globalData.getElgBkgData().tnr.tnr_msg) {
                    msgs = globalData.getElgBkgData().tnr.tnr_msg;
                    if (msgs) {
                        for (var i = 0; i < msgs.length; i++) {
                            if (msgs[i]) {
                                if ((msgs[i].msg_text.indexOf('seating preferences') > -1) || (msgs[i].msg_text.indexOf('Update Failed') > -1)) {
                                    $scope.createBkgWarning = msgs[i].msg_text;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            $scope.findBooking();
        } else {
            $scope.getBkgHistory();
        }
    };

    $scope.startsWith = function (bRef, viewValue) {
        if (bRef != null) {
            return bRef.substr(0, viewValue.length).toLowerCase() == viewValue.toLowerCase();
        } else {
            return false;
        }
    };

    $scope.findBooking = function () {
        $scope.seats = {}; //Clear the seats.

        if (($scope.bookingReference) && ($scope.noResults2 == false)) {
            openModal();
            var mres1 = RESOURCES.HOST;
            var nnce = nonceGenerator();
            var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
            var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

            //recall booking
            var gBkgData; //= globalData.getElgBkgData();

            viewBookingService.getBooking(userid, rndString, nnce, $scope.bookingReference, mres1, gBkgData, $scope.currency)
                .then(function (res) {
                    //successs
                    if (res.wsi_error) {
                        $scope.booking = {};
                        $scope.showBooking = false;
                        if (res.wsi_error.err_ers_text) {
                            $scope.retrieveError = res.wsi_error.err_ers_text;
                        } else {
                            $scope.retrieveError = res.wsi_error.err_wsi_text;
                        }
                        closeModal();
                    } else {
                        res.BookingServiceOut = s3_ProcessPaxInfo(res.BookingServiceOut);
                        $scope.booking = res;
                        if ($scope.booking.BookingServiceOut.tnr) {
                            if ($scope.booking.BookingServiceOut.tnr.tnr_segments) {
                                $scope.milesUsed = 0;
                                $scope.showBooking = true;
                                $scope.retrieveError = '';
                                globalData.setRefCode(undefined);
                                $scope.bookingType = $scope.booking.BookingServiceOut.tnr.tnr_booking_type;
                                $scope.isER = $scope.bookingType === 'R';
                                $scope.bookingTypeText = globalData.getBookingTypeText($scope.bookingType);
                                $translate($scope.bookingTypeText).then(function (txtMsg) {
                                    $scope.bookingTypeText = txtMsg;
                                });

                                for (var iCount = 0; iCount <= $scope.booking.BookingServiceOut.tnr.tnr_segments.length - 1; iCount++) {
                                    if ($scope.booking.BookingServiceOut.tnr.tnr_segments[iCount].segt_carrier == 'ER') {
                                        if ($scope.booking.BookingServiceOut.tnr.tnr_segments[iCount].segt_class.substring(0, 1) == 'A') {
                                            $scope.milesUsed += $scope.booking.BookingServiceOut.tnr.tnr_segments[iCount].products.filter(prod => !prod.cancelled && !prod.provisional).length * 2;
                                        } else if ($scope.booking.BookingServiceOut.tnr.tnr_segments[iCount].segt_class.substring(0, 1) == 'H') {
                                            $scope.milesUsed += $scope.booking.BookingServiceOut.tnr.tnr_segments[iCount].products.filter(prod => !prod.cancelled && !prod.provisional).length * 2;
                                        } else if ($scope.booking.BookingServiceOut.tnr.tnr_segments[iCount].segt_class.substring(0, 1) == 'B') {
                                            $scope.milesUsed += $scope.booking.BookingServiceOut.tnr.tnr_segments[iCount].products.filter(prod => !prod.cancelled && !prod.provisional).length * 1;
                                        } else {
                                            $scope.booking.BookingServiceOut.tnr.tnr_segments[iCount].segt_class_desc = $scope.booking.BookingServiceOut.tnr.tnr_segments[iCount].segt_class;
                                        }
                                    }
                                }

                                $scope.showMeals = canApplyMeals();
                                $scope.showSeats = canShowSeats();

                                globalData.setElgBkgData($scope.booking.BookingServiceOut);
                                ////////////////////////////////////////////////////
                                // set exchange/cancel validity
                                // Duty : Exchange anytime, Cancel only before departure
                                // FIPS : Exchange before departure, no cancellation
                                ////////////////////////////////////////////////////
                                //console.log("findBooking:Booking Type = " + $scope.bookingType);
                                $scope.showExch = false;
                                $scope.showCancel = false;
                                if ($scope.bookingType == 'D') {
                                    $scope.showExch = true;
                                    if (globalData.hasSegDepartingInFuture($scope.booking.BookingServiceOut)) {
                                        $scope.showCancel = true;
                                    }
                                } else if ($scope.bookingType == 'R') {
                                    if (
                                        globalData.hasBookingCreatedInSameQuater(
                                            $scope.booking.BookingServiceOut
                                        ) &&
                                        $scope.booking.BookingServiceOut.tnr.tnr_segments.length >
                                        0 &&
                                        $scope.booking.BookingServiceOut.tnr.tnr_tkts.length > 0
                                    ) {
                                        $scope.showCancel = true;
                                        $scope.erShowWarningSameQuarter = true;
                                        $scope.erQuarterEndDate = formatQuarterEndDate(new Date());
                                    } else {
                                        $scope.erShowWarningPastQuarter = true;
                                        $scope.erQuarterEndDate = formatQuarterEndDate($scope.booking.BookingServiceOut.tnr.tnr_pnr_create_time);
                                        if (
                                            $scope.booking.BookingServiceOut.tnr.tnr_segments.length >
                                            0 &&
                                            $scope.booking.BookingServiceOut.tnr.tnr_tkts.length > 0
                                        ) {
                                            $scope.showCancel = true;
                                        }
                                    }

                                    if (areAllSegmentsCancelled($scope.booking.BookingServiceOut)) {
                                        $translate("Text239").then(function (txtMsg) {
                                            $scope.retrieveError = txtMsg;
                                        });

                                        $scope.allSegmentsCancelled = true;
                                    }
                                } else if ($scope.bookingType == 'F' || $scope.bookingType == 'B') {
                                    $scope.showCancel = false;
                                    if (globalData.hasSegDepartingInFuture($scope.booking.BookingServiceOut)) {
                                      $scope.showExch = true;

				      if ($scope.bookingType == 'B') {
					let show_cancel = true;
					// check to see if there are no refund payments
					$scope.booking.BookingServiceOut.tnr.tnr_tkts.forEach(tkt => {
					  if (!tkt.tktd_can_be_cancelled) {
					    show_cancel = false;
					  }
					});
					
					$scope.booking.BookingServiceOut.payments.forEach(payment => {
					  if (payment.payment_type === 'refund') {
					    show_cancel = false;
					  }
					});

					if (show_cancel) {
					  // check to see if we've got the no of vouchers and dependants in the payments
					  $scope.booking.BookingServiceOut.payments.forEach(payment => {
					    if (payment.method === "Blue_Voucher") {
					      if (!Number.isFinite(Number(payment.externalReference))) {
						show_cancel = false;
						$scope.showEmail2Cancel = true;
					      }

					      if (payment.reference.split("-").length != 2) {
						show_cancel = false;
						$scope.showEmail2Cancel = true;
					      }
					    }
					  });
					}
					
					$scope.showCancel = show_cancel;
				      }
                                    }
                                }
                                $scope.showSSR = canApplyMeals();
                                if ($scope.showSSR) {
                                    if ($scope.paxMealList) {
                                        getSSR();
                                    } else {
                                        loadMealList();
                                    }

                                }
                                getSeats(res);
                                if ($rootScope.showVoucherMsg === true) {
                                    $scope.showVoucherMsg = true;
                                    $rootScope.showVoucherMsg = false; //Clear the variable in rootscope as this message is displayed only once.
                                    $translate('Text364').then(function (txtMsg) {
                                        $scope.voucherMsg = txtMsg.replace('XXX', $rootScope.voucherNumber);
                                    });
                                } else {
                                    $scope.showVoucherMsg = false;
                                    $scope.voucherMsg = '';
                                }
                            }

                            if ($scope.bookingType === 'D' || $scope.bookingType === 'P' || $scope.bookingType === 'F' || $scope.bookingType === 'M' || $scope.bookingType === 'I') {
                                if ($rootScope.pahPax) {
                                    if ($rootScope.pahPax.length > 0) {
                                        if ($rootScope.pahPax[0].type = 'PAH') {
                                            $scope.showDownload = true;
                                            if ($scope.booking.BookingServiceOut.tnr.tnr_names) {
                                                for (var kCount = 0; kCount < $rootScope.pahPax.length; kCount++) {
                                                    for (var jCount = 0; jCount < $scope.booking.BookingServiceOut.tnr.tnr_names.length; jCount++) {
                                                        if ($scope.booking.BookingServiceOut.tnr.tnr_names[jCount].name_surname.toUpperCase() ===
                                                            $rootScope.pahPax[kCount].lastName.toUpperCase() &&
                                                            $scope.booking.BookingServiceOut.tnr.tnr_names[jCount].name_initials.toUpperCase() ===
                                                            $rootScope.pahPax[kCount].firstName.toUpperCase()) {
                                                            $scope.booking.BookingServiceOut.tnr.tnr_names[jCount].name_url = $rootScope.pahPax[kCount].url;
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            $scope.showDownload = false;
                                        }
                                    } else {
                                        $scope.showDownload = false;
                                    }
                                } else {
                                    $scope.showDownload = false;
                                }
                            }
                        }

                        if ($scope.booking.BookingServiceOut.tnr.tnr_no_itin) {
                            // Set global ref code to undefined
                            globalData.setRefCode(undefined);
                            $scope.showBooking = false;

                            $translate('Text239').then(function (txtMsg) {
                                $scope.retrieveError = txtMsg;
                            });
                            //$scope.retrieveError = 'Booking does not have active segments.';
                        }

                        $scope.NonCancelledSegments = $scope.booking.BookingServiceOut.tnr.tnr_segments
                            .filter(seg => seg.products.some((prod) => !prod.cancelled));

                        $scope.CancelledTickets = $scope.booking.BookingServiceOut.tnr.tnr_tkts
                            .filter(tkt => tkt.tktd_status === 0)
                            .map(tkt => {
                                let seg = $scope.booking.BookingServiceOut.tnr.tnr_segments.find(seg => seg.segt_num === tkt.tktd_seg_nums[0]);
                                let paxName = $scope.booking.BookingServiceOut.tnr.tnr_names.find(name => name.s3_passenger_id === tkt.s3_tktd_passenger_id);

                                return {
                                    ...seg,
                                    passengerName: `${paxName.name_initials} ${paxName.name_surname}`,
                                    tkt
                                };
                            });

		      if ($scope.booking.BookingServiceOut.isABSADS) {
			$scope.showExch = false;
		      }

                        closeModal();
                    }
                }, function (err) {
                    //error
                    $scope.booking = {};
                    $scope.showBooking = false;

                    $translate('Text240').then(function (txtMsg) {
                        $scope.retrieveError = txtMsg;
                    });
                    //$scope.retrieveError = "There was an error recalling the booking. Please try again.";
                    closeModal();
                });
        } else {
            $scope.booking = {};
            $scope.showBooking = false;

            $translate('Text241').then(function (txtMsg) {
                $scope.retrieveError = txtMsg;
            });
            //$scope.retrieveError = "Invalid booking reference.";
        }
    };

    function areAllSegmentsCancelled(booking) {
        let allCancelled = true;

        booking.tnr.tnr_segments.forEach(segment => {
            segment.products.forEach(product => {
                if (!product.cancelled) {
                    allCancelled = false;
                }
            });
        });

        return allCancelled;
    }

    function loadMealList() {
        let mealListRequest = {
            'RetMealsServiceIn': {
                'reference': $scope.booking.BookingServiceOut.tnr.tnr_ers_pnr,
                's3_currency': $scope.currency
            }
        };
        let sUrl = RESOURCES.HOST;
        afterSalesService.getMealList(sUrl, mealListRequest)
            .then(
                function (res) {
                    if (res.wsi_error) {
                        $scope.paxMealList = undefined;
                    } else {
                        $scope.paxMealList = res.mealsServiceOut.meals;
                        $rootScope.paxMealList = $scope.paxMealList;
                    }
                    getSSR();
                },
                function (err) {
                    $scope.paxMealList = undefined;
                    getSSR();
                });
    }

    function getSeats(response) {
        //fetch seats data from response
        var strIndex;
        if (response.BookingServiceOut.seats) {
            for (var iSeg = 0; iSeg < response.BookingServiceOut.seats.length; iSeg++) {
                for (var iPax = 0; iPax < response.BookingServiceOut.seats[iSeg].seats_placements.length; iPax++) {
                    strIndex = response.BookingServiceOut.seats[iSeg].seats_seg_num + '-' + (iPax + 1);
                    if (response.BookingServiceOut.seats[iSeg].seats_placements[iPax].placement_seats[0].seat_num) {
                        $scope.seats[strIndex] = 'Coach: ' + response.BookingServiceOut.seats[iSeg].seats_placements[iPax].placement_seats[0].seat_coach_num + ', Seat: ' + response.BookingServiceOut.seats[iSeg].seats_placements[iPax].placement_seats[0].seat_num;
                    } else {
                        $scope.seats[strIndex] = 'Coach: ' + response.BookingServiceOut.seats[iSeg].seats_placements[iPax].placement_seats[0].seat_coach_num + ', Seat: ' + '---';
                    }
                }
            }
        } else {
            if (response.BookingServiceOut.tnr.tnr_segments) {
                for (var jSeg = 0; jSeg < response.BookingServiceOut.tnr.tnr_segments.length; jSeg++) {
                    for (var kPax = 0; kPax < response.BookingServiceOut.tnr.tnr_names.length; kPax++) {
                        strIndex = response.BookingServiceOut.tnr.tnr_segments[jSeg].segt_num + '-' + (kPax + 1);
                        $scope.seats[strIndex] = 'Coach: - , Seat: - ';
                    }
                }
            }
        }
    }

    $scope.displaySeats = function (strIndex, sDate, sTime) {
        var result;
        if ($scope.seats[strIndex]) {
            result = $scope.seats[strIndex];
        } else {
            if (globalData.isPastDate(sDate, sTime) === true) {
                result = '';
            } else {
                var idx = strIndex.split('-');
                if (parseInt(idx[1]) == parseInt('1')) {

                    $translate('Text244').then(function (txtMsg) {
                        result = txtMsg;
                    });
                    // result = "Non-Reservable";
                }
            }
        }
        return result;
    };

    function getSSRFlag() {
        var result = false;
        $scope.booking.BookingServiceOut.tnr.tnr_segments.forEach(segment => {
            segment.passengerMeals.forEach(meal => {
                if (meal.mealCode && meal.mealCode.trim() != '') {
                    result = true;
                }
            });
        });
        // for (var i = 0; i < $scope.ssr.length; i++) {
        //   if ($scope.ssr[i].meal_tfax) {
        //     result = true;
        //     break;
        //   }
        // }
        return result;
    }

    function getSSR() {
        $scope.ssr = [];
        $scope.booking.BookingServiceOut.tnr.tnr_segments.forEach(segment => {
            segment.passengerMeals.forEach(passengerMeal => {
                let name = $scope.booking.BookingServiceOut.tnr.tnr_names.filter(name => name.s3_passenger_id === passengerMeal.passengerId);
                let mealName;
                if ($scope.paxMealList) {
                    if (passengerMeal.mealCode && passengerMeal.mealCode.trim() != '') {
                        mealName = $scope.paxMealList.filter(meal => meal.code === passengerMeal.mealCode)[0].name;
                    } else {
                        mealName = 'Regular Meal';
                    }
                } else {
                    mealName = passengerMeal.mealCode;
                }

                let meal_request = {
                    seg_id: segment.segt_num,
                    pax_id: passengerMeal.passengerId,
                    pax_name: name[0].name_initials + ' ' + name[0].name_surname,
                    journey: segment.segt_dep_station_full_name + ' to ' + segment.segt_arr_station_full_name,
                    meal_tfax: mealName
                };

                // only push meal_request if it doesn't already exist in $scope.ssr
                if (!$scope.ssr.some(meal => meal.seg_id === meal_request.seg_id && meal.pax_id === meal_request.pax_id)) {
                    $scope.ssr.push(meal_request);
                }
            });
        });
    }

    $scope.getBkgHistory = function () {
        openModal();
        var mres1 = RESOURCES.HOST;
        $scope.bkgHistory = [];

        var nnce = nonceGenerator();
        var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
        var customerEmail = $rootScope.loggedInUser.ARIA_User.Customer_Email.toLowerCase();
        var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

        updateEmployeeService.getBookingHistory(userid, customerEmail, rndString, nnce, mres1)
            .then(function (res) {
                if (res.Booking_Cases) {
                    $scope.bkgHistory = res.Booking_Cases;
                } else {
                    $scope.bkgHistory = [];
                }
                closeModal();
            }, function (err) {
                //error
                closeModal();
                $scope.bkgHistory = [];
                //alert(err);
            });
    };

    $scope.showAfterSales = function (exFlag) {
        if (exFlag == true) {
            globalData.setAfterSaleFlag('E');
            $location.path('/aftersales');
        } else if (exFlag == false) {
            globalData.setAfterSaleFlag('C');
            $location.path('/aftersales');
        }
    };

    $scope.showChangeMeal = function (flag) {
        $location.path('/meals');
    };

    $scope.showChangeSeats = function (flag) {
        $location.path('/seatmap');
    };

    function canShowSeats() {
        if ($scope.bookingType == 'R') {
            return false;
        }

        let result = false;
        if (globalData.hasSegDepartingInFuture($scope.booking.BookingServiceOut)) {
            result = true;
        }
        return result;
    }

    function canApplyMeals() {
        if ($scope.bookingType == 'R') {
            return false;
        }

        let result = false;
        if (globalData.hasSegDepartingInFuture($scope.booking.BookingServiceOut)) {
            $scope.booking.BookingServiceOut.tnr.tnr_segments.forEach(segment => {
                if (segment.segt_s3_class === 'SP' || segment.segt_s3_class === 'PLUS' || segment.segt_s3_class === 'BP' || segment.segt_s3_class === 'PREMIER') {
                    result = true;
                }
            });
        }
        return result;
    }
});
