import ReactDOM from 'react-dom';
import React from 'react';
import * as NG from 'angular';
import CheckoutComponent, { CheckoutComponentProps } from './checkoutComponent';
import {DeferredPromise} from '../../helpers/deferred-promise';

class CheckoutComponentController implements NG.IController{
  private static readonly $inject: string[] = ['$element', '__ENV', '$http', '$scope', 'globalData'];

  constructor(
    private $element: any,
    private __ENV: any,
    private $http: any,
    private $scope: any,
    private globalData: any
  ) {

  }

  async getPaymentMethods(payload: any) {
    return this.$http.get(`${this.__ENV.PAYMENT_GATEWAY_URL}/payment-methods`, {
      params: payload
    })
      .then(
        (response: any) => ({ data: response.data.pspData }),
        (response: any) => ({response})
      );
  }

  async initiatePayment(payload: any, shouldFinalisePayment = false) {
    this.globalData.dfdPromiseInitiatePayment = new DeferredPromise();
    this.$scope.$parent.initiatePayment(payload, shouldFinalisePayment);
    try {
      const pspData = await this.globalData.dfdPromiseInitiatePayment.promise;

      if (pspData?.data?.action) {
        this.$scope.$parent.closeModal();
        this.$scope.payload = payload;
      }

      return pspData;
    } catch(err) {
      const props = this.getProps();
      ReactDOM.unmountComponentAtNode(this.$element[0]);
      ReactDOM.render(<CheckoutComponent { ...props } />, this.$element[0]);
    }
  }

  async onRedirectData(redirectData?: any | null) {
    const payload = this.$scope.payload;
    this.initiatePayment({ ...payload, actionData: { details: redirectData } }, true);
  }

  $onChanges() {
    const props = this.getProps();
    ReactDOM.render(<CheckoutComponent { ...props } />, this.$element[0]);
  }

  $onDestroy() {
    return ReactDOM.unmountComponentAtNode(this.$element[0]);
  }

  private getProps(): CheckoutComponentProps {
    return {
      adyenClientKey: this.__ENV.ADYEN_CLIENT_KEY,
      currency: this.$scope.$parent.currency,
      amount: this.$scope.$parent.amount,
      getPaymentMethods: this.getPaymentMethods.bind(this),
      initiatePayment: this.initiatePayment.bind(this),
      onRedirectData: this.onRedirectData.bind(this),
      adyenEnvironment: this.__ENV.ADYEN_ENVIRONMENT
    };
  }
}

export class CheckoutComponentWrapper implements NG.IComponentOptions {

  public bindings: any;
  public controller: any;

  constructor(Component: React.FC<CheckoutComponentProps>) {
    const propTypes = Component.propTypes || {};

    this.bindings = this.toBindings(propTypes);

    this.controller = CheckoutComponentController;
  }

  /**
   * Generates angularJs component bindings object from the React component's propTypes8
   * @param propTypes
   * @private
   */
  private toBindings(propTypes: any) {
    const bindings: { [index: string ]: any } = {};

    Object.keys(propTypes).forEach(key => bindings[key] = '<');

    return bindings;
  }
}
