import app from '../app';
import $ from 'jquery';
import { closeModal, nonceGenerator, openModal } from '../utils';

app.controller('LoginController', [ '$scope', '$location', 'RESOURCES', 'LANGUAGES', '$rootScope', 'loginService', 'globalData', 'md5', '$translate', '$http', function ($scope, $location, RESOURCES, LANGUAGES, $rootScope, loginService, globalData, md5, $translate, $http) {

  init();

  function init() {
    $scope.employee = {};
    //$scope.showOptions = false;
    $scope.loginError = '';
    $rootScope.loggedInUser = '';
    $scope.showLogin = true;
    $scope.showChange = false;
    $scope.showForgot = false;
    $scope.showForgotForce = false;
    $('[data-toggle="popover"]').popover();
    $scope.languages = [];
    $scope.languages = LANGUAGES.filter(l => l.code !== 'be');
    $scope.currency = '';
  }

  $scope.changeLanguage = function (langKey) {
    $translate.use(langKey);
  };

  $scope.login = function () {
    $scope.loginError = '';
    $scope.pwdResetMsg = '';
    globalData.clearGlobal();

    var mres1 = RESOURCES.HOST;

    openModal(); //Open the busy indicator.

    var nnce = nonceGenerator();
    var userid = $scope.username.toLowerCase();
    var rndString = md5.createHash(userid + $scope.password + nnce);

    loginService.getData(userid, rndString, mres1, nnce)
      .then(function (res) {
        //Check if there is an error.
        if (res.status) {
          if (res.status == 'FAILURE') {
            $scope.loginError = res.error;
            $scope.showLogin = true;
            $scope.showChange = false;
            $scope.showForgot = false;
            $scope.showForgotForce = false;
          } else if (res.status == 'RESET') {
            $scope.showLogin = false;
            $scope.showChange = true;
            $scope.showForgot = false;
            $scope.showForgotForce = false;
          }
          closeModal();
        } else {
          if (res.ARIA_User.Force_Password_Change === 'true') {
            $scope.showLogin = false;
            $scope.showChange = false;
            $scope.showForgot = true;
            $scope.showForgotForce = true;

            closeModal();
            return;
          }
          //if no error then process the response.
          $scope.employee = res;
          if (!$scope.employee.ARIA_User.Date_of_Employment) {
            $scope.employee.ARIA_User.Date_of_Employment = '';
          }
          $rootScope.loggedInUser = $scope.employee;
          $http.defaults.headers.common.cid = $scope.employee?.ARIA_User?.Aria_User_Name;
          $rootScope.loggedInUser.ARIA_User.password = $scope.password;
          $scope.showLogin = true;
          $scope.showChange = false;
          $scope.showForgot = false;
          $scope.showForgotForce = false;
          $scope.currency = $rootScope.loggedInUser.ARIA_User.Default_Currency;

          //Staff Vouchers
          setStaffVouchers();
          //Momentum Vouchers
          setMomentumVouchers();
          //Staff Vouchers
          setMitieVouchers();

          //set languange
          //---------------------------------------------------------------
          if ($scope.employee.ARIA_User.Default_Language === 'English') {
            $translate.use('en');
          } else if ($scope.employee.ARIA_User.Default_Language === 'French' || $scope.employee.ARIA_User.Default_Language === 'Français') {
            $translate.use('fr');
          } else if ($scope.employee.ARIA_User.Default_Language === 'Dutch' || $scope.employee.ARIA_User.Default_Language === 'Nederlands') {
            $translate.use('be');
          }
          //---------------------------------------------------------------
          globalData.LoadStationList(mres1, $scope.currency)
            .then(function (res) {
              if (res.error) {
                closeModal(); //Close the busy indicator.
              } else {
                globalData.setStaionList(res);
                closeModal(); //Close the busy indicator.
                $location.path('/');
              }
            });
        }
      }, function (err) {
        $rootScope.loggedInUser = '';
        closeModal(); //Close the busy indicator.
      });
  };

  function setStaffVouchers() {
    //Calculate vouchers current balance.
    if ($scope.employee.ARIA_User.Staff_Voucher === 'true') {
      $scope.employee.ARIA_User.Staff_Voucher_Issued = [];
      if ($scope.employee.ARIA_User.Voucher_Issued) {
        for (var ic = 0; ic < $scope.employee.ARIA_User.Voucher_Issued.length; ic++) {
          if ($scope.employee.ARIA_User.Voucher_Issued[ic].Voucher_Type === 'B') {
            $scope.employee.ARIA_User.Staff_Voucher_Issued.push($scope.employee.ARIA_User.Voucher_Issued[ic]);
          }
        }
      }
      if ($scope.employee.ARIA_User.Staff_Voucher_Issued) {
        if ($scope.employee.ARIA_User.Staff_Voucher_Issued.length > 0) {
          $scope.employee.ARIA_User.Staff_Voucher_Current_Balance = GetSVCurrentBalance($scope.employee.ARIA_User.Staff_Voucher_Issued);
        } else {
          $scope.employee.ARIA_User.Staff_Voucher_Current_Balance = 0;
        }
      } else {
        $scope.employee.ARIA_User.Staff_Voucher_Current_Balance = 0;
      }
    } else {
      $scope.employee.ARIA_User.Staff_Voucher_Current_Balance = 0;
    }
    //order Voucher expiry date in ascendeing order.
    if ($scope.employee.ARIA_User.Staff_Voucher_Issued) {
      if ($scope.employee.ARIA_User.Staff_Voucher_Issued.length > 0) {
        $scope.employee.ARIA_User.Staff_Voucher_Issued = $scope.employee.ARIA_User.Staff_Voucher_Issued.sort(function (a, b) {
          return Date.parse(a.Voucher_Expiry) > Date.parse(b.Voucher_Expiry);
        });
      }
    }
  }

  function setMomentumVouchers() {
    //Calculate vouchers current balance.
    if ($scope.employee.ARIA_User.Momentum_Voucher === 'true') {
      $scope.employee.ARIA_User.Momentum_Voucher_Issued = [];
      if ($scope.employee.ARIA_User.Voucher_Issued) {
        for (var ic = 0; ic < $scope.employee.ARIA_User.Voucher_Issued.length; ic++) {
          //console.log($scope.employee.ARIA_User.Voucher_Issued[ic].Voucher_Type);
          if ($scope.employee.ARIA_User.Voucher_Issued[ic].Voucher_Type === 'M') {
            $scope.employee.ARIA_User.Momentum_Voucher_Issued.push($scope.employee.ARIA_User.Voucher_Issued[ic]);
          }
        }
      }
      if ($scope.employee.ARIA_User.Momentum_Voucher_Issued) {
        if ($scope.employee.ARIA_User.Momentum_Voucher_Issued.length > 0) {
          $scope.employee.ARIA_User.Momentum_Voucher_Current_Balance = GetSVCurrentBalance($scope.employee.ARIA_User.Momentum_Voucher_Issued);
        } else {
          $scope.employee.ARIA_User.Momentum_Voucher_Current_Balance = 0;
        }
      } else {
        $scope.employee.ARIA_User.Momentum_Voucher_Current_Balance = 0;
      }
    } else {
      $scope.employee.ARIA_User.Momentum_Voucher_Current_Balance = 0;
    }
    //order Voucher expiry date in ascendeing order.
    if ($scope.employee.ARIA_User.Momentum_Voucher_Issued) {
      if ($scope.employee.ARIA_User.Momentum_Voucher_Issued.length > 0) {
        $scope.employee.ARIA_User.Momentum_Voucher_Issued = $scope.employee.ARIA_User.Momentum_Voucher_Issued.sort(function (a, b) {
          return Date.parse(a.Voucher_Expiry) > Date.parse(b.Voucher_Expiry);
        });
      }
    }
  }

  function setMitieVouchers() {
    //Calculate vouchers current balance.
    if ($scope.employee.ARIA_User.Mitie_Voucher === 'true') {
      $scope.employee.ARIA_User.Mitie_Voucher_Issued = [];
      if ($scope.employee.ARIA_User.Voucher_Issued) {
        for (var ic = 0; ic < $scope.employee.ARIA_User.Voucher_Issued.length; ic++) {
          if ($scope.employee.ARIA_User.Voucher_Issued[ic].Voucher_Type === 'I') {
            $scope.employee.ARIA_User.Mitie_Voucher_Issued.push($scope.employee.ARIA_User.Voucher_Issued[ic]);
          }
        }
      }
      if ($scope.employee.ARIA_User.Mitie_Voucher_Issued) {
        if ($scope.employee.ARIA_User.Mitie_Voucher_Issued.length > 0) {
          $scope.employee.ARIA_User.Mitie_Voucher_Current_Balance = GetSVCurrentBalance($scope.employee.ARIA_User.Mitie_Voucher_Issued);
        } else {
          $scope.employee.ARIA_User.Mitie_Voucher_Current_Balance = 0;
        }
      } else {
        $scope.employee.ARIA_User.Mitie_Voucher_Current_Balance = 0;
      }
    } else {
      $scope.employee.ARIA_User.Mitie_Voucher_Current_Balance = 0;
    }
    //order Voucher expiry date in ascendeing order.
    if ($scope.employee.ARIA_User.Mitie_Voucher_Issued) {
      if ($scope.employee.ARIA_User.Mitie_Voucher_Issued.length > 0) {
        $scope.employee.ARIA_User.Mitie_Voucher_Issued = $scope.employee.ARIA_User.Mitie_Voucher_Issued.sort(function (a, b) {
          return Date.parse(a.Voucher_Expiry) > Date.parse(b.Voucher_Expiry);
        });
      }
    }
  }

  function GetSVCurrentBalance(data) {
    var result = 0;
    for (var i = 0; i < data.length; i++) {
      result = result + data[i].Voucher_Balance;
    }
    return result;
  }
  $scope.clearPwdErrors = function () {
    $scope.changeError = '';
    $scope.changeError1 = '';
    $scope.changeError2 = '';
  };
  $scope.changePassword = function () {
    var result = true;
    if (isValidPassword($scope.newPassword) == false) {
      $translate('Text232').then(function (txtMsg) {
        $scope.changeError1 = txtMsg;
      });
      //$scope.changeError1 = "Invalid password.";
      result = false;
    } else {
      $scope.changeError1 = '';
    }
    if (isValidPassword($scope.newPassword1) == false) {

      $translate('Text232').then(function (txtMsg) {
        $scope.changeError2 = txtMsg;
      });
      //$scope.changeError2 = "Invalid password.";
      result = false;
    } else {
      $scope.changeError2 = '';
    }

    if ($scope.newPassword != $scope.newPassword1) {

      $translate('Text233').then(function (txtMsg) {
        $scope.changeError = txtMsg;
      });

      $scope.changeError = 'The passwords you entered do not match.';
      result = false;
    } else {
      $scope.changeError = '';
    }

    if (result == true) {
      var mres1 = RESOURCES.HOST;
      openModal(); //Open the busy indicator.
      var nnce = nonceGenerator();
      var userid = $scope.username.toLowerCase();
      var rndString = md5.createHash(userid + $scope.password + nnce);
      loginService.updateUser(userid, rndString, nnce, $scope.newPassword, mres1)
        .then(function (res) {
          //Check if there is an error.
          if (res.status) {
            if (res.status == 'FAILURE') {
              $scope.changeError = res.error;
              $scope.showLogin = false;
              $scope.showChange = true;
              $scope.showForgot = false;
              $scope.showForgotForce = false;
            }
            closeModal();
          } else {
            //if no error then process the response.
            $scope.employee = res;
            //$scope.showOptions = true;
            if (!$scope.employee.ARIA_User.Date_of_Employment) {
              $scope.employee.ARIA_User.Date_of_Employment = '';
            }
            $rootScope.loggedInUser = $scope.employee;
            $rootScope.loggedInUser.ARIA_User.password = $scope.newPassword;
            //---------------------------------------------------------------
            //Staff Vouchers
            setStaffVouchers();
            //Momentum Vouchers
            setMomentumVouchers();
            //Staff Vouchers
            setMitieVouchers();

            //set languange
            //---------------------------------------------------------------
            if ($scope.employee.ARIA_User.Default_Language === 'English') {
              $translate.use('en');
            } else if ($scope.employee.ARIA_User.Default_Language === 'French' || $scope.employee.ARIA_User.Default_Language === 'Français') {
              $translate.use('fr');
            } else if ($scope.employee.ARIA_User.Default_Language === 'Dutch' || $scope.employee.ARIA_User.Default_Language === 'Nederlands') {
              $translate.use('be');
            }
            //---------------------------------------------------------------

            globalData.LoadStationList(mres1)
              .then(function (res) {
                if (res.error) {} else {
                  globalData.setStaionList(res);
                }
              });

            closeModal(); //Close the busy indicator.
            $location.path('/');
          }
        }, function (err) {
          $rootScope.loggedInUser = '';
          closeModal(); //Close the busy indicator.
        });
    }
  };
  $scope.cancelPwdChange = function () {
    $scope.showLogin = true;
    $scope.showChange = false;
    $scope.showForgot = false;
    $scope.showForgotForce = false;
    $scope.newPassword = '';
    $scope.newPassword1 = '';
    $scope.changeError = '';
    $scope.changeError1 = '';
    $scope.changeError2 = '';
  };

  function isValidPassword(value) {
    var validLength = 8,
      minSuccess = 3,
      isNumeric = +/\d+/.test(value),
      isCapitals = +/[A-Z]+/.test(value),
      isSmall = +/[a-z]+/.test(value),
      isSpecial = +/[!@#$%&\/=\?_\.,:;\-]+/.test(value);
    if (value.length < validLength) { // 8 symbols. We don`t need regexp here
      return false;
    }

    if (isNumeric + isCapitals + isSmall + isSpecial < minSuccess) {
      return false;
    }

    return true;
  }
  $scope.forgotPassword = function () {
    $scope.showForgot = true;
    $scope.showForgotForce = false;
    $scope.showLogin = false;
    $scope.showChange = false;
    $scope.forgotError = '';
    $scope.userEmail = '';
  };
  $scope.resetPassword = function () {
    //Forgot Password.
    if ($scope.userEmail) {
      var mres1 = RESOURCES.HOST;
      openModal(); //Open the busy indicator.
      //loginService.reset($scope.userEmail, mres1)
      loginService.reset($scope.userEmail, mres1)
        .then(function (res) {
          //Check if there is an error.
          if (res.status) {
            if (res.status == 'FAILURE') {
              $scope.forgotError = res.error;
              $scope.showLogin = false;
              $scope.showChange = false;
              $scope.showForgot = true;
              $scope.showForgotForce = false;
              closeModal();
            } else {
              $scope.showLogin = true;
              $scope.showChange = false;
              $scope.showForgot = false;
              $scope.showForgotForce = false;
              $scope.forgotError = '';
              $scope.changeError = '';
              $scope.changeError1 = '';
              $scope.changeError2 = '';
              $scope.loginError = '';

              $translate('Text234').then(function (txtMsg) {
                $scope.pwdResetMsg = txtMsg;
              });
              closeModal(); //Close the busy indicator.
            }
          }
        }, function (err) {
          $rootScope.loggedInUser = '';
          closeModal(); //Close the busy indicator.
        });
    }
  };
  $scope.cancelPwdReset = function () {
    $scope.showLogin = true;
    $scope.showChange = false;
    $scope.showForgot = false;
    $scope.showForgotForce = false;
    $scope.forgotError = '';
    $scope.changeError = '';
    $scope.changeError1 = '';
    $scope.changeError2 = '';
    $scope.loginError = '';
  };
}]);
