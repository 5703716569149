import app from '../app';
import { closeModal, nonceGenerator, openModal } from '../utils';

app.controller('HomeController', function ($scope, $rootScope, $location, RESOURCES, LANGUAGES, CURRENCIES, COUNTRIES, updateEmployeeService, globalData, md5, $translate) {
  init();

  function init() {
    var i;
    $scope.loggedUser = $rootScope.loggedInUser;
    $scope.showEdit = 'false';
    $scope.currencies = CURRENCIES;
    $scope.countries = COUNTRIES;
    $scope.user = {
      'Street': $scope.loggedUser.ARIA_User.Street,
      'City': $scope.loggedUser.ARIA_User.City,
      'State': $scope.loggedUser.ARIA_User.State,
      'PostalCode': $scope.loggedUser.ARIA_User.PostalCode,
      'Country': $scope.loggedUser.ARIA_User.Country,
      'MobilePhone': $scope.loggedUser.ARIA_User.MobilePhone,
      'HomePhone': $scope.loggedUser.ARIA_User.HomePhone,
      'Customer_Email': $scope.loggedUser.ARIA_User.Customer_Email,
      'DefaultLanguage': $scope.loggedUser.ARIA_User.Default_Language,
      'DefaultCurrency': $scope.loggedUser.ARIA_User.Default_Currency
    };
    $scope.displayLanguage = $scope.loggedUser.ARIA_User.Default_Language;
    $scope.duty_hotel_url = RESOURCES.DUTY_HOTEL_URL;
    $scope.languages = [];
    $scope.languages = LANGUAGES.filter(l => l.code !== 'be');

    var cDate = new Date();
    var yr = cDate.getFullYear();
    var m = cDate.getMonth();
    var d = cDate.getDate();
    $scope.minDate = new Date(yr - 26, m, d);
    $scope.maxDate = new Date(yr, m, d);

    $scope.loadDependantsError = false;
    $scope.dependant = {
      'first_name': '',
      'last_name': '',
      'date_of_birth': ''
    };
    $scope.newDependants = [];
    $scope.dependants = [];
    loadDependants();

    $scope.spousePartnerError = null;
    $scope.spousePartner = {
      firstName: '',
      lastName: ''
    };
    loadSpousePartner();

    $scope.showPD = true;
    $scope.showDep = false;
    $scope.showSpousePartner = false;
    if ($scope.loggedUser.ARIA_User.Staff_Voucher === 'false') {
      $scope.hideDep = true;
    } else {
      $scope.hideDep = false;
    }

    if (typeof ($rootScope.loggedInUser.ARIA_User.ESV_remaining) !== 'undefined') {
      $scope.showESVCount = true;
      $scope.ESVCount = $rootScope.loggedInUser.ARIA_User.ESV_remaining;
    } else {
      $scope.showESVCount = false;
      $scope.ESVCount = 0;
    }
    //Load new dependants if the flag is true

    if ($scope.loggedUser.ARIA_User.Ask_Dependants_Info === 'true') {
      $scope.askDependants = true;
    } else {
      $scope.askDependants = false;
    }

    if ($scope.loggedUser.ARIA_User.Ask_Dependants_Info) {
      for (var i = 0; i < 8; i++) {
        $scope.newDependants.push({
          'first_name': '',
          'last_name': '',
          'date_of_birth': ''
        });
      }
    }
  }

  $scope.checkDOB = function (dob, mode) {
    var result;
    result = dobDiff($scope.maxDate, dob);
    result = result / 365.25;
    result = result.toFixed(2);

    if (mode === 1) {
      if (result > 26) {
        $scope.showDOBErr = true;
      } else {
        $scope.showDOBErr = false;
      }
    } else if (mode === 2) {
      if (result > 26) {
        $scope.showDOBErr = true;
      }
    }
  };

  function dobDiff(laterdate, earlierdate) {
    var difference = laterdate.getTime() - earlierdate.getTime();
    var result = 0;

    result = Math.floor(difference / 1000 / 60 / 60 / 24);
    return result;
  }

  $scope.addDependant = function () {
    $scope.first_name_error = {
      'text-transform': 'uppercase',
      'border-color': ''
    };
    $scope.last_name_error = {
      'text-transform': 'uppercase',
      'border-color': ''
    };
    $scope.date_of_birth_error = {
      'border-color': ''
    };
    $scope.showDepError = false;

    //$scope.dependant.date_of_birth = ($scope.dependant.date_of_birth.getDate() + "-" + (Number($scope.dependant.date_of_birth.getMonth()) + 1) + "-" + $scope.dependant.date_of_birth.getFullYear());
    if ($scope.dependant.first_name === '' && $scope.dependant.last_name === '' && $scope.dependant.date_of_birth === '') {
      $scope.first_name_error = {
        'text-transform': 'uppercase',
        'border-color': 'red'
      };
      $scope.last_name_error = {
        'text-transform': 'uppercase',
        'border-color': 'red'
      };
      $scope.date_of_birth_error = {
        'border-color': 'red'
      };
      $scope.showDepError = true;
      return;
    } else if ($scope.dependant.first_name === '') {
      $scope.showDepError = true;
      $scope.first_name_error = {
        'text-transform': 'uppercase',
        'border-color': 'red'
      };
      if ($scope.dependant.last_name === '') {
        $scope.last_name_error = {
          'text-transform': 'uppercase',
          'border-color': 'red'
        };
      } else {
        $scope.last_name_error = {
          'text-transform': 'uppercase',
          'border-color': ''
        };
      }
      if ($scope.dependant.date_of_birth === '') {
        $scope.date_of_birth_error = {
          'border-color': 'red'
        };
      } else {
        $scope.date_of_birth_error = {
          'border-color': ''
        };
      }
      return;
    } else if ($scope.dependant.last_name === '') {
      $scope.showDepError = true;
      $scope.last_name_error = {
        'text-transform': 'uppercase',
        'border-color': 'red'
      };
      if ($scope.dependant.first_name === '') {
        $scope.first_name_error = {
          'text-transform': 'uppercase',
          'border-color': 'red'
        };
      } else {
        $scope.first_name_error = {
          'text-transform': 'uppercase',
          'border-color': ''
        };
      }
      if ($scope.dependant.date_of_birth === '') {
        $scope.date_of_birth_error = {
          'border-color': 'red'
        };
      } else {
        $scope.date_of_birth_error = {
          'border-color': ''
        };
      }
      return;
    } else if ($scope.dependant.date_of_birth === '') {
      $scope.showDepError = true;
      if ($scope.dependant.first_name === '') {
        $scope.first_name_error = {
          'text-transform': 'uppercase',
          'border-color': 'red'
        };
      } else {
        $scope.first_name_error = {
          'text-transform': 'uppercase',
          'border-color': ''
        };
      }
      if ($scope.dependant.last_name === '') {
        $scope.last_name_error = {
          'text-transform': 'uppercase',
          'border-color': 'red'
        };
      } else {
        $scope.last_name_error = {
          'text-transform': 'uppercase',
          'border-color': ''
        };
      }
      $scope.date_of_birth_error = {
        'border-color': 'red'
      };
      return;
    }
    if ($scope.showDOBErr === true) {
      $scope.date_of_birth_error = {
        'border-color': 'red'
      };
      return;
    } else {
      $scope.date_of_birth_error = {
        'border-color': ''
      };
    }

    $('#depModal').modal({
      backdrop: 'static'
    });
    $scope.showDepError = false;
    $scope.showDOBErr = false;

    if ($scope.newDependants) {
      $scope.newDependants.push($scope.dependant);
      $scope.dependants.push($scope.dependant);
      $scope.dependant = {
        'first_name': '',
        'last_name': '',
        'date_of_birth': ''
      };
      if ($scope.dependants.length >= 8) {
        $scope.showAddDependant = false;
      }
    } else {
      var dep = [];
      $scope.dependants = dep;
      $scope.newDependants.push($scope.dependant);
      $scope.dependant = {
        'first_name': '',
        'last_name': '',
        'date_of_birth': ''
      };
      $scope.dependants.push($scope.dependant);
    }
  };

  $scope.saveDependants = function () {
    var dataFound = false;
    var i = 0;
    var result;

    for (i = 0; i < $scope.newDependants.length; i++) {
      $scope.newDependants[i].first_name_error = {
        'text-transform': 'uppercase',
        'border-color': ''
      };
      $scope.newDependants[i].last_name_error = {
        'text-transform': 'uppercase',
        'border-color': ''
      };
      $scope.newDependants[i].date_of_birth_error = {
        'border-color': ''
      };
      $scope.showDepError = false;
    }
    //check for blanks;

    for (i = 0; i < $scope.newDependants.length; i++) {

      if ($scope.newDependants[i].first_name != '' && $scope.newDependants[i].last_name != '' && $scope.newDependants[i].date_of_birth != '') {
        result = 0;
        result = dobDiff($scope.maxDate, $scope.newDependants[i].date_of_birth);
        result = result / 365.25;
        result = result.toFixed(2);

        if (result > 26) {
          $scope.newDependants[i].date_of_birth_error = {
            'border-color': 'red'
          };
          return;
        }
      }

      if ($scope.newDependants[i].first_name === '' && $scope.newDependants[i].last_name === '' && $scope.newDependants[i].date_of_birth === '') {
        $scope.newDependants[i].first_name_error = {
          'text-transform': 'uppercase',
          'border-color': ''
        };
        $scope.newDependants[i].last_name_error = {
          'text-transform': 'uppercase',
          'border-color': ''
        };
        $scope.newDependants[i].date_of_birth_error = {
          'border-color': ''
        };
      } else if ($scope.newDependants[i].first_name === '' || $scope.newDependants[i].first_name === undefined) {
        $scope.showDepError = true;
        $scope.newDependants[i].first_name_error = {
          'text-transform': 'uppercase',
          'border-color': 'red'
        };

        if ($scope.newDependants[i].last_name === '') {
          $scope.newDependants[i].last_name_error = {
            'text-transform': 'uppercase',
            'border-color': 'red'
          };
        } else {
          $scope.newDependants[i].last_name_error = {
            'text-transform': 'uppercase',
            'border-color': ''
          };
        }

        if ($scope.newDependants[i].date_of_birth === '') {
          $scope.newDependants[i].date_of_birth_error = {
            'border-color': 'red'
          };
        } else {
          $scope.newDependants[i].date_of_birth_error = {
            'border-color': ''
          };
        }

        return;
      } else if ($scope.newDependants[i].last_name === '' || $scope.newDependants[i].last_name === undefined) {
        $scope.showDepError = true;
        $scope.newDependants[i].last_name_error = {
          'text-transform': 'uppercase',
          'border-color': 'red'
        };

        if ($scope.newDependants[i].first_name === '') {
          $scope.newDependants[i].first_name_error = {
            'text-transform': 'uppercase',
            'border-color': 'red'
          };
        } else {
          $scope.newDependants[i].first_name_error = {
            'text-transform': 'uppercase',
            'border-color': ''
          };
        }
        if ($scope.newDependants[i].date_of_birth === '') {
          $scope.newDependants[i].date_of_birth_error = {
            'border-color': 'red'
          };
        } else {
          $scope.newDependants[i].date_of_birth_error = {
            'border-color': ''
          };
        }

        return;
      } else if ($scope.newDependants[i].date_of_birth === '' || $scope.newDependants[i].date_of_birth === undefined) {
        $scope.showDepError = true;
        $scope.newDependants[i].date_of_birth_error = {
          'border-color': 'red'
        };
        if ($scope.newDependants[i].first_name === '') {
          $scope.newDependants[i].first_name_error = {
            'text-transform': 'uppercase',
            'border-color': 'red'
          };
        } else {
          $scope.newDependants[i].first_name_error = {
            'text-transform': 'uppercase',
            'border-color': ''
          };
        }
        if ($scope.newDependants[i].last_name === '') {
          $scope.newDependants[i].last_name_error = {
            'text-transform': 'uppercase',
            'border-color': 'red'
          };
        } else {
          $scope.newDependants[i].last_name_error = {
            'text-transform': 'uppercase',
            'border-color': ''
          };
        }

        return;
      }
    }

    $scope.showDOBErr = false;

    for (i = 0; i < $scope.newDependants.length; i++) {
      if ($scope.newDependants[i].first_name != '' && $scope.newDependants[i].last_name != '' && $scope.newDependants[i].date_of_birth != '') {
        $scope.newDependants[i].date_of_birth = ($scope.newDependants[i].date_of_birth.getDate() + '-' + (Number($scope.newDependants[i].date_of_birth.getMonth()) + 1) + '-' + $scope.newDependants[i].date_of_birth.getFullYear());
        dataFound = true;
      }
    }
    if (dataFound === true) {
      if ($scope.newDependants.length > 0) {
        sendCreateDependants(0);
      }
      $scope.showPD = true;
      $scope.showDep = false;
    }
  };

  $scope.clearDependant = function (idx) {
    for (var i = 0; i < $scope.newDependants.length; i++) {
      if (i === idx) {
        $scope.newDependants[i].first_name = '';
        $scope.newDependants[i].last_name = '';
        $scope.newDependants[i].date_of_birth = '';
        $scope.newDependants[i].first_name_error = {
          'text-transform': 'uppercase',
          'border-color': ''
        };
        $scope.newDependants[i].last_name_error = {
          'text-transform': 'uppercase',
          'border-color': ''
        };
        $scope.newDependants[i].date_of_birth_error = {
          'border-color': ''
        };
      }
    }
  };

  $scope.clearDependants = function () {
    for (var i = 0; i < $scope.newDependants.length; i++) {
      for (var j = 0; j < $scope.dependants.length; j++) {
        if ($scope.newDependants[i].first_name === $scope.dependants[j].first_name &&
          $scope.newDependants[i].last_name === $scope.dependants[j].last_name &&
          $scope.newDependants[i].date_of_birth === $scope.dependants[j].date_of_birth) {
          $scope.dependants.splice(j, 1);
          break;
        }
      }
    }

    $scope.showPD = true;
    $scope.showDep = false;
    $scope.newDependants = [];
    $('#myModal').modal('hide');
  };

  function sendCreateDependants(idx) {
    if (idx < $scope.newDependants.length) {
      if ($scope.newDependants[idx].first_name != '' && $scope.newDependants[idx].last_name != '' && $scope.newDependants[idx].date_of_birth != '') {
        var nnce = nonceGenerator();
        var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
        var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);
        var mres1 = RESOURCES.HOST;
        var dData = {
          'first_name': $scope.newDependants[idx].first_name,
          'last_name': $scope.newDependants[idx].last_name,
          'date_of_birth': $scope.newDependants[idx].date_of_birth
        };

        $('#saveDepDialog').modal({
          backdrop: 'static'
        });
        $scope.saveDependantsError = false;

        updateEmployeeService.createDependant(userid, rndString, mres1, nnce, dData)
          .then(function (res) {
            if (res.status === 'SUCCESS') {
              sendCreateDependants(idx + 1);

            } else {
              $scope.saveDependantsError = true;
              $scope.showPD = true;
              $scope.showDep = false;
              $('#saveDepDialog').modal('hide');
            }
          }, function (err) {
            $scope.saveDependantsError = true;
            $('#saveDepDialog').modal('hide');
            $scope.showPD = true;
            $scope.showDep = false;
          });
      } else {
        sendCreateDependants(idx + 1);
      }
    } else {
      //reload the global object
      if ($scope.saveDependantsError === false) {
        if ($rootScope.loggedInUser.ARIA_User.dependants) {} else {
          $rootScope.loggedInUser.ARIA_User.dependants = [];
        }
        for (var i = 0; i < $scope.newDependants.length; i++) {
          if ($scope.newDependants[i].first_name != '' && $scope.newDependants[i].last_name && $scope.newDependants[i].date_of_birth != '') {
            $rootScope.loggedInUser.ARIA_User.dependants.push($scope.newDependants[i]);
            $scope.dependants.push($scope.newDependants[i]);
          }
        }
        $scope.newDependants = [];
        $('#myModal').modal('hide');
      }
      $scope.askDependants = false;
      $rootScope.loggedInUser.ARIA_User.Ask_Dependants_Info = false;
      $('#saveDepDialog').modal('hide');
    }
  }

  function loadDependants() {
    var result;
    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);
    var mres1 = RESOURCES.HOST;
    openModal();
    updateEmployeeService.getDependants(userid, rndString, mres1, nnce)
      .then(function (res) {
        if (res.dependants) {
          result = res.dependants;
          $scope.dependants = [];
          for (i = 0; i < result.length; i++) {
            $scope.dependants.push(result[i]);
          }
          if ($scope.dependants) {
            if ($scope.dependants.length > 0) {
              $rootScope.loggedInUser.ARIA_User.dependants = [];
              for (var i = 0; i < $scope.dependants.length; i++) {
                $rootScope.loggedInUser.ARIA_User.dependants.push($scope.dependants[i]);
              }
            }
          }
        } else {
          result = [];
          $scope.loadDependantsError = true;
        }
        closeModal();
      }, function (err) {
        //error
        closeModal();
        result = [];
        $scope.loadDependantsError = true;
        //alert(err);
      });

    //return result;
  }

  function loadSpousePartner() {
    var result;
    const userName = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    const mres1 = RESOURCES.HOST;

    openModal();
    
    updateEmployeeService.getSpousePartner(userName, mres1)
      .then(function (res) {
        if (res) {
	  $scope.spousePartnerError = null;
	  
          $scope.spousePartner = res;

	  if (($scope.spousePartner.firstName || '').trim() !== '' && ($scope.spousePartner.lastName || '').trim() !== '') {
            $scope.showUpdateSpousePartner = false;

	    // update root scope spouse/partner name
	    $rootScope.loggedInUser.ARIA_User.Spouse_Partner_First_Name = $scope.spousePartner.firstName;
	    $rootScope.loggedInUser.ARIA_User.Spouse_Partner_Last_Name = $scope.spousePartner.lastName;
	  } else {
            $scope.showUpdateSpousePartner = true;
	  }
        }
	
        closeModal();
      }, function (err) {
        //error
        closeModal();
        $scope.spousePartnerError = err.message;
        //alert(err);
      });
  }

  $scope.updateSpousePartner = function () {
    $scope.first_name_error = {
      'text-transform': 'uppercase',
      'border-color': ''
    };
    
    $scope.last_name_error = {
      'text-transform': 'uppercase',
      'border-color': ''
    };

    if ($scope.spousePartner.firstName === '') {
      $scope.first_name_error = {
        'text-transform': 'uppercase',
        'border-color': 'red'
      };
    }
    
    if ($scope.spousePartner.lastName === '') {
        $scope.last_name_error = {
          'text-transform': 'uppercase',
          'border-color': 'red'
        };
    }
    
    var result;
    const userName = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    const mres1 = RESOURCES.HOST;
    
    openModal();
    
    updateEmployeeService.updateSpousePartner(userName, $scope.spousePartner.firstName, $scope.spousePartner.lastName, mres1)
      .then(function (res) {
	closeModal();
	loadSpousePartner();
      }, function (err) {
        //error
        closeModal();
        $scope.spousePartnerError = err.message;
        //alert(err);
      });
  };

  $scope.SetNoDependant = function () {
    var userData = {};
    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);
    userData.Aria_User_Name = userid;
    userData.Aria_Passcode = rndString;
    userData.nnce = nnce;
    userData.noDependant = true;
    var mres1 = RESOURCES.HOST;
    openModal();
    updateEmployeeService.updateDependantFlag(userData, mres1)
      .then(function (res) {
        if (res.Update_User_Response.status == 'FAILURE') {
          alert('updateDependants failed.');
          $scope.askDependants = false;
          $rootScope.loggedInUser.ARIA_User.Ask_Dependants_Info = false;
          closeModal();
        } else {
          $scope.askDependants = false;
          $rootScope.loggedInUser.ARIA_User.Ask_Dependants_Info = false;
          closeModal();
        }
      }, function (err) {
        $scope.askDependants = false;
        $rootScope.loggedInUser.ARIA_User.Ask_Dependants_Info = false;
        closeModal();
      });
  };

  $scope.clickLink = function (data) {
    if (data === 0) {
      $scope.showPD = true;
      $scope.showDep = false;
      $scope.showSpousePartner = false;
    } else if (data === 1) {
      $scope.showPD = false;
      $scope.showSpousePartner = false;
      $scope.showDep = true;
      if ($scope.loadDependantsError === true) {
        $scope.loadDependantsError = false;
        loadDependants();
      }

      if ($scope.dependants) {
        if ($scope.dependants.length < 8) {
          $scope.showAddDependant = true;
        } else {
          $scope.showAddDependant = false;
        }
      } else {
        $scope.showAddDependant = true;
      }

    } else if (data === 2) {
      $scope.showPD = false;
      $scope.showDep = false;
      
      $scope.showSpousePartner = true;
      
      $scope.spousePartnerError = null;
      
      loadSpousePartner();
    }
  };

  $scope.changeLanguage = function (langKey) {
    $translate.use(langKey);
    $scope.SetDisplayLanguage(langKey);
  };

  $scope.goNext = function (value) {
    if (value == 'P') {
      if (parseInt($scope.loggedUser.ARIA_User.PALS_Remaining) > 0) {
        globalData.setbookingType(value);
        $location.path('/enquiry');
      }
    } else if (value == 'R') {
      if (parseInt($scope.loggedUser.ARIA_User.TSV_Balance) > 0) {
        globalData.setbookingType(value);
        $location.path('/enquiry');
      }
    } else if (value == 'B') {
      globalData.setbookingType(value);
      $location.path('/enquiry');
    } else if (value == 'M') {
      if ($scope.loggedUser.ARIA_User.Momentum_Voucher_Current_Balance > 0) {
        globalData.setbookingType(value);
        $location.path('/enquiry');
      }
    } else if (value == 'I') {
      if ($scope.loggedUser.ARIA_User.Mitie_Voucher_Current_Balance > 0) {
        globalData.setbookingType(value);
        $location.path('/enquiry');
      }
    } else {
      globalData.setbookingType(value);
      $location.path('/enquiry');
    }
  };

  $scope.gotoSupport = function () {
    $location.path('/support');
  };

  $scope.viewBkg = function (value) {
    if (value) {
      //console.log(value);
      $('.modal-backdrop').hide();
      var objBkg;
      globalData.setElgBkgData(objBkg);
      globalData.setRefCode(value);
      $location.path('/retrieve');
    }
  };

  //function to order by date of travel for booking history.
  $scope.orderByDate = function (item) {
    var parts = item.outbound_dep_date_time.split('-');
    var number = parseInt(parts[2] + parts[1] + parts[0]);
    return -number;
  };

  $scope.SetDisplayLanguage = function (obj) {
    $scope.displayLanguage = obj;
    globalData.setDisplayLanguage($scope.displayLanguage);
  };

  $scope.setEdit = function (value) {
    $scope.showEdit = value;
  };

  $scope.getBkgHistory = function () {
    openModal();
    var mres1 = RESOURCES.HOST;
    $scope.bkgHistory = [];

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var customerEmail = $rootScope.loggedInUser.ARIA_User.Customer_Email.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    updateEmployeeService.getBookingHistory(userid, customerEmail, rndString, nnce, mres1)
      .then(function (res) {
        if (res.Booking_Cases) {
          $scope.bkgHistory = res.Booking_Cases;
        } else {
          $scope.bkgHistory = [];
        }
        closeModal();
      }, function (err) {
        //error
        closeModal();
        $scope.bkgHistory = [];
        //alert(err);
      });
  };

  $scope.filterNumValue = function ($event) {
    if (isNaN(String.fromCharCode($event.keyCode))) {
      $event.preventDefault();
    }
  };

  function openInNewTab(url) {
    //var uri = 'http://martinfowler.com/ieeeSoftware/whenType.pdf';
    var link = angular.element('<a href="' + url + '" target="_blank"></a>');
    angular.element(document.body).append(link);
    link[0].click();
    link.remove();
  }

  $scope.gotoDDef = function () {
    var lang = $translate.use();
    switch (lang) {
    case 'fr':
      openInNewTab('content/data/Terms_And_Conditions_fr.pdf');
      break;
    case 'be':
      openInNewTab('content/data/Terms_And_Conditions_be.pdf');
      break;
    default:
      openInNewTab('content/data/Terms_And_Conditions.pdf');
    }
  };
  $scope.gotoTC = function () {
    var lang = $translate.use();
    switch (lang) {
    case 'fr':
      openInNewTab('content/data/Terms_And_Conditions_fr.pdf');
      break;
    case 'be':
      openInNewTab('content/data/Terms_And_Conditions_be.pdf');
      break;
    default:
      openInNewTab('content/data/Terms_And_Conditions.pdf');
    }
  };

  $scope.gotoFC = function () {
    //openInNewTab("content/data/Conditions_Of_Carriage.pdf");
    var lang = $translate.use();
    switch (lang) {
    case 'fr':
      openInNewTab('content/data/Conditions_Of_Carriage_fr.pdf');
      break;
    case 'be':
      openInNewTab('content/data/Conditions_Of_Carriage_be.pdf');
      break;
    default:
      openInNewTab('content/data/Conditions_Of_Carriage.pdf');
    }
  };

  $scope.gotoPP = function () {
    //openInNewTab("content/data/Privacy_Policy.pdf");
    var lang = $translate.use();
    switch (lang) {
    case 'fr':
      openInNewTab('content/data/Privacy_Policy_fr.pdf');
      break;
    case 'be':
      openInNewTab('content/data/Privacy_Policy_be.pdf');
      break;
    default:
      openInNewTab('content/data/Privacy_Policy.pdf');
    }
  };

  $scope.gotoERFAQ = function () {
    var lang = $translate.use();
    switch (lang) {
      case 'fr':
        openInNewTab('content/data/eurostar-red-faq-fr.pdf');
        break;
      case 'be':
        openInNewTab('content/data/eurostar-red-faq-nl.pdf');
        break;
      case 'nl':
        openInNewTab('content/data/eurostar-red-faq-nl.pdf');
        break;
      default:
        openInNewTab('content/data/eurostar-red-faq-en.pdf');
    }
  };

  $scope.gotoCPD = function () {
    //openInNewTab("content/data/Cookies_And_Personal_Data.pdf");
    var lang = $translate.use();
    switch (lang) {
    case 'fr':
      openInNewTab('content/data/Cookies_And_Personal_Data_fr.pdf');
      break;
    case 'be':
      openInNewTab('content/data/Cookies_And_Personal_Data_be.pdf');
      break;
    default:
      openInNewTab('content/data/Cookies_And_Personal_Data.pdf');
    }
  };

  function copyUserContactInfo(userData) {
    $scope.loggedUser.ARIA_User.Customer_Email = userData.Customer_Email;
    $scope.loggedUser.ARIA_User.Street = userData.Street;
    $scope.loggedUser.ARIA_User.City = userData.City;
    $scope.loggedUser.ARIA_User.State = userData.State;
    $scope.loggedUser.ARIA_User.PostalCode = userData.PostalCode;
    $scope.loggedUser.ARIA_User.Country = userData.Country;
    $scope.loggedUser.ARIA_User.MobilePhone = userData.MobilePhone;
    $scope.loggedUser.ARIA_User.HomePhone = userData.HomePhone;
    $scope.loggedUser.ARIA_User.Default_Language = userData.DefaultLanguage;
    $scope.loggedUser.ARIA_User.Default_Currency = userData.DefaultCurrency;
  }

  function isUSCA() {
    const country = $scope.countries.find(country => {
      if (country.name === $scope.user.Country){
        return country;
      }
    });

    return ['US', 'CA'].includes(country?.code);
  }

  $scope.isUSCA = () => isUSCA();

  $scope.updateUserContactInfo = function (delAddr) {
    var userData = {};

    $scope.contactInfoError = false;
    $scope.contactPhError = false;

    if (delAddr == true) {
      $scope.user.Street = '';
      $scope.user.City = '';
      $scope.user.State = '';
      $scope.user.Country = '';
      $scope.user.PostalCode = '';
      $scope.user.MobilePhone = '';
    }

    if (($scope.user.Street == '' || $scope.user.Street == undefined) && ($scope.user.City == '' || $scope.user.City == undefined) && ($scope.user.Country == '' || $scope.user.Country == undefined) && ($scope.user.State == '' || $scope.user.State == undefined) && ($scope.user.PostalCode == '' || $scope.user.PostalCode == undefined)) {
      $scope.contactInfoError = false;
    }

    if ($scope.userForm.line1.$dirty == true || $scope.userForm.line2.$dirty == true || $scope.userForm.line3.$dirty == true || $scope.userForm.line4.$dirty == true || $scope.userForm.line5.$dirty == true) {
      if ($scope.userForm.line1.$invalid == true || $scope.userForm.line2.$invalid == true || $scope.userForm.line5.$invalid == true || (isUSCA() && ($scope.user.State == '' || $scope.user.State == undefined))) {
        $scope.contactInfoError = true;
      }
    }

    if (($scope.userForm.mobilephone.$dirty && $scope.userForm.mobilephone.$invalid) || ($scope.userForm.mobilephone.$error.pattern)) {
      $scope.contactPhError = true;
    }

    if (($scope.contactInfoError == false) && ($scope.contactPhError == false)) {
      //$('#saveDialog').modal({backdrop: 'static',keyboard: false });
      $('#saveDialog').modal({
        backdrop: 'static'
      });

      var nnce = nonceGenerator();
      var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
      var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

      userData.Aria_User_Name = userid;
      userData.Aria_Passcode = rndString;
      userData.Customer_Email = $scope.user.Customer_Email;
      userData.nnce = nnce;
      userData.Street = $scope.user.Street;
      userData.City = $scope.user.City;
      userData.State = $scope.user.State;
      userData.PostalCode = $scope.user.PostalCode;
      userData.Country = $scope.user.Country;

      userData.MobilePhone = $scope.user.MobilePhone;
      userData.HomePhone = $scope.user.HomePhone;
      userData.DefaultLanguage = $scope.user.DefaultLanguage;
      userData.DefaultCurrency = $scope.user.DefaultCurrency;

      //openModal();
      var mres1 = RESOURCES.HOST;
      updateEmployeeService.updateUserData(userData, mres1)
        .then(function (res) {
          if (res.Update_User_Response.status == 'FAILURE') {
            //alert("Update failed.");
            $scope.setEdit('false');
            $('#saveDialog').modal('hide');
            //closeModal();
          } else {
            copyUserContactInfo(userData);
            $scope.setEdit('false');
            $('#saveDialog').modal('hide');
            //closeModal();
          }
        }, function (err) {
          //error
          $scope.setEdit('false');
          $('#saveDialog').modal('hide');
          //closeModal();
          //alert(err);
        });
    } else {
      //$('#saveDialog').modal('hide');
    }

  };

  $scope.checkCharOnly = function (event) {
    var x = event.which || event.keyCode;
    if ((x > 64 && x < 91) || (x > 96 && x < 123) || (x == 32) || (x == 8)) {
    } else {
      event.preventDefault();
    }
  };

  $scope.checkAlphaNumeric = function (event) {
    var x = event.which || event.keyCode;
    if ((x > 47 && x < 58) || (x > 64 && x < 91) || (x > 96 && x < 123) || (x == 32) || (x == 8)) {
    } else {
      event.preventDefault();
    }
  };

});
