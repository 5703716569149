import 'bootstrap/dist/js/bootstrap';
import moment from "moment"

var env = {};

if (window) {
  Object.assign(env, window.__env);
}

var app = angular.module('ariaApp', ['ngRoute', 'ui.bootstrap', 'angular-md5', 'pascalprecht.translate']);

app.constant('__ENV', env);

app.filter('titleCase', function() {
  return function(input) {
    input = input || '';
    return input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  };
});
app.constant('LANGUAGES', (function() {
  var lang = [{name:'English', code:'en'}, {name:'Français', code:'fr'}, {name:'Nederlands', code:'be'}, {name:'Netherlands', code:'nl'}];
  return lang;
})());
app.constant('CURRENCIES', (function() {
  var curr = [{name:'GBP', code:'GBP'}, {name:'EUR', code:'EUR'}];
  return curr;
})());

app.constant('COUNTRIES', (function() {
  var curr = [{name:'United Kingdom', code:'GB', dial: '+44'}, {name:'France', code:'FR', dial: '+33'},
    {name:'België', code:'BE', dial: '+32'}, {name:'Deutschland', code:'DE', dial: '+49'},
    {name:'Ireland', code:'IE', dial: '+353'}, {name:'Nederland', code:'NL', dial: '+31'},
    {name:'Norway', code:'NO', dial: '+47'},{name:'Portugal', code:'PT', dial: '+351'},
    {name:'United States', code:'US', dial: '+1'}, {name:'Canada', code:'CA', dial: '+1'},
    {name:'New Zealand', code:'NZ', dial: '+64'},{name:'Australia', code:'AU', dial: '+61'},
    {name:'Italy', code:'IT', dial: '+39'}, {name:'Spain', code:'ES', dial: '+34'},
    {name:'Luxembourg', code:'LU', dial: '+352'}, {name:'South Africa', code:'ZA', dial: '+27'}];
  return curr;
})());

//use to handle image src errors
app.directive('errSrc', function() {
  return {
    link: function(scope, element, attrs) {
      element.bind('error', function() {
        if (attrs.src != attrs.errSrc) {
          attrs.$set('src', attrs.errSrc);
        }
      });
    }
  };
});

//If Enter key is pressed in the textbox call the function provided.
app.directive('ngEnter', function () {
  return function (scope, element, attrs) {
    element.bind('keydown keypress', function (event) {
      if(event.which === 13) {
        scope.$apply(function (){
          scope.$eval(attrs.ngEnter);
        });

        event.preventDefault();
      }
    });
  };
});
//replace on text characters in the textbox
app.directive('replace', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, model) {
      model.$parsers.push(function(val) {
        if (!val) { return; }
        var regex = new RegExp('[^a-zA-Z ]');
        var replaced = val.replace(regex, '');
        if (replaced !== val) {
          model.$setViewValue(replaced);
          model.$render();
        }
        return replaced;
      });
    }
  };
});

app.config(function ($routeProvider) {
  $routeProvider
    .when('/seatmap',
      {
        templateUrl: 'partials/seatmap.html',
        controller: 'SeatMapController'

      })
    .when('/meals',
      {
        templateUrl: 'partials/meals.html',
        controller: 'MealsController'

      })
    .when('/outbound',
      {
        templateUrl: 'partials/outbound.html',
        controller: 'OutBoundController'

      })
    .when('/return',
      {
        templateUrl: 'partials/return.html',
        controller: 'ReturnController'

      })
    .when('/bookingSummary',
      {
        templateUrl: 'partials/summary.html',
        controller: 'SummaryController'

      })
    .when('/enquiry',
      {
        templateUrl: 'partials/enquiry.html',
        controller: 'EnquiryController'

      })
    .when('/login',
      {
        templateUrl: 'partials/login.html',
        controller: 'LoginController'

      })
    .when('/payment',
      {
        templateUrl: 'partials/payment.html',
        controller: 'PaymentController'

      })
    .when('/',
      {
        templateUrl: 'partials/home.html',
        controller: 'HomeController'

      })
    .when('/aftersales',
      {
        templateUrl: 'partials/aftersales.html',
        controller: 'AftersalesController'

      })
    .when('/retrieve/:bookingRef?',
      {
        templateUrl: 'partials/viewbooking.html',
        controller: 'ViewBookingController'

      })
    .otherwise({ redirectTo: '/' });
});

app.run(function($rootScope, $location) {
  $rootScope.$on( '$routeChangeStart', function(event, next, current) {
    if (!$rootScope.loggedInUser) {
      if ( next.templateUrl === 'partials/login.html') {
      } else {
        $location.path('/login');
      }
    }
  });
});

//----------------------Timeout functionality----------------------//

app.run(function($rootScope, $location, $interval) {

  var lastDigestRun = Date.now();
  var idleCheck = $interval(function() {
    var now = Date.now();
    if (now - lastDigestRun > 15*60*1000) {
      $rootScope.loggedInUser = '';
      $location.path('/login');
    }
  }, 60*1000);

  $rootScope.$on('$routeChangeStart', function(evt) {
    lastDigestRun = Date.now();
  });
});

app.filter('Capitalize', function() {
  return function(input){
    if (input){
      if(input.indexOf(' ') !== -1){
        var inputPieces,
          i;

        input = input.toLowerCase();
        inputPieces = input.split(' ');

        for(i = 0; i < inputPieces.length; i++){
          inputPieces[i] = capitalizeString(inputPieces[i]);
        }

        return inputPieces.toString().replace(/,/g, ' ');
      }
      else {
        input = input.toLowerCase();
        return capitalizeString(input);
      }

      function capitalizeString(inputString){
        return inputString.substring(0,1).toUpperCase() + inputString.substring(1);
      }
    }
  };
});

app.filter('ConvertElgDate', function() {
  return function(input){
    if( input.length == 5 ){
      let inputDate, inputMonth, output;
      const months = {
        'jan': 'January', 'feb': 'February', 'mar': 'March',
        'apr': 'April', 'may': 'May', 'jun': 'June', 'jul': 'July',
        'aug': 'August', 'sep': 'September', 'oct': 'October',
        'nov': 'November', 'dec': 'December'
      };
      inputDate = input.substr(0,2);
      inputMonth = input.substr(2,3);
      inputMonth = inputMonth.toLowerCase();
      output =`${inputDate} ${months[inputMonth]}`;
      // console.log('ConvertElgDate = ',  output);
      return output;
    }

  };
});

app.filter('displayFullDate', function () {
  return function(input){
    if (input) {
        return moment(input).format("ddd D MMM YYYY")
    }
  };
});

app.filter('ConvertSalesforceDate', function() {
  return function(input){
    if (input) {
      input = input.substr(0,8);
      if( input.length == 8 ){
        let inputDate, inputMonth, inputYear, output;
        const months = {
          '01': 'January', '02': 'February', '03': 'March',
          '04': 'April', '05': 'May', '06': 'June', '07': 'July',
          '08': 'August', '09': 'September', '10': 'October',
          '11': 'November', '12': 'December'
        };
        inputDate = input.substr(6,2);
        inputMonth = input.substr(4,2);
        inputYear = input.substr(0,4);
        output = `${inputDate} ${months[inputMonth]} ${inputYear}`;
        // console.log('ConvertSalesforceDate = ', output);
        return output;
      }
    }
  };
});

app.filter('formatTime', function () {
  return function(input) {
    let inputHours, inputMinutes, outPut;
    inputHours = input.substr(0,2);
    inputMinutes = input.substr(2,3);
    outPut = inputHours + ':' + inputMinutes;
    //console.log("formatTime:" + outPut );
    return outPut;

  };
});

app.filter('convertMinsToHoursMins', function () {
  return function(input){
    let inputHours, inputMinutes, outPut;
    inputHours =  Math.floor(input / 60);
    inputMinutes = input % 60;
    if (inputMinutes > 0){
      outPut = inputHours + 'h ' + inputMinutes + 'm';
    }
    else {
      outPut = inputHours + 'h';
    }
    return outPut;
  };
});

app.filter('formatFareValue', function () {
  return function(input){
    let outPut;
    if (input == 0) {
      outPut = '0.00';
    }
    else {
      outPut = input / 100;
      outPut = outPut.toFixed(2);
    }
    return outPut;
  };
});

app.filter('getFareClassOfAccommodation', function () {
  return function(fare) {
    return fare.type
  }
});

app.filter('getSegmentClassOfAccommodation', function () {
  return function(seg) {
    return seg.fareType
  }
});

Array.prototype.remove = function(from, to) {
  var rest = this.slice((to || from) + 1 || this.length);
  this.length = from < 0 ? this.length + from : from;
  return this.push.apply(this, rest);
};

app.constant('RESOURCES', (function() {
  // Define your variable
  return {
    HOST: '/',
    DC_RETURN_HOST: window.location.origin,
    CHANNEL: 'W',  //'M';  M=MOTO 'W=WEB'
    VERSION: 'ARIA-20.1.0', //Version Number
    PAGE_SET_ID: '2665', //'526'; //Page set ID
    PAGE_SET_ID_FR : '2812', //'526'; //Page set ID French
    PAGE_SET_ID_NL :'2813', //'526'; //Page set ID Dutch
    VOUCHER_UNIT_VALUE:  {'GBP': '24.50', 'EUR': '28.00'}, //unit value of the staff voucher.
    DUTY_HOTEL_URL: 'https://logic.inntel.co.uk/inputlogindetails.asp',
    ETAP_CID: 'e1f55aa8095f43f2b26fc0d54c1f44', // CID AUTH
    ETAP_UPDATE_CID: 'aria1', // CID PAXUPDATE
    ETAP_PATH: '/api/etap/',
    ETAP_POS: 'GBZXA' //'GBELG'; // POS
  };
})());

var translate_en = {
//Login
  'Text1': 'Log in',
  'Text2': 'Email address',
  'Text3': 'Please enter your email address',
  'Text4': 'Password',
  'Text5': 'What\'s your password?',
  'Text6': 'Submit',
  'Text7': 'Reset your password',
  'Text8': 'Reset your password',
  'Text9': 'To reset your password, enter the email address you use to sign in to ARIA.',
  'Text10': 'Email address',
  'Text11': 'Please enter an email address',
  'Text12': 'Cancel',
  'Text13': 'Change your password',
  'Text14': 'Your new password should be at least 8 characters long and contain at least 3 of the following:',
  'Text15': 'Lower case letter',
  'Text16': 'Upper case letter',
  'Text17': 'Number',
  'Text18': 'Special character',
  'Text19': 'New password',
  'Text20': 'Confirm new password',
  //home
  'Text21' : 'Booking history',
  'Text22' : 'Please fill in your house number/name, street, city and country ',
  'Text23' : 'Personal details',
  'Text24' : 'House number/name and street',
  'Text25' : 'City / Town',
  'Text26' : 'County',
  'Text27' : 'Postcode',
  'Text28' : 'Country',
  'Text29' : 'Phone number',
  'Text30' : 'Currency',
  'Text31' : 'Language',
  'Text32' : 'Save',
  'Text33' : 'Delete all details',
  'Text34' : 'Cancel',
  'Text35' : 'We\'re updating your details, please wait…',
  'Text36' : 'Address',
  'Text37' : 'Phone number',
  'Text38' : 'Email address',
  'Text39' : 'Currency',
  'Text40' : 'Language',
  'Text41' : 'Eurostar voucher bookings',
  'Text42' : 'Previous voucher balance',
  'Text43' : 'Vouchers issued',
  'Text44' : 'Vouchers used',
  'Text45' : 'Remaining vouchers',
  'Text46' : 'Annual PALS allowance',
  'Text47' : 'PALS bookings used',
  'Text48' : 'Remaining PALS bookings',
  'Text49' : 'Voucher bookings made',
  'Text50' : 'Other bookings',
  'Text51' : 'PALS bookings',
  'Text52' : 'Duty travel',
  'Text53' : 'Contractor voucher bookings',
  'Text54' : 'Vouchers used',
  'Text55' : 'FIP',
  'Text56' : 'Date of employment',
  'Text57' : 'Close',
  'Text58' : 'Booking history',
  'Text59' : 'Reference',
  'Text60' : 'Type',
  'Text61' : 'Travel date',
  'Text62' : 'From',
  'Text63' : 'To',
  'Text64' : 'Passengers',
  'Text65' : 'First passenger',
  'Text66' : 'Close',
  'Text67' : 'Book',
  'Text68' : 'Duty Travel – Eurostar',
  'Text69' : 'Eurostar - Blue Vouchers',
  'Text70' : 'Momentum Vouchers',
  'Text71' : 'Manage a booking',
  'Text72' : 'Get started',
  'Text73' : 'Website Information',
  'Text74' : 'Terms and conditions',
  'Text75' : 'Conditions of carriage',
  'Text76' : 'Privacy policy',
  'Text77' : 'Cookies and personal data',
  'Text78' : 'Help',
  'Text79' : 'Technical issues',
  'Text80' : 'Email:',
  'Text81' : 'Phone:',
  'Text82' : 'We\'re here from 08:00 to 17:00, Monday to Friday (except bank holidays). You can send an email any time though and we\'ll get back to you as soon as we can.',
  'Text83' : 'Fare and policy questions',
  'Text84' : 'Email:',
  'Text85' : 'Close',
  'Text86' : 'My profile',
  'Text87' : 'Edit',
  'Text87_1' : 'TFL Booking',
  'Text87_2' : 'MICE Booking',
  //enquiry
  'Text88' : 'FIP bookings - Eurostar Staff and Family Members Only',
  'Text89' : 'All passengers travelling on a FIP fare must be a family member and have a valid FIP or a Rail Staff Travel card in their name. Please remember to take your card with you or you may be refused travel or asked to buy a full fare ticket.',
  'Text90' : 'Accept',
  'Text91' : 'Go back',
  'Text92' : 'One-way',
  'Text93' : 'Return',
  'Text94' : 'From',
  'Text95' : 'We can\'t find that station. Please try again.',
  'Text96' : 'Where are you travelling from?',
  'Text97' : 'To',
  'Text98' : 'Where are you going?',
  'Text99' : 'Depart',
  'Text100' : 'Return',
  'Text101' : 'Passengers',
  'Text102' : '',
  'Text103' : 'Currency',
  'Text104' : 'Search',
  //outbound
  'Text105' : '2. Outbound journey',
  'Text106' : 'Date',
  'Text107' : 'Please enter a date.',
  'Text108' : 'Submit',
  'Text109' : 'Sorry, there are no trains that match your search.',
  'Text110' : 'change',
  'Text111' : 'changes',
  'Text112' : 'Direct',
  'Text113' : 'Depart',
  'Text114' : 'Arrive',
  'Text115' : 'Duration',
  'Text116' : 'Train',
  'Text117' : 'Change date',
  'Text118' : 'Departing on',
  'Text119' : 'Passengers',
  //return
  'Text120' : '3. Inbound journey',
  'Text121' : 'Change destination and/or date',
  'Text122' : 'From',
  'Text123' : 'Departing on',
  'Text124' : 'To',
  'Text125' : 'We can\'t find that station. Please try again.',
  'Text126' : 'Where are you going?',
  'Text127' : 'Date',
  'Text128' : 'Please enter a date.',
  'Text129' : 'Submit',
  'Text130' : 'Sorry, there are no trains that match your search.',
  'Text131' : 'change',
  'Text132' : 'changes',
  'Text133' : 'Direct',
  'Text134' : 'Depart',
  'Text135' : 'Arrive',
  'Text136' : 'Duration',
  'Text137' : 'Train',
  //payment
  'Text138' : '5. Payment',
  'Text139' : 'House No./Name , Street, City, Country are mandatory.',
  'Text140' : 'Billing address',
  'Text141' : 'Edit',
  'Text142' : 'House number/name and street',
  'Text143' : 'City',
  'Text144' : 'County',
  'Text145' : 'Postcode',
  'Text146' : 'Country',
  'Text147' : 'Phone number',
  'Text148' : 'Please enter your phone number',
  'Text149' : 'Make payment',
  'Text150' : 'Your booking',
  'Text151' : 'Booking reference',
  'Text152' : 'Search',
  'Text153' : '',
  'Text154' : 'Exchange booking',
  'Text155' : 'Cancel booking',
  'Text155_1' : 'Add/Change Meals',
  'Text155_2': 'Change Seats',
  'Text156' : 'Booking reference',
  'Text157' : 'Booking type',
  'Text158' : 'Passengers',
  'Text159' : 'First name',
  'Text160' : 'Last name',
  'Text161' : 'Journeys',
  'Text162' : 'Carrier',
  'Text163' : 'Train',
  'Text164' : 'From',
  'Text165' : 'To',
  'Text166' : 'Date',
  'Text167' : 'Departs',
  'Text168' : 'Arrives',
  'Text169' : 'Seat',
  'Text170' : 'Travel class',
  'Text171' : 'Meal preferences',
  'Text172' : 'Passenger',
  'Text173' : 'Journey',
  'Text174' : 'Meal',
  //Booking Summary
  'Text175' : '4. Booking summary',
  'Text176' : 'Collecting SNCF tickets in France',
  'Text177' : '• If your journey starts in France and includes an SNCF journey, you can’t print your tickets at home.• Instead, you’ll need to collect them at the station. Just take your booking reference and payment card to an SNCF ticket machine or ticket office.',
  'Text178' : '• If your journey from France is direct on Eurostar, you can print your tickets at home via Manage a booking at eurostar.com.',
  'Text179' : 'Ok',
  'Text180' : 'Train',
  'Text181' : 'Departs',
  'Text182' : 'Arrives',
  'Text183' : 'Journey',
  'Text184' : 'Travel class',
  'Text185' : 'Price',
  'Text186' : 'Fare conditions',
  'Text187' : 'Passenger details',
  'Text188' : 'First name',
  'Text189' : 'Last name',
  'Text190' : 'Meal preference',
  'Text191' : 'Please enter a first name',
  'Text192' : 'Please enter a last name',
  'Text193' : 'No preference',
  'Text194' : 'Meal preferences only apply when you\'re travelling in Standard Premier or Business Premier.',
  'Text195' : 'Choose your seats',
  'Text196' : 'First, select which journey you\'re choosing your seat for. If it\'s a return journey, don\'t forget to pick a seat for the trip home, too.',
  'Text197' : 'Journey',
  'Text198' : '',
  'Text199' : 'Choose a seat number',
  'Text200' : 'Apply',
  'Text201' : 'Cancel',
  'Text202' : 'Email address',
  'Text203' : 'Phone number',
  'Text204' : 'Contact details',
  'Text205' : 'Please enter an email address',
  'Text206' : 'Please enter a phone number',
  'Text207' : 'I\'ve read and agree with Eurostar\'s terms and conditions and conditions of carriage.',
  'Text208' : 'Conditions of carriage',
  'Text209' : 'Please enter your phone number. ',
  'Text210' : 'To stop this message popping up again, just save your contact details in My profile before your next booking.',
  //aftersales
  'Text211' : 'Exchange a booking',
  'Text212' : 'Click the journey you\'d like to exchange',
  'Text213' : 'Enter a new date',
  'Text214' : '1. Find trains',
  'Text215' : 'Train',
  'Text216' : 'Departs',
  'Text217' : 'Arrives',
  'Text218' : 'Duration',
  'Text219' : 'Your new journey',
  'Text220' : 'From',
  'Text221' : 'To',
  'Text222' : 'Date',
  'Text223' : 'Departs',
  'Text224' : 'Arrives',
  'Text225' : 'Travel class',
  'Text226' : 'Confirm exchange',
  'Text227' : 'Cancel a booking',
  'Text228' : 'Pick which journeys you\'d like to cancel, then click Confirm cancellation',
  'Text229' : 'Cancel journey?',
  'Text230' : 'Select to cancel',
  'Text231' : 'Confirm cancellation',
  //Controller.js
  'Text232' : 'Sorry, that password\'s not valid – please try again',
  'Text233' : 'Oops, the passwords you entered don\'t match',
  'Text234' : 'We\'ve sent you a password reset link. Just follow the instructions in the email to change your password.',
  'Text235' : '',
  'Text236' : '',
  'Text237' : '',
  'Text238' : '',
  'Text239' : 'This booking has been cancelled',
  'Text240' : 'Sorry, we can\'t find that booking at the moment. Please try again later.',
  'Text241' : 'Sorry, we can\'t find that booking. Please check your reference and try again.',
  'Text242' : 'Coach ',
  'Text243' : 'Seat ',
  'Text244' : 'No seat reserved',
  'Text245' : 'Book',
  'Text246' : 'Confirm and pay',
  'Text247' : 'Exchangeable via www.eurostar.com before departure with fee',
  'Text248' : 'Exchangeable and refundable before departure. Please note: refunds can\'t be processed on ARIA just yet. Please email staff.travel@eurostar.com instead.',
  'Text249' : 'Exchangeable and refundable before departure',
  'Text249_1' : 'Exchangeable and Non Refundable',
  'Text249_2' : 'Non Exchangeable and Non Refundable',
  'Text250' : 'Please enter a first name',
  'Text251' : 'It looks like you\'ve entered the same name twice',
  'Text252' : 'Please enter a last name',
  'Text253' : 'Oops, you\'ve already picked that seat for someone else.',
  'Text254' : 'You\'re all booked! Your booking reference is x',
  'Text255' : 'Sorry, we couldn\'t connect to the payment system. Please try again.',
  'Text256' : 'We\'re processing your card payment, please wait...',
  'Text257' : 'Sorry, the payment authorisation failed. Please try again.',
  'Text258' : 'Sorry, something went wrong. We haven\'t taken payment, so please start the booking again.',
  'Text259' : 'Sorry, your payment failed. Please try again.',
  'Text260' : 'We\'re issuing your tickets, please wait...',
  'Text261' : 'You\'re all booked! Your booking reference is ',
  'Text262' : '',
  'Text263' : 'You can only buy x tickets on this booking.',
  'Text264' : 'We can\'t find that station. Please try again.',
  'Text265' : 'We can\'t find that station. Please try again.',
  'Text266' : 'Oops, that date\'s either in the past or too far in advance. Please try another date.',
  'Text267' : 'Sorry, there are no fares that match your search.',
  'Text268' : 'Please note: your travel class has changed because Business Premier is only available for duty travel at certain times of day.',
  'Text269' : 'Oops, your outbound journey date is now after your inbound journey.',
  //Others
  'Text270' : 'Total fare for ',
  'Text271' : 'passenger',
  //Services.js
  'Text273' : 'Duty travel',
  'Text274' : 'FIP bookings',
  'Text275' : 'PALS booking',
  'Text276' : 'Momentum booking',
  'Text277' : 'Eurostar voucher booking',
  'Text278' : 'Contractor voucher booking',
  'Text278_1' : 'TFL booking',
  'Text278_2' : 'MICE booking',
  //app.js
  'Text279' : 'English',
  'Text280' : 'French',
  'Text281' : 'Dutch',
  'Text282' : 'Pound sterling',
  'Text283' : 'Euro',
  'Text284' : 'United Kingdom',
  'Text285' : 'France',
  'Text286' : 'Belgium',
  'Text287' : 'Germany',
  'Text288' : 'Ireland',
  'Text289' : 'Netherlands',
  'Text290' : 'I’ve read and agree with',
  'Text291' : 'Eurostar’s Terms and conditions',
  'Text292' : 'and Eurostar’s Conditions of carriage',
  'Text293' : 'Home',
  'Text294' : 'User Guide',
  'Text295' : 'FAQ',
  'Text296' : 'Login',
  'Text297' : 'Logout',
  //Misc
  'Text298' : 'PALS',
  'Text299' : 'Enter booking reference',
  'Text300' : 'Please enter your house name/number and street',
  'Text301' : 'Please enter your city',
  'Text302' : 'Please enter your county',
  'Text303' : 'Please enter your postcode',
  'Text304' : 'Please enter your phone number',
  'Text305' : 'Select',
  'Text306' : 'Duty travel tickets can be used by Eurostar, Momentum or Railway staff when travelling for work purposes only, as well as by third parties where the purpose for travel is directly related to the Eurostar business.',
  'Text307' : 'Where the purpose for travel is not related to the Eurostar business, please email the duty travel team to request \'Invitation\' travel.',
  'Text308' : 'Mitie Vouchers',
  'Text309' : 'Mitie Booking',
  'Text310' : 'Sorry, you don\'t have enough vouchers to make this booking.',
  'Text311' : 'You can only book return journeys with your vouchers.',
  'Text312' : 'Sorry, you don\'t have enough vouchers to make this booking.',
  'Text313' : 'Oops, something went wrong. Please start your booking again.',
  'Text314' : ' Voucher Rollback Failed.',
  'Text315' : 'Would you like to pay with vouchers?',
  'Text316' : 'If so, choose a voucher amount. Otherwise, click Pay balance.',
  'Text317' : 'Pay with vouchers',
  'Text318' : 'Vouchers available',
  'Text319' : 'Voucher Payment',
  'Text320' : 'Balance To Pay',
  'Text321' : 'Pay Balance',
  'Text322' : 'Confirm Booking',
  'Text323' : 'Voucher reference',
  'Text324' : 'Expiry date',
  'Text325' : 'Starting balance',
  'Text326' : 'Remaining balance',
  'Text327' : 'Duty Travel Hotels, Meetings, Events',
  'Text328' : 'Passbook Ticket',
  'Text329' : 'PDF Ticket',
  'Text330' : 'Missing Passenger Contact Details',
  'Text331' : 'Please select a ticketing option',
  'Text332' : 'Passenger Contact Details',
  'Text333' : 'Passenger Name',
  'Text334' : 'Contact Email',
  'Text335' : 'Country',
  'Text336' : 'Contact Phone',
  'Text337' : 'COPY TO ALL PASSENGERS',
  'Text338' : 'Information',
  'Text339' : 'Some browsers will not work with Aria so it is recommended to use',
  'Text340' : 'Infant Forename',
  'Text341' : 'Infant Surname',
  'Text342' : 'Travelling with an infant under 4?',
  'Text343' : 'Duplicate infant names.',
  'Text344' : 'Update Failed - Use Manage Your Booking to print tickets.',
  //Dependant defination
  'Text345' : 'Dependent children can be included on the same voucher as the adult traveller ONLY when the adult travelling with them is:',
  'Text345_1' : 'the employee to whom the voucher is issued',
  'Text345_2' : 'or their spouse/partner',
  'Text346' : 'Dependants must be under the age of 25 and either:',
  'Text347' : 'The child of the employee, Or',
  'Text348' : 'The child of the spouse/partner and permanently living with the employee',
  'Text349' : 'And either',
  'Text350' : 'In full-time education, Or',
  'Text351' : 'Earning less than a weekly wage as published each year by Rail Staff Travel at Rail Delivery Group',
  'Text351_1': 'Find out more here.',
  'Text352' : 'You do not have sufficient staff vouchers to pay for this booking.',
  'Text353' : 'Dependants',
  'Text354' : 'First Name',
  'Text355' : 'Surname',
  'Text356' : 'Date Of Birth',
  'Text357' : 'Add',
  'Text358' : 'Save',
  'Text359' : 'Cancel',
  'Text360' : 'The account holder or spouse must be travelling with the dependants',
  'Text361' : 'Close',
  'Text362' : 'Is passenger a dependant?',
  'Text363' : 'Including Dependants',
  'Text364' : 'Please send XXX vouchers to Staff Travel.',
  'Text365' : 'By making a booking using your free allocation of vouchers you are agreeing to send the required number of vouchers within 2 weeks of making a booking.',
  'Text366' : 'For active staff vouchers should be deposited in one of the secure staff travel post boxes.',
  'Text367' : 'For retirees and staff who are not at work vouchers should be posted to us – preferably by special delivery, to:',
  'Text368' : 'Eurostar International Ltd',
  'Text369' : 'Staff Travel',
  'Text370' : 'Kent House',
  'Text371' : '81 Station Road',
  'Text372' : 'Ashford',
  'Text373' : 'Kent  TN23 1AP',
  'Text374' : 'Continue',
  'Text375' : 'Date of birth',
  'Text376' : 'Passenger DOB is required.',
  'Text377' : 'Please enter passenger date of birth.',
  'Text378' : 'To book tickets for dependants using your Eurostar vouchers, you’ll need to give us a few details about them first.',
  'Text379' : 'Add Dependants',
  'Text380' : 'No Dependants',
  'Text381' : 'If you made a mistake when entering your dependant’s details, just email',
  'Text382' : 'application.support@eurostar.com',
  'Text383' : ' for help.',
  'Text384' : 'First name, surname and date of birth are mandatory.',
  'Text385' : 'If you have a dependant who is 25 or older, please contact ',
  'Text386' : 'traveldesk@eurostar.com',
  'Text387' : 'Do you want to continue exchange without a special meal?',
  'Text388' : 'Continue',
  'Text389' : 'Cancel',
  'Text390' : 'Click ',
  'Text391' : ' or ',
  'Text392' : ' symbols to change voucher contribution',
  'Text393' : 'Number Of Vouchers',
  'Text394' : 'Meals',
  'Text395' : 'First Name',
  'Text396' : 'Last Name',
  'Text397' : 'Meal',
  'Text398' : 'Save',
  'Text399' : 'Cancel',
  'Text400' : 'For security, we need you to reset your password. Please enter the email address you use to sign in to ARIA.',
  'Text401' : 'State or Province',
  'Text402' : 'Please fill in your house number/name, street, city, state or province and country',
  'Text403' : 'Eurostar - Red Vouchers',
  'Text404' : 'Eurostar Red booking',
  'Text405' : 'Sorry, you don\'t have enough miles to make this booking.',
  'Text406' : 'Maximum 9 passengers',
  'Text407' : 'Not enough miles for selected journey and passengers.',
  'Text408' : 'HR tickets. Cannot be exchanged. Only cancellation is possible. Refund of the Miles/Vouchers only if the cancellation takes place before the trip AND during the same trimester as the booking (up to',
  'Text409' : 'HR tickets. Cannot be exchanged. Only cancellation is possible. Refund of the Miles/Vouchers only if the cancellation takes place before the trip AND during the same trimester as the booking (up to',
  'Text410' : 'This booking is not refundable',
  'Text411' : 'Confirm',
  'Text412' : 'Status',
  'Text413' : 'Booking date',
  'Text414' : 'Please note that only trains available for your discount will be shown below. Do not compare to eurostar.com',
  'Text415' : 'Total fare',
  'Text416' : 'HR tickets. Cannot be exchanged. Only cancellation is possible. Refund of the Miles/Vouchers only if the cancellation takes place before the trip AND during the same trimester as the booking (up to',
  'Text417' : 'Total to be refunded',
  'Text418' : 'None of the tickets in this booking can be canceled.',
  'Text419': 'Thalys staff members, please use your Eurostar email address to log in, eg',
  'Text421': 'Eurostar Red Vouchers Q&A',
  'Text422' : 'Cancelled journeys',
  'Text423': 'What you need to do next',
  'Text424': 'When you’ve booked, please go to Manage your booking on',
  'Text425': 'Accept',
  'Text426': 'Go back',
  'Text427': 'Eurostar',
  'Text428': 'and fill in your Advance Passenger Information (API).',
  'Text429': 'You won’t be able to get your tickets until you’ve completed this information.',
  'Text430': 'You are selecting an Any Belgian Station journey. Vouchers can only be used against the journey between London and Brussels Midi so you will have to pay the remainder by card',
  'Text431': 'Any Belgian station journey',
  'Text432': 'Spouse / Partner',
  'Text433': 'Spouse / partner will be reviewed by the relevant People Team',
  'Text434': 'If you made a mistake when entering your spouse/partner’s details or you need to amend the details then email ',
  'Text435': 'traveldesk@eurostar.com',
  'Text436': ' to update your spouse / partner',
  'Text437': 'This booking can be cancelled but you need to email staff.travel@eurostar.com',
  'Text438': 'Max 5 passengers ', // PALS
  'Text439': 'Max passengers - Account Holder and Spouse/Partner, Dependants', // FIP
  'Text440': 'Max 9 passengers', // DUTY
  'Text441': 'Max 9 passengers', // Blue Vouchers
  'Text442': 'Max 5 passengers', // MICE
  'Text443': 'Max 9 passengers', // ER
  'Text444': 'Max passengers - Account Holder and 5 others', // TfL
};

var translate_fr = {
//Login
  'Text1': 'Connectez-vous',
  'Text2': 'Adresse e-mail',
  'Text3': 'Merci d\'entrer votre adresse e-mail',
  'Text4': 'Mot de passe',
  'Text5': 'Quel est votre mot de passe?',
  'Text6': 'Soumettre',
  'Text7': 'Réitialiser votre mot de passe',
  'Text8': 'Réinitialiser votre mot de passe',
  'Text9': 'Pour réinitialiser votre mot de passe, entrez l\'adresse e-mail utilisée pour vous connecter à ARIA.',
  'Text10': 'Adresse e-mail',
  'Text11': 'Merci d\'indiquer une adresse e-mail',
  'Text12': 'Annuler',
  'Text13': 'Changer votre mot de passe',
  'Text14': 'Votre mot de passe doit se composer d\'au moins 8 caractères, avec 3 des caractères suivants au minimum :',
  'Text15': 'Lettre en minuscule',
  'Text16': 'Lettre en majuscule',
  'Text17': 'Chiffre',
  'Text18': 'Symbole',
  'Text19': 'Nouveau mot de passe',
  'Text20': 'Confirmer le nouveau mot de passe',
  //home
  'Text21' : 'Historique des réservations',
  'Text22' : 'Merci d\'entrer votre numéro/nom de rue, ville et pays ',
  'Text23' : 'Informations personnelles',
  'Text24' : 'Numéro/Nom de la rue',
  'Text25' : 'Ville',
  'Text26' : 'N/A for the French',
  'Text27' : 'Code postal',
  'Text28' : 'Pays',
  'Text29' : 'Numéro de téléphone',
  'Text30' : 'Devise',
  'Text31' : 'Langue',
  'Text32' : 'Enregistrer',
  'Text33' : 'Supprimer toutes les informations',
  'Text34' : 'Annuler',
  'Text35' : 'Nous mettons vos coordonnées à jour, merci de patienter…',
  'Text36' : 'Adresse',
  'Text37' : 'Numéro de téléphone',
  'Text38' : 'Adresse e-mail',
  'Text39' : 'Devise',
  'Text40' : 'Langue',
  'Text41' : 'Réservations avec vouchers Eurostar',
  'Text42' : 'Nombres de vouchers restants au 31 décembre de l\'année précédente',
  'Text43' : 'Vouchers émis',
  'Text44' : 'Vouchers utilisés',
  'Text45' : 'Vouchers restants ',
  'Text46' : 'Attribution PALS annuelle',
  'Text47' : 'Réservations PALS déjà effectuées',
  'Text48' : 'Réservations PALS restantes',
  'Text49' : 'Réservations avec vouchers Eurostar déjà effectuées',
  'Text50' : 'Autres réservations',
  'Text51' : 'Réservations PALS',
  'Text52' : 'Duty travel',
  'Text53' : 'Réservations vouchers fournisseurs',
  'Text54' : 'Vouchers utilisés',
  'Text55' : 'FIP',
  'Text56' : 'Date d\'embauche',
  'Text57' : 'Fermer',
  'Text58' : 'Historique des réservations',
  'Text59' : 'Référence de réservation',
  'Text60' : 'Type de réservation',
  'Text61' : 'Date de voyage',
  'Text62' : 'De ',
  'Text63' : 'À',
  'Text64' : 'Voyageurs',
  'Text65' : 'Premier voyageur',
  'Text66' : 'Fermer',
  'Text67' : 'Réserver',
  'Text68' : 'Déplacement Professionnel - Eurostar',
  'Text69' : 'Eurostar - Blue Vouchers',
  'Text70' : 'Vouchers Momentum',
  'Text71' : 'Gérer une réservation',
  'Text72' : 'Commencer',
  'Text73' : 'Informations générales',
  'Text74' : 'Conditions',
  'Text75' : 'Conditions de transport',
  'Text76' : 'Politique de confidentialité',
  'Text77' : 'Cookies et données personnelles',
  'Text78' : 'Aide',
  'Text79' : 'Problèmes techniques',
  'Text80' : 'E-mail :',
  'Text81' : 'Téléphone :',
  'Text82' : 'Notre équipe vous répond de 09 h 00 à 18 h 00 du lundi au vendredi, excepté les jours fériés. N\'hésitez pas à nous envoyer un e-mail à tout moment, nous reviendrons vers vous dès que possible.',
  'Text83' : 'Questions concernant le tarif et la politique',
  'Text84' : 'E-mail :',
  'Text85' : 'Fermer',
  'Text86' : 'Mon profil',
  'Text87' : 'Éditer',
  'Text87_1' : 'TFL Booking',
  'Text87_2' : 'MICE Booking',
  //enquiry
  'Text88' : 'Réservations FIP - Rérvéux employé Eurostar et leur famille',
  'Text89' : 'Tout passager voyageant au tarif FIP doit être muni d\'une carte FIP ou Rail Staff Travel Card à son nom. À défaut de la présenter, le trajet peut vous être refusé ou il peut vous être demandé d\'acheter un billet à plein tarif.',
  'Text90' : 'Accepter',
  'Text91' : 'Retour',
  'Text92' : 'Aller simple',
  'Text93' : 'Aller-retour',
  'Text94' : 'De',
  'Text95' : 'Nous ne trouvons pas cette gare. Merci de réessayer.',
  'Text96' : 'D\'où partez-vous ?',
  'Text97' : 'À',
  'Text98' : 'Où allez-vous ?',
  'Text99' : 'Départ',
  'Text100' : 'Retour',
  'Text101' : 'Nombre de voyageurs',
  'Text102' : '',
  'Text103' : 'Devise',
  'Text104' : 'Chercher',
  //outbound
  'Text105' : '2. Trajet aller',
  'Text106' : 'Date',
  'Text107' : 'Merci d\'entrer une date',
  'Text108' : 'Soumettre',
  'Text109' : 'Aucun train ne correspond à votre recherche, sorry.',
  'Text110' : 'changement',
  'Text111' : 'changements',
  'Text112' : 'Direct',
  'Text113' : 'Départ',
  'Text114' : 'Arrivée',
  'Text115' : 'Durée du trajet',
  'Text116' : 'Train',
  'Text117' : 'Modifier la date',
  'Text118' : 'Départ le',
  'Text119' : 'Voyageurs',
  //return
  'Text120' : '3. Trajet retour',
  'Text121' : 'Modifier la destination et/ou la date',
  'Text122' : 'De ',
  'Text123' : 'Départ le',
  'Text124' : 'À',
  'Text125' : 'Nous ne trouvons pas cette gare. Merci de réessayer.',
  'Text126' : 'Où allez-vous ?',
  'Text127' : 'Date',
  'Text128' : 'Merci d\'entrer une date',
  'Text129' : 'Soumettre',
  'Text130' : 'Aucun train ne correspond à votre recherche, sorry.',
  'Text131' : 'changement',
  'Text132' : 'changements',
  'Text133' : 'Direct',
  'Text134' : 'Départ',
  'Text135' : 'Arrivée',
  'Text136' : 'Durée du trajet',
  'Text137' : 'Train',
  //Payment
  'Text138' : '5. Paiement',
  'Text139' : 'Numéro de maison./Nom, rue, ville, pays sont obligatoires.',
  'Text140' : 'Adresse de facturation',
  'Text141' : 'Éditer',
  'Text142' : 'Numéro/Nom de la rue',
  'Text143' : 'Ville',
  'Text144' : 'N/A for the French',
  'Text145' : 'Code postal',
  'Text146' : 'Pays',
  'Text147' : 'Numéro de téléphone',
  'Text148' : 'Merci d\'entrer votre numéro de téléphone',
  'Text149' : 'Payer',
  //View Booking
  'Text150' : 'Votre réservation',
  'Text151' : 'Référence de réservation',
  'Text152' : 'Chercher',
  'Text153' : '',
  'Text154' : 'Changer la réservation',
  'Text155' : 'Annuler la réservation',
  'Text155_1' : 'Add/Change Meals',
  'Text155_2': 'Change Seats',
  'Text156' : 'Référence de réservation',
  'Text157' : 'Type de réservation',
  'Text158' : 'Voyageurs',
  'Text159' : 'Prénom',
  'Text160' : 'Nom',
  'Text161' : 'Trajets',
  'Text162' : 'Transporteur',
  'Text163' : 'Train',
  'Text164' : 'De',
  'Text165' : 'À',
  'Text166' : 'Date',
  'Text167' : 'Départ',
  'Text168' : 'Arrivée',
  'Text169' : 'Place',
  'Text170' : 'Classe de voyage',
  'Text171' : 'Préférence de repas',
  'Text172' : 'Voyageur',
  'Text173' : 'Trajet',
  'Text174' : 'Repas',
  //Booking Summary
  'Text175' : '4. Récapitulatif de la réservation',
  'Text176' : 'Retrait de billets SNCF en France',
  'Text177' : '• Si votre voyage commence en France et inclut un trajet SNCF, vous ne pouvez pas imprimer vos billets chez vous.• Vous devez les retirer en gare auprès d\'une borne en libre-service ou de la billetterie SNCF, avec votre référence de réservation et la carte de paiement utilisée.',
  'Text178' : '• Si votre voyage depuis la France est direct avec Eurostar, vous pouvez imprimer vos billets chez vous via la section Gérer une réservation sur eurostar.com.',
  'Text179' : 'Ok',
  'Text180' : 'Train',
  'Text181' : 'Départ',
  'Text182' : 'Arrivée',
  'Text183' : 'Trajet',
  'Text184' : 'Classe de voyage',
  'Text185' : 'Tarif',
  'Text186' : 'Conditions tarifaires',
  'Text187' : 'Informations voyageurs',
  'Text188' : 'Prénom',
  'Text189' : 'Nom',
  'Text190' : 'Préférence de repas',
  'Text191' : 'Merci d\'indiquer le prénom',
  'Text192' : 'Merci d\'indiquer le nom',
  'Text193' : 'Pas de préférence',
  'Text194' : 'La préférence de repas s\'applique uniquement en Standard Premier et Business Premier.',
  'Text195' : 'Choisissez vos places',
  'Text196' : 'Sélectionnez le trajet pour lequel vous choisissez la place. S\'il s\'agit d\'un aller-retour, pensez à choisir une place pour le retour également.',
  'Text197' : 'Trajet',
  'Text198' : '',
  'Text199' : 'Choisir un numéro de siège',
  'Text200' : 'Appliquer',
  'Text201' : 'Annuler',
  'Text202' : 'Adresse e-mail',
  'Text203' : 'Numéro de téléphone',
  'Text204' : 'Coordonnées',
  'Text205' : 'Merci d\'indiquer une adresse e-mail',
  'Text206' : 'Merci d\'indiquer un numéro de téléphone',
  'Text207' : 'J\'ai lu et j\'accepte les conditions d\'utilisation et les conditions de transport Eurostar.',
  'Text208' : 'Conditions de transport',
  'Text209' : 'Merci d\'indiquer votre numéro de téléphone. ',
  'Text210' : 'Pour ne plus voir ce message apparaître, sauvegardez vos coordonnées dans Mon profil avant votre prochaine réservation.',
  //aftersales
  'Text211' : 'Changer une réservation',
  'Text212' : 'Cliquez sur le trajet que vous souhaitez modifier.',
  'Text213' : 'Entrez une nouvelle date',
  'Text214' : '1. Chercher les trains',
  'Text215' : 'Train',
  'Text216' : 'Départ',
  'Text217' : 'Arrivée',
  'Text218' : 'Durée du trajet',
  'Text219' : 'Votre nouveau trajet',
  'Text220' : 'De ',
  'Text221' : 'À',
  'Text222' : 'Date',
  'Text223' : 'Départ',
  'Text224' : 'Arrivée',
  'Text225' : 'Classe de voyage',
  'Text226' : 'Confirmer l\'échange',
  'Text227' : 'Annuler une réservation',
  'Text228' : 'Sélectionnez le trajet que vous souhaitez annuler puis cliquez sur Confirmer l\'annulation.',
  'Text229' : 'Annuler le trajet ?',
  'Text230' : 'Sélectionnez pour annuler',
  'Text231' : 'Confirmer l\'annulation',
  //controller.js
  'Text232' : 'Ce mot de passe n\'est pas valide. Merci de réessayer.',
  'Text233' : 'Oups, les mots de passe ne correspondent pas.',
  'Text234' : 'Nous vous avons envoyé un lien pour réinitialiser votre mot de passe. Suivez les instructions pour le changer.',
  'Text235' : '',
  'Text236' : '',
  'Text237' : '',
  'Text238' : '',
  'Text239' : 'Cette réservation a été annulée',
  'Text240' : 'Nous ne retrouvons pas cette réservation pour l\'instant, sorry. Merci de réessayer plus tard.',
  'Text241' : 'Nous ne trouvons pas cette réservation, sorry. Merci de vérifier votre référence et de réessayer.',
  'Text242' : 'Voiture ',
  'Text243' : 'Place ',
  'Text244' : 'Pas de siège réservé',
  'Text245' : 'Réserver',
  'Text246' : 'Confirmer et payer',
  'Text247' : 'Échangeable avant le départ sur www.eurostar.com, moyennant un supplément',
  'Text248' : 'Échangeable et remboursable avant le départ. À noter : les remboursements ne peuvent pas encore être traités sur ARIA. Merci d\'envoyer un e-mail à staff.travel@eurostar.com.',
  'Text249' : 'Échangeable et remboursable avant le départ',
  'Text249_1' : 'Échangeable et non remboursable',
  'Text249_2' : 'Non Échangeable et non remboursable',
  'Text250' : 'Merci d\'indiquer le prénom',
  'Text251' : 'Apparemment vous avez entré deux fois le même nom.',
  'Text252' : 'Merci d\'indiquer le nom',
  'Text253' : 'Oups, vous avez déjà sélectionné cette place pour une autre personne.',
  'Text254' : 'Réservation confirmée ! Votre référence est XXXXXX',
  'Text255' : 'Nous n\'avons pu nous connecter au système de paiement, sorry. Merci de patienter…',
  'Text256' : 'Nous traitons votre paiement par carte, merci de patienter…',
  'Text257' : 'Autorisation de paiement refusée, sorry. Merci de réessayer.',
  'Text258' : 'Un incident s\'est produit, sorry. Le paiement n\'a pas été pris en compte, merci de recommencer votre réservation.',
  'Text259' : 'La paiement a échoué, sorry. Merci de réessayer.',
  'Text260' : 'Nous éditons vos billets, merci de patienter…',
  'Text261' : 'Réservation confirmée ! Votre référence est ',
  'Text262' : '',
  'Text263' : 'Vous pouvez uniquement réserver X billets sur cette réservation.',
  'Text264' : 'Nous ne trouvons pas cette gare. Merci de réessayer.',
  'Text265' : 'Nous ne trouvons pas cette gare. Merci de réessayer.',
  'Text266' : 'Oups, cette date est passée ou trop anticipée. Merci d\'essayer avec une autre date.',
  'Text267' : 'Aucun tarif ne correspond à votre recherche, sorry.',
  'Text268' : 'À noter : votre classe de voyage a changé car Business Premier est uniquement valable en duty travel à certaines heures de la journée.',
  'Text269' : 'Oups, votre date aller est maintenant postérieure à votre trajet retour.',
  //Others
  'Text270' : 'Prix total pour ',
  'Text271' : 'voyageur',
  //Services
  'Text273' : 'Duty travel',
  'Text274' : 'Réservations FIP',
  'Text275' : 'Réservation PALS',
  'Text276' : 'Réservation Momentum',
  'Text277' : 'Réservation voucher Eurostar',
  'Text278' : 'Réservation voucher fournisseur',
  'Text278_1' : 'TFL booking',
  'Text278_2' : 'MICE booking',
  //app.js
  'Text279' : 'Anglais',
  'Text280' : 'Français',
  'Text281' : 'Néerlandais',
  'Text282' : 'Livre sterling',
  'Text283' : 'Euro',
  'Text284' : 'Royaume-Uni',
  'Text285' : 'France',
  'Text286' : 'Belgique',
  'Text287' : 'Allemagne',
  'Text288' : 'Irlande',
  'Text289' : 'Pays-Bas',
  'Text290' : 'J’ai lu et j’accepte',
  'Text291' : 'Les conditions générales Eurostar',
  'Text292' : 'Et les conditions de transport Eurostar',
  'Text293' : 'Accueil',
  'Text294' : 'Guide Utilisateur',
  'Text295' : 'Questions Réponses',
  'Text296' : 'Connexion',
  'Text297' : 'Déconnexion',
  //Misc
  'Text298' : 'PALS',
  'Text299' : 'Entrez votre référence de réservation',
  'Text300' : 'Merci d\'indiquer votre numéro/nom de rue ',
  'Text301' : 'Merci d\'indiquer votre ville',
  'Text302' : '',
  'Text303' : 'Merci d\'indiquer votre code postal',
  'Text304' : 'Merci d\'indiquer votre numéro de téléphone',
  'Text305' : 'Sélectionner',
  'Text306' : 'Les billets de service (Duty Travel) peuvent être utilisés par le personnel d\'Eurostar, Momentum ou Railway lorsqu\'ils voyagent à des fins professionnelles uniquement, ainsi que par des tiers lorsque le but du voyage est directement lié à l\'activité d\'Eurostar.',
  'Text307' : 'Lorsque le but du voyage n\'est pas lié à l\'activité Eurostar, veuillez envoyer un e-mail à l\'équipe Duty Travel pour demander un voyage \'Invitation\'.',
  'Text308' : 'Vouchers Mitie',
  'Text309' : 'Mitie Booking',
  'Text310' : 'Désolé, vous n’avez pas assez de vouchers pour effectuer cette réservation.',
  'Text311' : 'Ces vouchers permettent uniquement de réserver un voyage retour.',
  'Text312' : 'Désolé, vous n’avez pas assez de vouchers pour effectuer cette réservation.',
  'Text313' : 'Oups, une erreur est survenue. Veuillez recommencer votre réservation.',
  'Text314' : ' Voucher Rollback Failed.',
  'Text315' : 'Souhaitez-vous payer avec des vouchers?',
  'Text316' : 'Si oui, choisissez la somme à utiliser. Sinon, cliquez sur Payer le solde.',
  'Text317' : 'Paiement par vouchers',
  'Text318' : 'Montant disponible',
  'Text319' : 'Somme à utiliser',
  'Text320' : 'Solde à payer',
  'Text321' : 'Payer le solde',
  'Text322' : 'Confirmer la réservation',
  'Text323' : 'Référence de voucher',
  'Text324' : 'Date d’expiration',
  'Text325' : 'Montant initial',
  'Text326' : 'Montant restant',
  'Text327' : 'Duty Travel Hotels, Meetings, Events',
  'Text328' : 'Billet électronique',
  'Text329' : 'Billet PDF',
  'Text330' : 'Détails de contact des voyageurs manquants',
  'Text331' : 'Sélectionnez une option de billetterie',
  'Text332' : 'INFORMATIONS VOYAGEUR',
  'Text333' : 'Nom du voyageur',
  'Text334' : 'Adresse e-mail',
  'Text335' : 'Pays',
  'Text336' : 'Téléphone',
  'Text337' : 'Appliquer à tous les voyageurs',
  'Text338' : 'Information',
  'Text339' : 'Certains navigateurs ne fonctionnent pas avec Aria, il est recommandé d’utiliser',
  'Text340' : 'Prénom de l’enfant de moins de 4 ans',
  'Text341' : 'Nom de famille de l’enfant de moins de 4 ans',
  'Text342' : 'Vous voyagez avec un enfant de moins de 4 ans?',
  'Text343' : 'Noms d’enfants dupliqués',
  'Text344' : 'Mise à jour échouée – Utilisez ‘Gérer une réservation’ pour imprimer vos billets.',
  //Dependant defination
  'Text345' : 'Les enfants à charge peuvent être inclus sur le même voucher qu’un voyageur adulte uniquement lorsque l’adulte voyageant avec eux est :,',
  'Text345_1' : 'L’employé à qui le voucher a été délivré',
  'Text345_2' : 'Ou son conjoint',
  'Text346' : 'Les personnes à charge doivent être âgées de moins de 25 ans et doivent être soit :',
  'Text347' : 'Les enfants de l’employé',
  'Text348' : 'Les enfants du conjoint de l’employé, habitant à demeure avec l’employé',
  'Text349' : 'Et soit :',
  'Text350' : 'Scolarisés/étudiants à plein temps',
  'Text351' : 'Ou rémunérés moins que le salaire minimum hebdomadaire établi chaque année par by Rail Staff Travel (Rail Delivery Group).',
  'Text351_1': 'Cliquez ici pour en savoir plus.',
  'Text352' : 'Vous n\'avez pas suffisamment de bons pour payer cette réservation.',
  'Text353' : 'enfant ou personne à charge',
  'Text354' : 'Prénom',
  'Text355' : 'Nom',
  'Text356' : 'Date de naissance',
  'Text357' : 'Add',
  'Text358' : 'Enregistrer',
  'Text359' : 'Annuler',
  'Text360' : 'L’employé ou son conjoint doivent voyager avec les enfants ou personnes à charge',
  'Text361' : 'Fermer',
  'Text362' : 'Ce voyageur est-il une personne à charge ?',
  'Text363' : 'En incluant les personnes à charges',
  'Text364' : 'Merci d’envoyer vos XXX vouchers à l’équipe Staff Travel.',
  'Text365' : 'En réservant avec votre quota de vouchers gratuits, vous vous engagez à envoyer le nombre adéquat de vouchers au plus tard 2 semaines après la réservation.',
  'Text366' : 'Les employés en activité devront déposer les vouchers dans l’une des boîtes aux lettres sécurisées destinées au personnel.',
  'Text367' : 'Les personnes à la retraite et les employés n’étant momentanément pas en activité devront nous poster les vouchers, de préférence en exprès, à :',
  'Text368' : 'Eurostar International Ltd',
  'Text369' : 'Staff Travel',
  'Text370' : 'Kent House',
  'Text371' : '81 Station Road',
  'Text372' : 'Ashford',
  'Text373' : 'Kent  TN23 1AP',
  'Text374' : 'Continuer',
  'Text375' : 'Date de naissance',
  'Text376' : 'Quelle est la date de naissance',
  'Text377' : 'Merci d’entrer la date de naissance.',
  'Text378' : 'Pour réserver des billets pour vos enfants ou personnes à charge avec des vouchers, vous devrez d’abord nous fournir quelques renseignements. Allez sur Aria et cliquez sur Mon profil.',
  'Text379' : 'Ajouter un enfant ou une personne à charge',
  'Text380' : 'Aucun enfant ou personne à charge',
  'Text381' : 'Si vous avez fait une erreur en saisissant les informations d’un de vos enfants ou personne à charge, envoyez un e-mail à ',
  'Text382' : 'application.support@eurostar.com',
  'Text383' : '',  // leave this blank,
  'Text384' : 'Le prénom, le nom et la date de naissance sont obligatoires.',
  'Text385' : 'Si vous avez une personne à charge qui a 25 ans ou plus, envoyez-nous un email à ',
  'Text386' : 'traveldesk@eurostar.com',
  'Text387' : 'Voulez-vous continuer l\'échange sans un repas spécial?',
  'Text388' : 'Continuer',
  'Text389' : 'Annuler',
  'Text390' : 'cliquez sur ',
  'Text391' : ' ou ',
  'Text392' : ' pour changer le nombre de voucher',
  'Text393' : 'Nombre de voucher',
  'Text394' : 'Meals',
  'Text395' : 'First Name',
  'Text396' : 'Last Name',
  'Text397' : 'Meal',
  'Text398' : 'Save',
  'Text399' : 'Cancel',
  'Text400' : 'For security, we need you to reset your password. Please enter the email address you use to sign in to ARIA.',
  'Text401' : 'State or Province',
  'Text402' : 'Please fill in your house number/name, street, city, state or province and country',
  'Text403' : 'Eurostar - Red Vouchers',
  'Text404' : 'Eurostar Red booking',
  'Text405' : 'Sorry, you don\'t have enough miles to make this booking.',
  'Text406' : '9 passagers maximum',
  'Text407' : 'Miles insuffisants pour le trajet et les passagers sélectionnés.',
  'Text408' : "Billets RH. Non échangeable. Seule l'annulation est possible. Remboursement des miles uniquement si l'annulation a lieu dans le trimestre de la réservation (avant le",
  'Text409' : "Billets RH. Non échangeable. Seule l'annulation est possible. Remboursement des miles uniquement si l'annulation a lieu dans le trimestre de la réservation (avant le",
  'Text410' : "Cette réservation n'est pas remboursable",
  'Text411' : 'Confirmer',
  'Text412' : 'Statut',
  'Text413' : 'Date de la commande',
  'Text414' : 'Seuls les trains disponibles avec votre réduction seront affichés ci-dessous. Ne comparez pas à eurostar.com',
  'Text415' : 'Prix total',
  'Text416' : "Billets RH. Non échangeable. Seule l'annulation est possible. Remboursement des miles uniquement si l'annulation a lieu dans le trimestre de la réservation (avant le",
  'Text417' : 'Total à rembourser',
  'Text418' : 'Aucun des billets ne peut être annulé.',
  'Text419': 'Collaborateurs Thalys, veuillez utiliser votre adresse e-mail Eurostar pour vous connecter, par exemple',
  'Text421': 'Eurostar Red Vouchers Q&A',
  'Text422' : 'Trajets annulés',
  'Text423': 'Ce que vous devez faire maintenant',
  'Text424': 'Une fois votre réservation effectuée, rendez-vous dans votre espace Gérer une réservation sur',
  'Text425': 'Accepter',
  'Text426': 'Retour',
  'Text427': 'Eurostar',
  'Text428': 'et indiquez vos informations préalables (API).',
  'Text429': 'Vous ne pourrez pas obtenir vos billets avant d’avoir indiqué ces informations.',
  'Text430': 'Vous sélectionnez un voyage vers n’importe quelle gare belge. Les vouchers ne peuvent être utilisés que pour le trajet entre Londres et Bruxelles Midi. Vous devrez donc payer le reste par carte bancaire',
  'Text431': 'Toutes gares Belge',
  'Text432': 'Conjoint / Partenaire',
  'Text433': 'Votre conjoint/partenaire sera verifié par l\'équipe RH compétente.',
  'Text434': 'Si vous avez fait une erreur lors de la saisie de votre conjoint/partenaire ou si vous desirez le modifier, envoyez un e-mail à ',
  'Text435': 'traveldesk@eurostar.com',
  'Text436': ' pour mettre à jour votre conjoint / partenaire',
  'Text437': 'This booking can be cancelled but you need to email staff.travel@eurostar.com',
  'Text438': 'Max 5 passengers ',
  'Text439': 'Max passengers - Account Holder and Spouse/Partner, Dependants',
  'Text440': 'Max 9 passengers',
  'Text441': 'Max 9 passengers',
  'Text442': 'Max 5 passengers',
  'Text443': 'Max 9 passengers',
  'Text444': 'Max passengers - Account Holder and 5 others'
};

var translate_be = {
//Login
  'Text1':   'Inloggen',
  'Text2':   'E-mailadres',
  'Text3':   'Vul je e-mailadres in',
  'Text4':   'Paswoord',
  'Text5':   'Wat is je paswoord?',
  'Text6':   'Verzenden',
  'Text7':   'Paswoord opnieuw instellen', //"Wachtwoord opnieuw instellen",
  'Text8':   'Paswoord opnieuw instellen',
  'Text9':   'Gebruik je e-mailadres voor ARIA om je paswoord opnieuw in te stellen.',
  'Text10': 'E-mailadres',
  'Text11': 'Vul een e-mailadres in',
  'Text12': 'Annuleren',
  'Text13': 'Paswoord wijzigen',
  'Text14': 'Je nieuwe paswoord moet minstens 8 tekens tellen en minstens 3 van de volgende elementen bevatten:',
  'Text15': 'Kleine letter',
  'Text16': 'Hoofdletter',
  'Text17': 'Cijfer',
  'Text18': 'Symbool',
  'Text19': 'Nieuw paswoord',
  'Text20': 'Paswoord bevestigen',
  //home
  'Text21' : 'Boekingsgeschiedenis',
  'Text22' : 'Vul je adres in (straat, huisnummer, gemeente en land) ',
  'Text23' : 'Persoonlijke gegevens',
  'Text24' : 'Straat en huisnummer',
  'Text25' : 'Plaats/Stad',
  'Text26' : 'N/A for the Belgians',
  'Text27' : 'Postcode',
  'Text28' : 'Land',
  'Text29' : 'Telefoonnummer',
  'Text30' : 'Valuta',
  'Text31' : 'Taal',
  'Text32' : 'Bewaren',
  'Text33' : 'Alles wissen',
  'Text34' : 'Annuleren',
  'Text35' : 'We werken je gegevens bij, ogenblikje …',
  'Text36' : 'Adres',
  'Text37' : 'Telefoonnummer',
  'Text38' : 'E-mailadres',
  'Text39' : 'Valuta',
  'Text40' : 'Taal',
  'Text41' : 'Eurostar voucher boekingen',
  'Text42' : 'Aantal resterende vouchers op 31 december van vorig jaar',
  'Text43' : 'Aantal vouchers uitgeschreven',
  'Text44' : 'Aantal gebruikte vouchers',
  'Text45' : 'Aantal resterende vouchers',
  'Text46' : 'Jaarlijks aantal PALS boekingen',
  'Text47' : 'Gebruikte PALS boekingen ',
  'Text48' : 'Resterende PALS boekingen',
  'Text49' : 'Aantal gemaakte boekingen met Eurostar-vouchers',
  'Text50' : 'Andere boekingen',
  'Text51' : 'PALS boekingen',
  'Text52' : 'Duty travel',
  'Text53' : 'Contractor voucher bookings',
  'Text54' : 'Aantal gebruikte vouchers',
  'Text55' : 'FIP',
  'Text56' : 'Datum van indiensttreding',
  'Text57' : 'Sluiten',
  'Text58' : 'Boekingsgeschiedenis',
  'Text59' : 'Boekingsreferentie',
  'Text60' : 'Soort boeking (FIP, PALS, …)',
  'Text61' : 'Reis datum',
  'Text62' : 'Van',
  'Text63' : 'Naar',
  'Text64' : 'Reizigers',
  'Text65' : 'Eerste reiziger',
  'Text66' : 'Sluiten',
  'Text67' : 'Boeken',
  'Text68' : 'Beroepsverplaatsingen – Eurostar',
  'Text69' : 'Eurostar - Blue Vouchers',
  'Text70' : 'Momentum Vouchers',
  'Text71' : 'Beheer je boeking',
  'Text72' : 'Starten',
  'Text73' : 'Website',
  'Text74' : 'Algemene voorwaarden',
  'Text75' : 'Vervoersvoorwaarden',
  'Text76' : 'Privacybeleid',
  'Text77' : 'Cookies en persoonlijke gegevens',
  'Text78' : 'Help',
  'Text79' : 'Technisch probleem',
  'Text80' : 'E-mail:',
  'Text81' : 'Telefoon:',
  'Text82' : 'We zijn bereikbaar van 9-18 u. MET, van maandag tot vrijdag (behalve feestdagen).Je kunt ons ook altijd een e-mail sturen, we zullen zo snel mogelijk antwoorden.',
  'Text83' : 'Voor vragen over tarieven en het beleid',
  'Text84' : 'E-mail:',
  'Text85' : 'Sluiten',
  'Text86' : 'Mijn profiel',
  'Text87' : 'Wijzigen',
  'Text87_1' : 'TFL Booking',
  'Text87_2' : 'MICE Booking',
  //enquiry
  'Text88' : 'FIP boekingen - Enkel voor Eurostar medewerkers en familie',
  //"Text89" : "Wie reist met een FIP-tarief moet een geldige FIP- of Rail Staff Travel-kaart op zijn eigen naam hebben. Neem zeker altijd je kaart mee, of je loopt het risico niet in de trein te mogen of een ticket voor de volle prijs te moeten kopen.",
  'Text89' : 'Wie reist met een FIP-tarief moet een geldige FIP- of Rail Staff Travel-kaart op zijn/haar eigen naam hebben. Neem zeker altijd je kaart mee, of je loopt het risico niet in de trein te mogen of een ticket voor de volle prijs te moeten kopen.',
  'Text90' : 'Aanvaarden',
  'Text91' : 'Terug',
  'Text92' : 'Enkele reis',
  'Text93' : 'Heen- en terugreis',
  'Text94' : 'Van',
  'Text95' : 'We vinden dit station niet terug. Probeer het opnieuw.',
  'Text96' : 'Wat is het station van vertrek?',
  'Text97' : 'Naar',
  'Text98' : 'Wat is het station van bestemming?',
  'Text99' : 'Vertrek',
  'Text100' : 'Terug',
  'Text101' : 'Aantal personen',
  'Text102' : '',
  'Text103' : 'Valuta',
  'Text104' : 'Zoeken',
  //outbound
  'Text105' : '2. Heenreis',
  'Text106' : 'Datum',
  'Text107' : 'Vul een datum in.',
  'Text108' : 'Verzenden',
  'Text109' : 'Er is geen trein die voldoet aan je zoekopdracht.',
  'Text110' : 'Overstappen',
  'Text111' : 'Overstappen',
  'Text112' : 'Rechtstreeks',
  'Text113' : 'Vertrek',
  'Text114' : 'Aankomst',
  'Text115' : 'Reisduur',
  'Text116' : 'Trein nr.',
  'Text117' : 'Datum wijzigen',
  'Text118' : 'Vertrek',
  'Text119' : 'Reizigers',
  //return
  'Text120' : '3. Terugreis',
  'Text121' : 'Bestemming en/of datum wijzigen',
  'Text122' : 'Van',
  'Text123' : 'Vertrek',
  'Text124' : 'Naar',
  'Text125' : 'We vinden dit station niet terug. Probeer het opnieuw.',
  'Text126' : 'Wat is het station van bestemming?',
  'Text127' : 'Datum',
  'Text128' : 'Vul een datum in.',
  'Text129' : 'Verzenden',
  'Text130' : 'Er is geen trein die voldoet aan je zoekopdracht.',
  'Text131' : 'Overstappen',
  'Text132' : 'Overstappen',
  'Text133' : 'Rechtstreeks',
  'Text134' : 'Vertrek',
  'Text135' : 'Aankomst',
  'Text136' : 'Reisduur',
  'Text137' : 'Trein nr.',
  'Text138' : '5. Betaling',
  'Text139' : 'Huisnummer / Naam, Straat, Plaats, Land zijn verplicht.',
  'Text140' : 'Factuuradres',
  'Text141' : 'Wijzigen',
  'Text142' : 'Straat en huisnummer',
  'Text143' : 'Gemeente',
  'Text144' : 'N/A for the Belgians',
  'Text145' : 'Postcode',
  'Text146' : 'Land',
  'Text147' : 'Telefoonnummer',
  'Text148' : 'Vul je telefoonnummer in',
  'Text149' : 'Betalen',
  'Text150' : 'Je boeking',
  'Text151' : 'Boekingsreferentie',
  'Text152' : 'Zoeken',
  'Text153' : '',
  'Text154' : 'Boeking wijzigen',
  'Text155' : 'Boeking annuleren',
  'Text155_1' : 'Add/Change Meals',
  'Text155_2': 'Change Seats',
  'Text156' : 'Boekingsreferentie',
  'Text157' : 'Soort boeking',
  'Text158' : 'Reizigers',
  'Text159' : 'Voornaam',
  'Text160' : 'Achternaam',
  'Text161' : 'Traject(en)',
  'Text162' : 'Maatschappij',
  'Text163' : 'Trein nr.',
  'Text164' : 'Van',
  'Text165' : 'Naar',
  'Text166' : 'Datum',
  'Text167' : 'Vertrek',
  'Text168' : 'Aankomst',
  'Text169' : 'Zitplaats',
  'Text170' : 'Reisklasse',
  'Text171' : 'Maaltijdvoorkeur',
  'Text172' : 'Reiziger',
  'Text173' : 'Traject',
  'Text174' : 'Maaltijd',
  //Booking Summary
  'Text175' : '4. Overzicht',
  'Text176' : 'SNCF-tickets afhalen in Frankrijk',
  'Text177' : '. Als je reis start in Frankrijk en een treinrit met SNCF bevat, kun je je tickets niet thuis afdrukken. Je moet dan je tickets afhalen in het station. Neem je boekingsreferentie en bankkaart  mee naar een SNCF-ticketmachine of naar de verkoopbalie.',
  'Text178' : '. Als je reis vanuit Frankrijk alleen met Eurostar is, dan kun je je tickets thuis afdrukken via ‘Beheer je boeking’ op eurostar.com.',
  'Text179' : 'Ok',
  'Text180' : 'Trein nr.',
  'Text181' : 'Vertrek',
  'Text182' : 'Aankomst',
  'Text183' : 'Traject',
  'Text184' : 'Reisklasse',
  'Text185' : 'Prijs',
  'Text186' : 'Tariefvoorwaarden',
  'Text187' : 'Reizigersgegevens',
  'Text188' : 'Voornaam',
  'Text189' : 'Achternaam',
  'Text190' : 'Maaltijdvoorkeur',
  'Text191' : 'Vul een voornaam in',
  'Text192' : 'Vul een achternaam in',
  'Text193' : 'Geen speciale wensen',
  'Text194' : 'Je kunt alleen een maaltijdvoorkeur aanduiden bij een reis in Standard Premier of Business Premier.',
  'Text195' : 'Zitplaats kiezen',
  'Text196' : 'Selecteer eerst de reis waarvoor je zitplaatsen kiest. Bij een retourtje mag je ook de terugreis niet vergeten.',
  'Text197' : 'Traject',
  'Text198' : '',
  'Text199' : 'Kies een zitplaatsnummer',
  'Text200' : 'Bevestigen',
  'Text201' : 'Annuleren',
  'Text202' : 'E-mailadres',
  'Text203' : 'Telefoonnummer',
  'Text204' : 'Contactgegevens',
  'Text205' : 'Vul een e-mailadres in',
  'Text206' : 'Vul een telefoonnummer in',
  'Text207' : 'Ik heb de algemene voorwaarden en de vervoersvoorwaarden van Eurostar gelezen en ga ermee akkoord.',
  'Text208' : 'Vervoersvoorwaarden',
  'Text209' : 'Vul je telefoonnummer in. ',
  'Text210' : 'Om deze pop-up te vermijden, bewaar je je contactgegevens in Mijn profiel voor een volgende boeking.',
  //aftersales
  'Text211' : 'Boeking wijzigen',
  'Text212' : 'Klik op de boeking die je wilt wijzigen',
  'Text213' : 'Voer een nieuwe datum in',
  'Text214' : '1. Trein zoeken',
  'Text215' : 'Trein nr.',
  'Text216' : 'Vertrek',
  'Text217' : 'Aankomst',
  'Text218' : 'Reisduur',
  'Text219' : 'Je nieuwe reis',
  'Text220' : 'Van',
  'Text221' : 'Naar',
  'Text222' : 'Datum',
  'Text223' : 'Vertrek',
  'Text224' : 'Aankomst',
  'Text225' : 'Reisklasse',
  'Text226' : 'Wijziging bevestigen',
  'Text227' : 'Boeking annuleren',
  'Text228' : 'Kies de reizen die je wilt annuleren en klik dan op Annulering bevestigen',
  'Text229' : 'Reis annuleren?',
  'Text230' : 'Selecteer om te annuleren',
  'Text231' : 'Annulering bevestigen',
  //Controller.js
  'Text232' : 'Dit is geen geldig paswoord. Probeer opnieuw',
  'Text233' : 'Oeps, deze paswoorden komen niet overeen',
  'Text234' : 'We hebben een link gestuurd om je paswoord opnieuw in te stellen. Volg de instructies om je paswoord te wijzigen',
  'Text235' : '',
  'Text236' : '',
  'Text237' : '',
  'Text238' : '',
  'Text239' : 'Deze boeking is geannuleerd.',
  'Text240' : 'We kunnen deze boeking op dit moment niet terugvinden. Probeer het later nog eens.',
  'Text241' : 'We kunnen deze boeking niet terugvinden. Controleer de referentie en probeer opnieuw.',
  'Text242' : 'Rijtuig ',
  'Text243' : 'zitplaats ',
  'Text244' : 'Geen zitplaats gereserveerd',
  'Text245' : 'Boeken',
  'Text246' : 'Bevestigen en betalen',
  'Text247' : 'Verwisselbaar met een wijzigingskost voor vertrek op www.eurostar.com',
  'Text248' : 'Omruilbaar en terugbetaalbaar voor vertrek. Belangrijk: Aria kan nog geen terugbetalingen regelen. E-mail hiervoor naar staff.travel@eurostar.com.',
  'Text249' : 'Omruilbaar en terugbetaalbaar voor vertrek',
  'Text249_1' : 'Omruilbaar en niet terugbetaalbaar',
  'Text249_2' : 'Niet inwisselbaar en niet-restitueerbaar',
  'Text250' : 'Vul een voornaam in',
  'Text251' : 'Blijkbaar heb je twee keer dezelfde naam ingevuld.',
  'Text252' : 'Vul een achternaam in',
  'Text253' : 'Oeps, deze plaats was al geselecteerd voor iemand anders.',
  'Text254' : 'Helemaal klaar! Je boekingsreferentie is x',
  'Text255' : 'Sorry, we kunnen geen verbinding maken met het betaalsysteem. Probeer het nog een keer.',
  'Text256' : 'We verwerken je betaling, een ogenblikje…',
  'Text257' : 'Blijkbaar is de betaling niet geslaagd. Probeer het nog een keer.',
  'Text258' : 'Blijkbaar is er iets misgegaan. Er is geen betaling gebeurd. Boek opnieuw.',
  'Text259' : 'Je betaling is niet doorgegaan. Probeer het nog een keer.',
  'Text260' : 'Wij maken je tickets aan, eventjes geduld …',
  'Text261' : 'Helemaal klaar! Je boekingsreferentie is ',
  'Text262' : '',
  'Text263' : 'Met deze boeking kun je maar x tickets bestellen.',
  'Text264' : 'We kunnen dat station niet terugvinden. Probeer het nog een keer.',
  'Text265' : 'We kunnen dat station niet terugvinden. Probeer het nog een keer.',
  'Text266' : 'Oeps, die datum is ofwel al voorbij, ofwel te ver in de toekomst. Probeer met een andere datum.',
  'Text267' : 'Er is geen tarief dat voldoet aan je zoekopdracht.',
  'Text268' : 'Belangrijk: je reisklasse is gewijzigd omdat er voor duty travel maar enkele treinen per dag in Business Premier mogelijk zijn.',
  'Text269' : 'Oeps, je datum van terugreis ligt voor de datum van vertrek.',
  //Others
  'Text270' : 'Totale prijs voor ',
  'Text271' : 'persoon',
  //Services
  'Text273' : 'Duty travel',
  'Text274' : 'FIP boekingen',
  'Text275' : 'PALS boeking',
  'Text276' : 'Momentum boeking',
  'Text277' : 'Eurostar voucher boeking',
  'Text278' : 'Contractor voucher boeking',
  'Text278_1' : 'TFL booking',
  'Text278_2' : 'MICE booking',
  //app.js
  'Text279' : 'Engels',
  'Text280' : 'Frans',
  'Text281' : 'Nederlands',
  'Text282' : 'Pond sterling',
  'Text283' : 'Euro',
  'Text284' : 'Verenigd Koninkrijk',
  'Text285' : 'Frankrijk',
  'Text286' : 'België',
  'Text287' : 'Duitsland',
  'Text288' : 'Ierland',
  'Text289' : 'Nederland',
  'Text290' : 'Ik ga akkoord met de',
  'Text291' : 'Eurostar algemene voorwaarden',
  'Text292' : 'en de vervoersvoorwaarden van Eurostar',
  'Text293' : 'Home',
  'Text294' : 'Gebruikersgids',
  'Text295' : 'Veelgestelde vragen',
  'Text296' : 'Inloggen',
  'Text297' : 'Uitloggen',
  //Misc
  'Text298' : 'PALS',
  'Text299' : 'Vul je boekingsreferentie in',
  'Text300' : 'Vul je straat en huisnummer in',
  'Text301' : 'Vul je gemeente in',
  'Text302' : '',
  'Text303' : 'Vul je postcode in',
  'Text304' : 'Vul je telefoonnummer in',
  'Text305' : 'Selecteer',
  'Text306' : 'Dienstreizentickets kunnen worden gebruikt door Eurostar-, Momentum- of spoorwegpersoneel wanneer ze alleen voor werkreizen reizen, evenals door derden wanneer het reisdoel rechtstreeks verband houdt met de Eurostar-activiteiten.',
  'Text307' : 'Als het doel van de reis niet gerelateerd is aan de Eurostar-activiteiten, stuur dan een e-mail naar het dienstdoende reisteam om een \'Invitation\'-reis aan te vragen.',
  'Text308' : 'Mitie Vouchers',
  'Text309' : 'Mitie Booking',
  'Text310' : 'Je hebt niet genoeg vouchers voor deze boeking.',
  'Text311' : 'Je kunt alleen retourtickets (H/T) boeken met je vouchers.',
  'Text312' : 'Je hebt niet genoeg vouchers voor deze boeking.',
  'Text313' : 'Blijkbaar ging er iets fout. Begin opnieuw voor deze boeking.',
  'Text314' : ' Voucher Rollback Failed.',
  'Text315' : 'Wil je met vouchers betalen?',
  'Text316' : 'Kies dan het bedrag van de voucher. Of klik op Betalen.',
  'Text317' : 'Betaal met vouchers',
  'Text318' : 'Vouchers beschikbaar',
  'Text319' : 'Betaling met vouchers',
  'Text320' : 'Nog te betalen',
  'Text321' : 'Betaal restbedrag',
  'Text322' : 'Bevestig boeking',
  'Text323' : 'Voucherreferentie',
  'Text324' : 'Vervaldatum',
  'Text325' : 'Startsaldo',
  'Text326' : 'Overblijvend saldo',
  'Text327' : 'Duty Travel Hotels, Meetings, Events',
  'Text328' : 'Passbook-ticket',
  'Text329' : 'Pdf-ticket',
  'Text330' : 'Ontbrekende passagiers contactgegevens',
  'Text331' : 'Selecteer een ticketing optie',
  'Text332' : 'Reizigersgegevens',
  'Text333' : 'Naam van reiziger',
  'Text334' : 'E-mail',
  'Text335' : 'Land',
  'Text336' : 'Telefoonnummer (mobiel)',
  'Text337' : 'Kopieer naar alle reizigers',    //"Kopieer Naar Alle Reizigers",
  'Text338' : 'Goed om te weten',
  'Text339' : 'Aria werkt niet zo goed met sommige browsers. Gebruik beter',
  'Text340' : 'Baby voornaam',
  'Text341' : 'Baby achternaam',
  'Text342' : 'Reist er een kind jonger dan 4 mee?', //"Reist u met een kind onder 4?",
  'Text343' : 'Dubbele babynaam',
  'Text344' : 'Update mislukt – Gebruik beheer uw boeking om de tickets af te drukken.',
  //Dependant defination
  'Text345' : 'Kinderen ten laste kunnen alleen reizen onder dezelfde voucher als de volwassen reiziger, als die reiziger',
  'Text345_1' : 'de werknemer is op wiens naam de voucher werd uitgegeven',
  'Text345_2' : 'of diens partner of echtgeno(o)t(e) ',
  'Text346' : 'Kinderen ten laste moeten jonger zijn dan 25 én:',
  'Text347' : 'ofwel een kind van de werknemer',
  'Text348' : 'of een kind van diens echtgeno(o)t(e)/partner en op hetzelfde adres wonen als de werknemer',
  'Text349' : 'Bovendien moet het kind',
  'Text350' : 'voltijds schoollopen',
  'Text351' : 'of minder verdienen dan een weekloon zoals elk jaar wordt gepubliceerd door Rail Staff Travel bij Rail Delivery Group.',
  'Text351_1': 'Meer informatie.',
  'Text352' : 'U hebt niet voldoende personeelsbewijzen om deze boeking te betalen',
  'Text353' : 'afhankelijke personen toe',
  'Text354' : 'Voornaam',
  'Text355' : 'Achternaam',
  'Text356' : 'Geboortedatum',
  'Text357' : 'Add',
  'Text358' : 'Bewaren',
  'Text359' : 'Annuleren',
  'Text360' : 'The account houder of echtgenoot moet met de afhankelijke person reizen.',
  'Text361' : 'Sluiten',
  'Text362' : 'Is de passagier afhankelijk van hulp/onzelfstandig',
  'Text363' : 'Inclusief afhankelijke personen',
  'Text364' : 'Stuur alstublieft XXX vouchers naar Staff Travel.',
  'Text365' : 'Maak je bij een boeking gebruik van je gratis vouchers, dan ga je ermee akkoord het vereiste aantal vouchers binnen 2 weken na het boeken te verzenden.',
  'Text366' : 'Actieve medewerkers moeten hun vouchers deponeren in een van de beveiligde brievenbussen voor Staff Travel (reizen medewerkers).',
  'Text367' : 'Gepensioneerden of medewerkers die niet aan het werk zijn, sturen hun vouchers - bij voorkeur als expresbrief - naar:',
  'Text368' : 'Eurostar International Ltd',
  'Text369' : 'Staff Travel',
  'Text370' : 'Kent House',
  'Text371' : '81 Station Road',
  'Text372' : 'Ashford',
  'Text373' : 'Kent  TN23 1AP',
  'Text374' : 'Doorgaan',
  'Text375' : 'Geboortedatum',
  'Text376' : 'Geboortedatum van de passagier is verplicht.',
  'Text377' : 'Vul alstublieft de geboortedatum van de passagier in.',
  'Text378' : 'Om tickets te boeken voor personen ten laste met Eurostar-vouchers, moet je ons eerst wat meer informatie over hen geven.',
  'Text379' : 'Voeg afhankelijke personen toe',
  'Text380' : 'Geen afhankelijke personen',
  'Text381' : 'Bij een vergissing in de gegevens van een kinderen ten laste, stuur je een e-mail naar ',
  'Text382' : 'application.support@eurostar.com',
  'Text383' : '', // leave this blank
  'Text384' : 'Voornaam, achternaam en geboortedatum zijn verplicht.',
  'Text385' : 'Heb je een kind ten laste van 25 jaar of ouder, contacteer dan ',
  'Text386' : 'traveldesk@eurostar.com',
  'Text387' : 'Wilt u de uitwisseling voortzetten zonder een speciale maaltijd?',
  'Text388' : 'Doorgaan',
  'Text389' : 'Annuleren',
  'Text390' : 'Klik ',
  'Text391' : ' of ',
  'Text392' : ' symbolen om de voucher kwanteit te wijzigen',
  'Text393' : 'Aantal vouchers',
  'Text394' : 'Meals',
  'Text395' : 'First Name',
  'Text396' : 'Last Name',
  'Text397' : 'Meal',
  'Text398' : 'Save',
  'Text399' : 'Cancel',
  'Text400' : 'For security, we need you to reset your password. Please enter the email address you use to sign in to ARIA.',
  'Text401' : 'State or Province',
  'Text402' : 'Please fill in your house number/name, street, city, state or province and country',
  'Text403' : 'Eurostar - Red Vouchers',
  'Text404' : 'Eurostar Red booking',
  'Text405' : 'Sorry, you don\'t have enough miles to make this booking.',
  'Text406' : 'Maximum 9 passengers',
  'Text407' : 'Niet genoeg mijlen voor geselecteerde reis en passagiers',
  'Text408' : 'HR-tickets. Kunnen niet worden omgeruild. Enkel annuleren is mogelijk. Teruggifte van de Miles/Vouchers enkel indien de annulering gebeurt voor de reis EN in hetzelfde kwartaal als de boeking (voor',
  'Text409' : 'HR-tickets. Kunnen niet worden omgeruild. Enkel annuleren is mogelijk. Teruggifte van de Miles/Vouchers enkel indien de annulering gebeurt voor de reis EN in hetzelfde kwartaal als de boeking (voor',
  'Text410' : "Deze boeking is niet restitueerbaar",
  'Text411' : 'Bevestigen',
  'Text412' : 'Status',
  'Text413' : 'Datum reservatie',
  'Text414' : 'Houd er rekening mee dat hieronder alleen treinen worden weergegeven die beschikbaar zijn voor uw korting. Niet vergelijken met eurostar.com',
  'Text415' : 'Totale prijs',
  'Text416' : "HR-tickets. Kunnen niet worden omgeruild. Enkel annuleren is mogelijk. Teruggifte van de Miles/Vouchers enkel indien de annulering gebeurt voor de reis EN in hetzelfde kwartaal als de boeking (voor",
  'Text417' : 'Totaal wordt terugbetaald',
  'Text418' : 'Geen van de tickets in deze boeking kan worden geannuleerd.',
  'Text419': 'Thalys medewerkers, gebruik jouw Eurostar e-mailadres om in te loggen, bijv.',
  'Text421': 'Eurostar Red Vouchers Q&A',
  'Text422' : 'Geannuleerde traject(en)',
  'Text423': 'Wat je vervolgens moet doen',
  'Text424': 'Wanneer je geboekt hebt, ga dan naar Beheer je boeking op',
  'Text425': 'Aanvaarden',
  'Text426': 'Terug',
  'Text427': 'Eurostar',
  'Text428': 'en vul je Advance Passenger Information (API) in.',
  'Text429': 'Je krijgt je tickets pas nadat je deze info hebt opgegeven.',
  'Text430': 'U selecteert een reis naar een willekeurig Belgisch station. Vouchers kunnen alleen worden gebruikt voor de reis tussen Londen en Brussel MIDI, dus u zult de rest met uw bankkaart moeten betalen.',
  'Text431': 'Elke Belgische stationreis',
  'Text432': 'Echtgeno(o)t(e) / Partner',
  'Text433': 'De gegevens van uw echtgeno(o)t(e)/partner zullen gecontroleerd worden door het HR-team.',
  'Text434': 'Als u een fout heeft gemaakt bij het invoeren van de gegevens van uw echtgeno(o)t(e) of partner, of als u de gegevens wilt wijzigen, stuur dan een e-mail ',
  'Text435': 'traveldesk@eurostar.com',
  'Text436': ' te sturen om de gegevens van uw echtgenoot/partner bij te werken.',
  'Text437': 'This booking can be cancelled but you need to email staff.travel@eurostar.com',
  'Text438': 'Max 5 passengers ',
  'Text439': 'Max passengers - Account Holder and Spouse/Partner, Dependants',
  'Text440': 'Max 9 passengers',
  'Text441': 'Max 9 passengers',
  'Text442': 'Max 5 passengers',
  'Text443': 'Max 9 passengers',
  'Text444': 'Max passengers - Account Holder and 5 others'
};

app.config(function ($translateProvider) {

  /*$translateProvider.useStaticFilesLoader({
    prefix: 'content/data/locale-',
    suffix: '.json'
  });
  $translateProvider.preferredLanguage('en');
  */
  $translateProvider.translations('en', translate_en);
  $translateProvider.translations('fr', translate_fr);
  $translateProvider.translations('be', translate_be);
  $translateProvider.translations('nl', translate_be);
  $translateProvider.preferredLanguage('en');

});

$('.navbar-nav li a').click(function(event) {
  $('.navbar-collapse').collapse('hide');
});

export default app;
