import app from '../app';
import { closeModal, openModal } from '../utils';

app.controller('MealsController', function ($scope, $rootScope, $location, RESOURCES, globalData,  afterSalesService, md5, $translate) {
  init();
  function init() {
    $scope.booking = globalData.getElgBkgData();
    $scope.currency = $rootScope.loggedInUser.ARIA_User.Default_Currency;
    $scope.bookingType = $scope.booking.tnr.tnr_booking_type; // Read Booking Type 'D' == Duty, 'P' = Pals etc.
    $scope.bookingHorizon = getMaxDays();
    let passengerMeals = [];

    $scope.booking.tnr.tnr_segments.forEach(segment => {
      // let passengerMeals = [];
      // $scope.booking.tnr.tnr_names.forEach(name => {
      //   passengerMeals.push({ "passengerId" : name.s3_passenger_id,
      //                        "passengerFirstName": name.name_initials,
      //                         "passengerLastName": name.name_surname,
      //                         "mealCode" : ""
      //                       });
      // });
      // segment.passengerMeals = passengerMeals;
      segment.passengerMeals.forEach(passengerMeal => {
        let name = $scope.booking.tnr.tnr_names.filter( name => name.s3_passenger_id === passengerMeal.passengerId );
        passengerMeal.passengerFirstName = name[0].name_initials;
        passengerMeal.passengerLastName = name[0].name_surname;
        if (passengerMeal.mealCode) {
          passengerMeal.mealCode = passengerMeal.mealCode;
        }
        else {
          passengerMeal.mealCode = name[0].mealCode === undefined ? '' :  name[0].mealCode;
        }

      });
    });
    $scope.paxMealList = $rootScope.paxMealList;
    if ($scope.paxMealList){
      if ($scope.paxMealList.length <=0){
        loadMealList();
      }
    }
    else {
      loadMealList();
    }

  }
  function loadMealList() {
    let mealListRequest = { 'RetMealsServiceIn': { 'reference': $scope.booking.tnr.tnr_ers_pnr, 's3_currency': $scope.currency}};
    let sUrl = RESOURCES.HOST;

    openModal();
    afterSalesService.getMealList(sUrl, mealListRequest)
      .then(
        function (res) {
          if (res.wsi_error) {
            $scope.mealsError = 'Unable to fetch meals please try again later.';
          }
          else {
            $scope.paxMealList = res.mealsServiceOut.meals;
          }

          closeModal();
        },
        function (err) {
          closeModal();
        });
  }

  function getMaxDays() {
    var h1 = 0;
    var h2 = 0;

    h1 = globalData.getStationHorizonByCode($scope.booking.tnr.tnr_segments[0].segt_dep_station.trim());
    h2 = globalData.getStationHorizonByCode($scope.booking.tnr.tnr_segments[0].segt_arr_station.trim());

    if (h1 <= h2) {
      return h1;
    } else if (h2 <= h1) {
      return h2;
    }
  }
  $scope.goBack = function () {
    globalData.setRefCode($scope.booking.tnr.tnr_ers_pnr);
    $location.path('/retrieve');
  };

  $scope.saveMeals = () => {
    const sUrl = RESOURCES.HOST;
    $scope.booking.tnr.tnr_segments.forEach( segment => {
      openModal();
      let passengerMeals = [];
      segment.passengerMeals.forEach(paxMeal => {
        if (paxMeal.mealCode.trim() != ''){
          passengerMeals.push(paxMeal);
        }
      });

      if (passengerMeals.length > 0){
        let request =  { 'changeMealsServiceIn' : {
          'reference' : $scope.booking.tnr.tnr_ers_pnr,
          'trainNumber' : segment.segt_train_num,
          's3_currency' : $scope.currency,
          'departureDate' : formatDate($scope.ShowFullDate(segment.segt_dep_date)),
          'passengerMeals' : passengerMeals
        }};
        afterSalesService.changeMeals(sUrl, request)
          .then(function (res) {
            if (res.wsi_error){
              $scope.mealsError = 'Error saving meals please try again.';
              closeModal();
            }
            else if (res.s3_error){
              $scope.mealsError = 'Error saving meals please try again.';
              closeModal();
            }
            else {
              closeModal();
              globalData.setRefCode($scope.booking.tnr.tnr_ers_pnr);
              $location.path('/retrieve');
            }
          },
          function(err){
            $scope.mealsError = 'Error saving meals please try again.';
            closeModal();
          }
          );
      }
      else {
        closeModal();
        globalData.setRefCode($scope.booking.tnr.tnr_ers_pnr);
        $location.path('/retrieve');
      }
    });

  };

  function formatDate(sDate) {
    let result;
    let month = (sDate.getMonth()+1) <10 ? '0' + (sDate.getMonth()+1).toString(): (sDate.getMonth()+1).toString();
    let day = (sDate.getDate() < 10) ? '0' +(sDate.getDate()) : sDate.getDate();

    result = `${sDate.getFullYear()}-${month}-${day}`;

    return result;
  }

  $scope.ShowFullDate = function (data) {
    return globalData.getFullDate($scope.bookingHorizon, data, '00:00');
  };

  $scope.showValidSegments = function(item){
    let result = false;
    if (item) {
      if (globalData.isPastDate(item.segt_dep_date, item.segt_dep_time) == false) {
        result = true;
      }
    }
    // console.log("showValidSegments : " + result);
    return result;
  };

  function toggleChevron(e) {
    $(e.target)
      .prev('.panel-heading')
      .find('i.indicator')
      .toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
  }

  $('#accordion').on('hidden.bs.collapse', toggleChevron);
  $('#accordion').on('shown.bs.collapse', toggleChevron);

});
