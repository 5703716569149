import app from '../app';
import { convertDate, dateDiff, getQuarter } from '../utils';
import {SHORT_CODE_TO_UIC_MAP} from "../helpers/short-code-to-uic-map";

app.service('loginService', function($http, $q) {
  //this.userData = {};
  //console.log ("in login service");
  this.getData = function(userid, passcode, resDomain, nnce) {
    var defer = $q.defer();
    var userLoginData = {
      Aria_User_Name: userid,
      Aria_Passcode: passcode,
      Client_Tranx_Id: nnce
    };
    /* var authRequest = {
         method: 'POST'
         url: 'http://localhost:9090/ws/simple/getEmployee',
         headers: {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'},
         data: userLoginData
      }
      $http(authRequest)*/
    //$http.post (resDomain + 'ws/rest/salesforce1/employee/getEmployee', userLoginData,{headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(resDomain + 'ws/rest/v2.0/sf/employee/getEmployee', userLoginData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        //console.log(data);
        //this.userData = data;
        defer.resolve(data);
      })
      .error(function(msg, code) {
        //console.log(msg);
        defer.reject(msg);
      });
    return defer.promise;
  };

  this.updateUser = function(userid, pass, nnce, newPass, resDomain) {
    var defer = $q.defer();
    var userLoginData = {
      Aria_User_Name: userid,
      Aria_Passcode: pass,
      Client_Tranx_Id: nnce,
      Aria_Passcode_New: newPass
    };
    //$http.post (resDomain + 'ws/rest/salesforce1/password/updatePassword', userLoginData,{headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(
        resDomain + 'ws/rest/v2.0/sf/password/updatePassword',
        userLoginData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        }
      )
      .success(function(data) {
        //console.log(data);
        //this.userData = data;
        defer.resolve(data);
      })
      .error(function(msg, code) {
        //console.log(msg);
        defer.reject(msg);
      });
    return defer.promise;
  };

  this.reset = function(userid, resDomain) {
    var defer = $q.defer();
    var userLoginData = { Aria_User_Name: userid };
    //$http.post (resDomain + 'ws/rest/salesforce1/password/resetPassword', userLoginData,{headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(
        resDomain + 'ws/rest/v2.0/sf/password/resetPassword',
        userLoginData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        }
      )
      .success(function(data) {
        //console.log(data);
        //this.userData = data;
        defer.resolve(data);
      })
      .error(function(msg, code) {
        //console.log(msg);
        defer.reject(msg);
      });
    return defer.promise;
  };
  return this;
});

app.service('bookingService', function($http, $q) {
  var task = this;
  //****************************************************************************************************************************//
  task.bookJourney = function(bkgData, resDomain) {
    var defer = $q.defer();
    var bkgRefData = { CreateBookingServiceIn: bkgData };
    bkgRefData = JSON.stringify(bkgRefData);
    //console.log("stringify");
    //console.log(bkgRefData);
    //$http.post(resDomain + "ws/rest/aria1/booking/createBooking", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(resDomain + 'api/agent-portal/create-booking', bkgRefData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  //****************************************************************************************************************************//
  task.createCase = function(bkgCaseData, resDomain) {
    var defer = $q.defer();
    var bkgRefData = { Booking_Case: bkgCaseData };

    bkgRefData = JSON.stringify(bkgRefData);
    //console.log("stringify");
    //console.log(bkgRefData);

    //$http.post(resDomain + "ws/rest/salesforce1/case/createCase", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(resDomain + 'ws/rest/v2.0/sf/case/createCase', bkgRefData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  //****************************************************************************************************************************//
  task.ticketJourney = function(bkgData, resDomain) {
    var defer = $q.defer();
    var bkgRefData = { TicketBookingServiceIn: bkgData };
    bkgRefData = JSON.stringify(bkgRefData);

    const url = `${resDomain}api/agent-portal/ticket-booking`;
    const headers = {'Content-Type': 'application/json'};
    $http
      .post(url, bkgRefData, {
        headers: headers
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  //****************************************************************************************************************************//
  //****************************************************************************************************************************//
  task.getSSR = function(userid, passcode, nnce, resDomain) {
    var defer = $q.defer();
    var bkgRefData = {
      Aria_User_Name: userid,
      Aria_Passcode: passcode,
      Client_Tranx_Id: nnce
    };

    //$http.get(resDomain + "ws/rest/aria1/tfax/getTfax",bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .get(resDomain + 'ws/rest/s3/aria/tfax/getTfax', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  //****************************************************************************************************************************//

  //****************************************************************************************************************************//
  task.getSeats = function(userid, passcode, nnce, bkgSeatsData, resDomain) {
    var defer = $q.defer();
    var bkgRefData = {
      SeatMapServiceIn: {
        Aria_User_Name: userid,
        Aria_Passcode: passcode,
        Client_Tranx_Id: nnce,
        dep_date: bkgSeatsData.dep_date,
        dep_station: bkgSeatsData.dep_station,
        arr_station: bkgSeatsData.arr_station,
        train_number: bkgSeatsData.train_number,
        class: bkgSeatsData.class,
        s3_currency: bkgSeatsData.s3_currency
      }
    };
    $http
      .post(resDomain + 'ws/rest/s3/aria/availability/seatmap', bkgRefData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  //****************************************************************************************************************************//
  task.updateSeats = function(userid, passcode, nnce, resDomain,  seatsData, reference, currency) {
    var defer = $q.defer();
    var bkgRefData = {
      updateSeatsServiceIn: {
        Aria_User_Name: userid,
        Aria_Passcode: passcode,
        Client_Tranx_Id: nnce,
        seats: seatsData,
        reference: reference,
        s3_currency: currency
      }
    };
    $http
      .post(resDomain + 'ws/rest/s3/aria/booking/updateSeats', bkgRefData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  return task;
});

app.service('afterSalesService', function($http, $q) {
  var task = this;
  //****************************************************************************************************************************//
  task.exchangeBooking = function(bkgData, resDomain) {
    var defer = $q.defer();
    var bkgRefData = { ExchBookingServiceIn: bkgData };
    bkgRefData = JSON.stringify(bkgRefData);
    //console.log("stringify");
    //console.log(bkgRefData);
    //$http.post(resDomain + "ws/rest/aria1/booking/exchBooking", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(resDomain + 'api/agent-portal/exchange-cancel-booking', bkgRefData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });

    return defer.promise;
  };
  //****************************************************************************************************************************//
  //Change Meals
  this.changeMeals = function(sUrl, request) {
    var defer = $q.defer();
    $http.post( `${sUrl}ws/rest/s3/aria/booking/changeMeals`,
      request,
      { headers:
          {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      })
      .success(function(data) {
        //console.log(data);
        //this.userData = data;
        defer.resolve(data);
      })
      .error(function(msg, code) {
        //console.log(msg);
        defer.reject(msg);
      });
    return defer.promise;
  };
  this.getMealList = function(sUrl, request) {
    var defer = $q.defer();
    $http.post( `${sUrl}ws/rest/s3/aria/availability/meals`,
      request,
      { headers:
          {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      })
      .success(function(data) {
        //console.log(data);
        //this.userData = data;
        defer.resolve(data);
      })
      .error(function(msg, code) {
        //console.log(msg);
        defer.reject(msg);
      });
    return defer.promise;
  };
  return task;
});

app.service('availabilityService', function($http, $q) {
  var task = this;

  //****************************************************************************************************************************//
  task.getAvailability = function(
    userid,
    passcode,
    nnce,
    origin,
    destination,
    dDate,
    dTime,
    resDomain
  ) {
    var defer = $q.defer();
    //console.log(origin + "-" + destination + "-" + dDate + "-" + dTime);
    var bkgRefData = {
      AvailabilityServiceIn: {
        Aria_User_Name: userid,
        Aria_Passcode: passcode,
        Client_Tranx_Id: nnce,
        dep_date: dDate,
        dep_station: origin,
        arr_station: destination,
        is_dep_time: '1',
        dep_arr_time: dTime
      }
    };
    //$http.post(resDomain + "ws/rest/aria1/availability/getAvailability", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(
        resDomain + 'ws/rest/s3/aria/availability/getAvailability',
        bkgRefData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        }
      )
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  //****************************************************************************************************************************//
  task.getFareAvailability = function(
    userid,
    passcode,
    nnce,
    bType,
    pNum,
    origin,
    destination,
    dDate,
    resDomain,
    currency
  ) {
    var defer = $q.defer();
    //console.log(origin + "-" + destination + "-" + dDate);
    var bkgRefData = {
      FareAvailabilityServiceIn: {
        Aria_User_Name: userid,
        Aria_Passcode: passcode,
        Client_Tranx_Id: nnce,
        booking_type: bType,
        num_pax: pNum,
        dep_date: dDate,
        dep_station: origin,
        arr_station: destination,
        currency: currency
      }
    };
    //$http.post(resDomain + "ws/rest/aria1/availability/fareAvailability", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(
        resDomain + 'api/agent-portal/journey-search',
        bkgRefData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };

  task.getExchangeFareAvailability = function(
      exchangeItems,
      bookingRef,
      userid,
      passcode,
      nnce,
      bType,
      pNum,
      origin,
      destination,
      dDate,
      resDomain,
      currency
  ) {
    var defer = $q.defer();
    //console.log(origin + "-" + destination + "-" + dDate);
    var bkgRefData = {
      FareAvailabilityServiceIn: {
        Aria_User_Name: userid,
        Aria_Passcode: passcode,
        Client_Tranx_Id: nnce,
        booking_type: bType,
        num_pax: pNum,
        dep_date: dDate,
        dep_station: origin,
        arr_station: destination,
        currency: currency,
        exchange_items: exchangeItems,
        booking_ref: bookingRef
      }
    };
    //$http.post(resDomain + "ws/rest/aria1/availability/fareAvailability", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
        .post(
            resDomain + 'api/agent-portal/exchange-search',
            bkgRefData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        )
        .success(function(data) {
          //console.log (data);
          defer.resolve(data);
        })
        .error(function(msg, code) {
          defer.reject(msg);
        });
    return defer.promise;
  };
  //****************************************************************************************************************************//
  return task;
});

app.service('viewBookingService', function($http, $q) {
  var task = this;
  //****************************************************************************************************************************//
  task.getBooking = function(
    userid,
    passcode,
    nnce,
    strBookingRef,
    resDomain,
    gBkgData,
    currency

  ) {
    var defer = $q.defer();
    // If booking details already available, skip recall from ELGAR
    if (gBkgData && gBkgData.tnr.tnr_ers_pnr == strBookingRef) {
      var bkgRsp = {};
      bkgRsp.BookingServiceOut = {};
      bkgRsp.BookingServiceOut.tnr = gBkgData.tnr;
      bkgRsp.BookingServiceOut.seats = gBkgData.seats;
      bkgRsp.BookingServiceOut.pricing = gBkgData.pricing;
      // You dont need reject here, a global booking should never be set
      // if there is any error
      defer.resolve(bkgRsp);
    } else {
      var bkgRefData = {
        BookingServiceIn: {
          Aria_User_Name: userid,
          Aria_Passcode: passcode,
          Client_Tranx_Id: nnce,
          locator: strBookingRef,
          s3_currency: currency
        }
      };
      //$http.post(resDomain + "ws/rest/aria1/booking/queryBooking", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
      $http
        .post(
          /* resDomain + 'ws/rest/s3/aria/booking/queryBooking',
          bkgRefData,
          {
            headers: {
              'Content-Type': 'x-www-form-urlencoded;charset=UTF-8'
            }
          } */
          resDomain + 'api/agent-portal/retrieve-booking',
          bkgRefData,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .success(function(data) {
          defer.resolve(data);
        })
        .error(function(msg, code) {
          defer.reject(msg);
        });
    }
    return defer.promise;
  };
  //****************************************************************************************************************************//
  return task;
});

/*---------------- Update Employee Service ----------------*/
app.service('updateEmployeeService', function($http, $q) {
  var task = this;
  //****************************************************************************************************************************//
  task.updateUserData = function(empContactData, resDomain) {
    var defer = $q.defer();

    var bkgRefData = {
      ARIA_User: {
        Aria_User_Name: empContactData.Aria_User_Name,
        Aria_Passcode: empContactData.Aria_Passcode,
        Client_Tranx_Id: empContactData.nnce,
        Customer_Email: empContactData.Customer_Email,
        Street: empContactData.Street,
        City: empContactData.City,
        State: empContactData.State,
        PostalCode: empContactData.PostalCode,
        Country: empContactData.Country,
        MobilePhone: empContactData.MobilePhone,
        HomePhone: empContactData.HomePhone,
        DefaultLanguage: empContactData.DefaultLanguage,
        DefaultCurrency: empContactData.DefaultCurrency
      }
    };
    //$http.post(resDomain + "ws/rest/salesforce1/employee/updateEmployee", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(resDomain + 'ws/rest/v2.0/sf/employee/updateEmployee', bkgRefData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  //****************************************************************************************************************************//
  //****************************************************************************************************************************//
  task.updateDependantFlag = function(empContactData, resDomain) {
    var defer = $q.defer();

    var bkgRefData = {
      ARIA_User: {
        Aria_User_Name: empContactData.Aria_User_Name,
        Aria_Passcode: empContactData.Aria_Passcode,
        Client_Tranx_Id: empContactData.nnce,
        Has_No_Dependant: empContactData.noDependant
      }
    };

    $http
      .post(resDomain + 'ws/rest/v2.0/sf/employee/updateEmployee', bkgRefData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  //****************************************************************************************************************************//

  //****************************************************************************************************************************//
  task.getBookingHistory = function(userid, customerEmail, passcode, nnce, resDomain) {
    var defer = $q.defer();

    var bkgRefData = {
      Aria_User_Name: userid,
        Customer_Email: customerEmail,
      Aria_Passcode: passcode,
      Client_Tranx_Id: nnce
    };

    //$http.post(resDomain + "ws/rest/salesforce1/case/getCaseList", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(resDomain + 'api/agent-portal/history-bookings', bkgRefData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });

    return defer.promise;
  };
  //****************************************************************************************************************************//
  /*---------------- Dependant load/add Service ----------------*/
  this.createDependant = function(userid, passcode, resDomain, nnce, dData) {
    var defer = $q.defer();
    var requestData = {
      auth: { uid: userid, key: passcode, tid: nnce },
      req: {
        first_name: dData.first_name,
        last_name: dData.last_name,
        date_of_birth: dData.date_of_birth
      }
    };
    $http
      .post(
        resDomain + 'ws/rest/v2.0/sf/employee/createDependant',
        requestData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        }
      )
      .success(function(data) {
        //console.log(data);
        //this.userData = data;
        defer.resolve(data);
      })
      .error(function(msg, code) {
        //console.log(msg);
        defer.reject(msg);
      });
    return defer.promise;
  };

  this.getDependants = function(userid, passcode, resDomain, nnce) {
    var defer = $q.defer();
    var requestData = { auth: { uid: userid, key: passcode, tid: nnce } };
    $http
      .post(resDomain + 'ws/rest/v2.0/sf/employee/getDependants', requestData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        //console.log(data);
        //this.userData = data;
        defer.resolve(data);
      })
      .error(function(msg, code) {
        //console.log(msg);
        defer.reject(msg);
      });
    return defer.promise;
  };

  this.getSpousePartner = function(userName, resDomain) {
    var defer = $q.defer();
    const data = JSON.stringify({ userName });
    
    $http
      .post(resDomain + 'api/agent-portal/get-spouse-partner', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .success(function(data) {
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    
    return defer.promise;
  };

  this.updateSpousePartner = function(userName, firstName, lastName, resDomain) {
    var defer = $q.defer();
    const data = JSON.stringify({ userName, firstName, lastName });
    
    $http
      .post(resDomain + 'api/agent-portal/update-spouse-partner', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    
    return defer.promise;
  }
  
  /*---------------- Dependant load/add Service ----------------*/
  return task;
});

/*---------------- Update Employee Service ----------------*/

/*---------------- Payment Service ----------------*/

app.service('PaymentService', function($http, $q) {
  this.redeemVoucher = function(vData, aData, resDomain) {
    var defer = $q.defer();
    var bkgRefData = {
      auth: { uid: aData.uid, key: aData.key, tid: aData.tid },
      req: {
        vo_ref: vData.vo_ref,
        amount: vData.amount,
        currency: vData.currency,
        pnr: vData.pnr,
        date: vData.date
      }
    };
    console.log(bkgRefData);
    $http
      .post(resDomain + 'ws/rest/v2.0/dc/payment/evRedeem', bkgRefData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  this.rollbackVoucher = function(vData, aData, resDomain) {
    var defer = $q.defer();
    var bkgRefData = {
      auth: { uid: aData.uid, key: aData.key, tid: aData.tid },
      req: { pnr: vData.pnr, rdm_dcref: vData.dcRef }
    };
    console.log(bkgRefData);
    $http
      .post(resDomain + 'ws/rest/v2.0/dc/payment/evRollback', bkgRefData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };

  this.paymentSetup = function(inputData, domain) {
    var defer = $q.defer();
    var payLoad = inputData;
    //console.log(bkgRefData);
    var url = `${domain}api/train-inventory/payment-setup`;
    var headers = {'Content-Type': 'application/json'};
    $http
      .post(url, payLoad, {headers: headers})
      .success(function(data) {
        //console.log (data);
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  /****************************************************************/
  return this;
});

//Service to access eTAP API.
app.service('eTapServices', function($http, $q) {
  // ETAP Authenticate Request
  this.authenticate = function(bkgRef, leadPax, sUrl, hdrData) {
    var defer = $q.defer();
    var bkgRefData = { lastName: leadPax, pnr: bkgRef };
    $http
      .post(sUrl, bkgRefData, {
        headers: {
          'X-apikey': hdrData.apikey,
          cid: hdrData.cid,
          'Content-Type': 'application/json'
        }
      })
      .success(function(data) {
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };

  // ETAP UPDATE PASSENGER Request
  this.updatePax = function(data, sUrl, hdrData) {
    var defer = $q.defer();
    $http
      .put(sUrl, data, {
        headers: {
          cid: hdrData.cid,
          'X-apikey': hdrData.apikey,
          'Content-Type': 'application/json',
          Authorization: hdrData.authorization
        }
      })
      .success(function(data) {
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  // ETAP UPDATE PASSENGER Request
  this.ticket = function(data, sUrl, hdrData) {
    var defer = $q.defer();
    $http
      .post(sUrl, data, {
        headers: {
          Accept: 'application/json',
          cid: hdrData.cid,
          'X-apikey': hdrData.apikey,
          'Content-Type': 'application/json',
          Authorization: hdrData.authorization
        }
      })
      .success(function(data) {
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };
  // ETAP CREATE INFANT Request
  this.createInfant = function(data, sUrl, hdrData) {
    var defer = $q.defer();
    $http
      .post(sUrl, data, {
        headers: {
          cid: hdrData.cid,
          'X-apikey': hdrData.apikey,
          'Content-Type': 'application/json',
          Authorization: hdrData.authorization
        }
      })
      .success(function(data) {
        defer.resolve(data);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };

  return this;
});
/*---------------- Payment Service ----------------*/

//Service to store and retrieve the global data.
app.service('globalData', function($http, $q) {
  var task = this;

  /*  var bookingEnquiryData = {
         isReturnBooking: "",
         origin: "",
         originFullName: "",
         destination: "",
         destinationFullName: "",
         dDate: "",
         dDateFull: "",
         dTime: "",
         paxNum: "",
         returnOrigin: "",
         returnOriginFullName: "",
         returnDestination: "",
         returnDestinationFullName: "",
         returndDate: "",
         returndDateFull: "",
         returndTime: ""
     };

   var originStationList = [
       { name: 'London St. Pancras Intl', shortCode: 'SPX', longCode: 'GBSPX' },
       { name: 'Ashford Intl', shortCode: 'ASI', longCode: 'GBASI' },
       { name: 'Ebbsfleet Intl', shortCode: 'EBF', longCode: 'GBEBF' },
       { name: 'Paris Gare Du Nord', shortCode: 'PBN', longCode: 'FRPNO' },
       { name: 'Bruxelles Midi', shortCode: 'BXS', longCode: 'BEBMI' }
     ];

   var destinationStationList = [
             { name: 'Paris Gare Du Nord',  shortCode: 'PBN',  longCode: 'FRPNO' },
             { name: 'Bruxelles Midi', shortCode: 'BXS', longCode: 'BEBMI' },
             { name: 'Ebbsfleet Intl', shortCode: 'EBF', longCode: 'GBEBF' },
             { name: 'London St. Pancras Intl', shortCode: 'SPX', longCode: 'GBSPX' },
             { name: 'Ashford Intl', shortCode: 'ASI', longCode: 'GBASI' },
             { name: 'Avignon', shortCode: 'AVT', longCode: 'FRAVG' }
           ];

   task.bookingSale = {
   names: [{ firstname:"", surname:"", mobile_number:"", email_address:"", paxtype:"" }],
   segments: [{ origin:"", destination:"", carrier:"", train_number:"", dep_date:"", dep_time:"", ccos:"" }],
   contact_details: [{ firstname:"",surname:"", address:{line_1:"", line_2:"", line_3:"", country:"", postcode:""}, phone:["44123123223"], email:[""] }],
   ticket: "1",
   payments: [{type:"", amount:"", currency:"", indicator:"", note:"", cc_details: {token:"", expiry:"", input_method:"", scheme:"", payment_ref:"", auth_code:"", elgar_ref:"" },
                 cc_holder_firstname:"", cc_holder_surname:"", cc_payment_address: {line_1:"", line_2:"", line_3:"", country:"", postcode:""}, } ]

   };
   */
  var bookingEnquiryData = {};
  var originStationList = [];
  var destinationStationList = [];
  var bookingType = '';
  var stationList = [];

  task.bookingSale = {};
  task.bookingSegments = [];
  task.passengers = [];
  task.payment = {};
  task.elgBkgData = undefined;
  task.currency = '';
  task.displayLanguage = '';
  task.refCode = '';
  task.afterSaleFlag = '';
  task.voucherPayment = [];
  /*
   task.x ='';
   task.get = function () {
      return;
   }
   task.set = function (data) {
      task. = data;
   }
   */

  /*---------------- Clear Global Data ----------------*/
  task.clearGlobal = function() {
    task.bookingEnquiryData = {};
    task.bookingType = '';
    task.bookingSale = {};
    task.bookingSegments = [];
    task.passengers = [];
    task.payment = {};
    task.elgBkgData = undefined;
    task.currency = '';
    task.refCode = '';
    task.afterSaleFlag = '';
    task.displayLanguage = '';
    task.voucherPayment = [];
    stationList = [];
  };
  /*---------------- Clear Global Data ----------------*/

  /*---------------- Book Ref Code ----------------*/
  task.getRefCode = function() {
    return task.refCode;
  };

  task.setRefCode = function(data) {
    task.refCode = data;
  };
  /*---------------- Book Ref Code ----------------*/

  /*---------------- DisplayLanguage ----------------*/

  task.getDisplayLanguage = function() {
    return task.displayLanguage;
  };

  task.setDisplayLanguage = function(data) {
    task.displayLanguage = data;
  };
  /*---------------- DisplayLanguage ----------------*/

  /*---------------- Currency ----------------*/

  task.getCurrency = function() {
    return task.currency;
  };

  task.setCurrency = function(data) {
    task.currency = data;
  };

  task.getCurrencySign = function(data) {
    var result;
    if (data == 'GBP') {
      result = '£';
    } else if (data == 'EUR') {
      result = '€';
    } else if (data == 'USD') {
      result = '$';
    }
    return result;
  };

  task.getCurrencyCode = function(data) {
    var result;
    if (data == 'GBP') {
      result = '826';
    } else if (data == 'EUR') {
      result = '978';
    } else if (data == 'USD') {
      result = '840';
    }
    return result;
  };

  /*---------------- Currency ----------------*/

  /*---------------- Booking Type ----------------*/
  task.getbookingType = function() {
    return task.bookingType;
  };

  task.setbookingType = function(data) {
    task.bookingType = data;
  };

  /* task.getBookingTypeCodeText = function (bkgType) {
      console.log("getBookingTypeCodeText = " + bkgType);
      var bkgTypecode;
      if (bkgType == "Duty Booking") {
         bkgTypecode = "D";
      }
      else if (bkgType == "FIP Booking") {
         bkgTypecode = "F";
      }
      else if (bkgType == "Pals Booking") {
         bkgTypecode = "P";
      }
      else if (bkgType == "Momentum Booking") {
         bkgTypecode = "M";
      }
      else if (bkgType == "Employee Voucher Booking") {
         bkgTypecode = "B";
      }
      else if (bkgType == "Contractor Voucher Booking") {
         bkgTypecode = "G";
      }
      return bkgTypecode;
   }*/

  task.getBookingTypeText = function(bkgType) {
    //console.log("getBookingTypeText = " + bkgType);
    var bkgTypecode;
    if (bkgType == 'D') {
      /*$translate('Text273').then(function (txtMsg) {
            bkgTypecode = txtMsg;
         });*/
      //bkgTypecode = "Duty Booking";
      bkgTypecode = 'Text273';
    } else if (bkgType == 'F') {
      /*$translate('Text274').then(function (txtMsg) {
            bkgTypecode = txtMsg;
         });*/
      //bkgTypecode = "FIP Booking";
      bkgTypecode = 'Text274';
    } else if (bkgType == 'P') {
      /*$translate('Text275').then(function (txtMsg) {
            bkgTypecode = txtMsg;
         });*/
      //bkgTypecode = "Pals Booking";
      bkgTypecode = 'Text275';
    } else if (bkgType == 'R') {
      bkgTypecode = 'Text404';
    } else if (bkgType == 'M') {
      /*$translate('Text276').then(function (txtMsg) {
            bkgTypecode = txtMsg;
         });*/
      //bkgTypecode = "Momentum Booking";
      bkgTypecode = 'Text276';
    } else if (bkgType == 'I') {
      /*$translate('Text276').then(function (txtMsg) {
            bkgTypecode = txtMsg;
         });*/
      //bkgTypecode = "Mitie Booking";
      bkgTypecode = 'Text309';
    } else if (bkgType == 'B') {
      /*$translate('Text277').then(function (txtMsg) {
            bkgTypecode = txtMsg;
         });*/
      //bkgTypecode = "Eurostar Staff Voucher Booking";
      bkgTypecode = 'Text277';
    } else if (bkgType == 'G') {
      /*$translate('Text278').then(function (txtMsg) {
            bkgTypecode = txtMsg;
         });*/
      //bkgTypecode = "Contractor Voucher Booking";
      bkgTypecode = 'Text278';
    } else if (bkgType == 'T') {
      bkgTypecode = 'Text278_1';
    } else if (bkgType == 'Y') {
      bkgTypecode = 'Text278_2';
    }
    return bkgTypecode;
  };

  task.getBookingTypeCode = function(bkgType) {
    //console.log("getBookingTypeCode = " + bkgType);
    var bkgTypecode;

    if (bkgType == 'D') {
      bkgTypecode = 1;
    } else if (bkgType == 'F') {
      bkgTypecode = 2;
    } else if (bkgType == 'P') {
      bkgTypecode = 4;
    } else if (bkgType == 'M') {
      bkgTypecode = 8;
    } else if (bkgType == 'I') {
      bkgTypecode = 8;
    } else if (bkgType == 'B') {
      bkgTypecode = 16;
    } else if (bkgType == 'G') {
      bkgTypecode = 32;
    } else if (bkgType == 'T') {
      bkgTypecode = 16;
    } else if (bkgType == 'Y') {
      bkgTypecode = 16;
    } else if (bkgType == 'R') {
      bkgTypecode = 64;
    }

    return bkgTypecode;
  };
  /*---------------- Booking Type ----------------*/

  /*---------------- Enquiry Data ----------------*/
  task.getBookingEnquiryData = function() {
    //console.log("global data get = " + task.bookingEnquiryData.origin);
    return task.bookingEnquiryData;
  };

  task.incEnquiryDate = function(bReturn, maxDate) {
    //console.log("incEnquiryDate");
    if (bReturn == false) {
      var d = new Date(task.bookingEnquiryData.dDateFull);
      var d1 = maxDate;

      if (parseInt(dateDiff(d, d1)) > 0) {
        d.setDate(d.getDate() + 1);

        task.bookingEnquiryData.dDateFull = d;
        task.bookingEnquiryData.dDate = convertDate(d);
      }

      return d;
    } else {
      //console.log("inc date return");
      var d = new Date(task.bookingEnquiryData.returndDateFull);
      var d1 = maxDate

      if (parseInt(dateDiff(d, d1)) > 0) {
        d.setDate(d.getDate() + 1);
        task.bookingEnquiryData.returndDateFull = d;
        task.bookingEnquiryData.returndDate = convertDate(d);
      }
      //console.log(d);
    }
  };

  task.decEnquiryDate = function(bReturn, minDate) {
    //console.log("decEnquiryDate");
    if (bReturn == false) {
      //console.log(task.bookingEnquiryData);
      var d = new Date(task.bookingEnquiryData.dDateFull);
      var d1 = minDate;
      //console.log(d);
      //console.log(d1);
      //console.log(dateDiff(d, d1));
      if (dateDiff(d, d1) < 0) {
        d.setDate(d.getDate() - 1);
        task.bookingEnquiryData.dDateFull = d;
        task.bookingEnquiryData.dDate = convertDate(d);
      }

      return d;
    } else {
      //console.log("dec date return");
      var d = new Date(task.bookingEnquiryData.returndDateFull);
      var d1 = new Date(task.bookingEnquiryData.dDateFull); // return date shuld not be less than the outbound date.
      //console.log(d);
      //console.log(d1);
      //console.log(dateDiff(d, d1));
      if (dateDiff(d, d1) < 0) {
        d.setDate(d.getDate() - 1);
        task.bookingEnquiryData.returndDateFull = d;
        task.bookingEnquiryData.returndDate = convertDate(d);
      }
      //console.log(d);
    }
  };

  task.setBookingEnquiryData = function(data) {
    //console.log("global data set = " + data.origin);
    task.bookingEnquiryData = data;
  };
  /*---------------- Enquiry Data ----------------*/

  /*---------------- Station List Data ----------------*/

  task.LoadStationList = function(resDomain, currency) {
    var defer = $q.defer();
    var bkgRefData = { StationListServiceIn: { titlecase: '1', s3_currency: currency } };
    //$http.post(resDomain + "ws/rest/aria1/stations/getStationList", bkgRefData, {headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}})
    $http
      .post(
        resDomain + 'ws/rest/s3/aria/stations/getStationList',
        bkgRefData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        }
      )
      .success(function(data) {
        //console.log (data);
        var modifiedData = task.modifyStationListData(data);
        task.booking = modifiedData;
        defer.resolve(modifiedData);
      })
      .error(function(msg, code) {
        defer.reject(msg);
      });
    return defer.promise;
  };

  task.modifyStationListData = function (data) {
    const bookingHorizon = 366;
    const { StationListServiceOut: { stations: unmodifiedStations } } = data;
    const stations = unmodifiedStations.map((station) => ({ ...station, stn_bkg_hrzn: bookingHorizon }));
    return { StationListServiceOut: { stations } };
  };

  task.setStaionList = function(data) {
    //console.log(stationList);
    stationList = data.StationListServiceOut.stations;
  };

  task.getDCStation = function(code) {
    var result = {};
    var bFound = false;
    for (var iCount = 0; iCount < stationList.length; iCount++) {
      if (stationList[iCount].stn_code == code) {
        result.dc_Code = stationList[iCount].stn_dc_code;
        result.dc_Tz = stationList[iCount].stn_tz_gmt;
        bFound = true;
        break;
      }
    }
    if (bFound == false) {
      result.dc_Code = 'XX1';
      result.dc_Tz = 0;
    }
    return result;
  };

  // task.getStaionList = function(bkgType) {
  //   //console.log("Get Station List:");
  //   //console.log("getStaionList = " + bkgType);
  //   var oStationList = [];
  //   var bkgTypeCode = task.getBookingTypeCode(bkgType);
  //   for (var iCount = 0; iCount < stationList.length; iCount++) {
  //     if (stationList[iCount].stn_type & bkgTypeCode) {
  //       oStationList.push(stationList[iCount]);
  //       //console.log(stationList[iCount].stn_name);
  //     }
  //   }
  //   return oStationList;
  // };

  task.getStaionList = function(bkgType) {
    // console.log("Get Station List:");
    // console.log("getStaionList = " + bkgType);
    var oStationList = [];
    var bkgTypeCode = task.getBookingTypeCode(bkgType);
    for (var iCount = 0; iCount < stationList.length; iCount++) {
      if (stationList[iCount].stn_type !== 999 && (stationList[iCount].stn_type & bkgTypeCode)) {
        if (bkgType === 'M' || bkgType === 'I' || bkgType === 'Y') {
          if (stationList[iCount].stn_code != 'BEABS') {
            oStationList.push(stationList[iCount]);
          }
        } else {
          oStationList.push(stationList[iCount]);
        }
      }
    }
    return oStationList;
  };
  /*task.getOriginStationList = function () {
      console.log(originStationList);
      return originStationList;
   }
   task.getDestinationStationList = function () {
      console.log("Get Destination Station List = " + destinationStationList);
      return destinationStationList;
   }
   */

  task.getStationCode = function(stationName) {
    var result = '';
    for (var iCount = 0; iCount < stationList.length; iCount++) {
      if (stationName == stationList[iCount].stn_name) {
        result = stationList[iCount].stn_code;
        break;
      }
    }
    return result;
  };

  task.getStationCodeNumeric = function(stationName) {
    var result = '';
    for (var iCount = 0; iCount < stationList.length; iCount++) {
      if (stationName == stationList[iCount].stn_name) {
        result = SHORT_CODE_TO_UIC_MAP[stationList[iCount].stn_code];
        break;
      }
    }
    return result;
  };

  task.getStationName = function(stationCode) {
    var result = '';
    for (var iCount = 0; iCount < stationList.length; iCount++) {
      if (stationCode == stationList[iCount].stn_code) {
        result = stationList[iCount].stn_name;
        break;
      }
    }
    return result;
  };

  task.getStationHorizon = function(stationName) {
    var result = 0;
    for (var iCount = 0; iCount < stationList.length; iCount++) {
      if (
        stationName.toLowerCase() == stationList[iCount].stn_name.toLowerCase()
      ) {
        result = stationList[iCount].stn_bkg_hrzn;
        break;
      }
    }
    return result;
  };
  task.getStationHorizonByCode = function(stationCode) {
    var result = 0;
    for (var iCount = 0; iCount < stationList.length; iCount++) {
      if (
        stationCode.toLowerCase() == stationList[iCount].stn_code.toLowerCase()
      ) {
        result = stationList[iCount].stn_bkg_hrzn;
        break;
      }
    }
    return result;
  };
  /*---------------- Station List Data ----------------*/

  /*---------------- Get/Set the SALE data ----------------*/
  task.getBookingSaleData = function() {
    //console.log("getBookingSaleData");
    //console.log(task.bookingSale);
    return task.bookingSale;
  };

  task.setBookingSaleData = function(data) {
    task.bookingSale = data;
    // console.log("setBookingSaleData")
    //console.log(task.bookingSale);
  };

  /*---------------- Get/Set the Segments Selected ----------------*/
  task.getBookingSegments = function() {
    return task.bookingSegments;
  };

  task.setBookingSegments = function(data) {
    task.bookingSegments = data;
    //console.log("setBookingSegments")
    //console.log(task.bookingSegments);
  };
  /*---------------- Get/Set the Segments Selected ----------------*/

  /*---------------- Get/Set Passengers ----------------*/
  task.getPassengers = function() {
    //console.log("getPassengers");
    //console.log(task.passengers);
    return task.passengers;
  };

  task.setPassengers = function(data) {
    task.passengers = data;
    //console.log("setPassengers")
    //console.log(task.passengers);
  };
  /*---------------- Get/Set Passengers  ----------------*/

  /*---------------- Get/Set Payment ----------------*/
  task.getPayment = function() {
    //console.log("getPayment");
    //console.log(task.payment);
    return task.payment;
  };

  task.setPayment = function(data) {
    task.payment = data;
    //console.log("setPayment")
    //console.log(task.payment);
  };
  /*---------------- Get/Set Payment  ----------------*/

  /*---------------- Get/Set Booking ----------------*/
  //res.CreateBookingServiceOut object
  task.getElgBkgData = function() {
    //console.log("getElgBkgData");
    //console.log(task.elgBkgData);
    return task.elgBkgData;
  };

  task.setElgBkgData = function(data) {
    task.elgBkgData = data;
    //console.log("setElgBkgData")
    //console.log(task.elgBkgData);
  };
  /*---------------- Get/Set Booking  ----------------*/

  /* ---------------- Check Exchangable Segments ----------------*/
  /////
  // Returns true, if booking has at least 1 segment with
  // departure date in future
  /////
  task.hasSegDepartingInFuture = function(response) {
    var result = false;
    for (var i = 0; i < response.tnr.tnr_segments.length; i++) {
      if (
        task.isPastDate(
          response.tnr.tnr_segments[i].segt_dep_date,
          response.tnr.tnr_segments[i].segt_dep_time
        ) == false
      ) {
        result = true;
        break;
      }
    }
    //console.log ("hasSegDepartingInFuture:result = " + result);
    return result;
  };

  task.hasBookingCreatedInSameQuater = function(response) {
    const bookingQuater = getQuarter(response.tnr.tnr_pnr_create_time);
    const currentQuater = getQuarter(new Date().toISOString().split('T').shift());

    return bookingQuater > 0 && currentQuater > 0 &&  bookingQuater === currentQuater;
  };

  task.isPastDate = function(oDate, oTime) {
    var cDate = new Date();
    var dDate = new Date();
    var result = true;
    dDate = task.getFullDate(300, oDate, oTime);
    //console.log("isPastDate:dDate = " + dDate);
    if (datetimeDiff(dDate, cDate, 'm') >= 0) {
      result = false;
    }
    //console.log("isPastDate:result = " + result);
    return result;
  };

  task.getFullDate = function(maxDays, xDate, xTime) {
    var eDate = new Date();
    var cDate = new Date();
    var indx = xDate.split(' ');

    var tyear = parseInt(eDate.getFullYear()) + 1;
    var strdate = '';

    if (xTime) {
      strdate = indx[1] + ' ' + indx[0] + ' ' + tyear + ' ' + xTime;
    } else {
      strdate = indx[1] + ' ' + indx[0] + ' ' + tyear;
    }

    var dDate = new Date(strdate);
    var result = false;
    eDate.setDate(cDate.getDate() + maxDays);

    if (dDate >= eDate) {
      dDate.setMonth(dDate.getMonth() - 12);
    }

    return dDate;
  };

  task.compareDates = function(date1, time1, date2, time2) {
    //console.log("compareDates date1 = " + date1);
    //console.log("compareDates date2 = " + date2);
    //console.log("compareDates time1 = " + time1);
    //console.log("compareDates time2 = " + time2);

    var result = false;
    var sDate1 =
      date1.getMonth() +
      1 +
      '/' +
      date1.getDate() +
      '/' +
      date1.getFullYear() +
      ' ' +
      time1;
    var sDate2 =
      date2.getMonth() +
      1 +
      '/' +
      date2.getDate() +
      '/' +
      date2.getFullYear() +
      ' ' +
      time2;
    var d1 = new Date(sDate1);
    var d2 = new Date(sDate2);

    //console.log("compareDates d1 = " + d1);
    //console.log("compareDates d2 = " + d2);

    if (datetimeDiff(d2, d1, 'm') > 60) {
      result = true;
    }
    return result;
  };

  task.calcDateDiffInHrs = function(date1, time1, date2, time2) {
    var result = false;
    var sDate1 =
      date1.getMonth() +
      1 +
      '/' +
      date1.getDate() +
      '/' +
      date1.getFullYear() +
      ' ' +
      time1;
    var sDate2 =
      date2.getMonth() +
      1 +
      '/' +
      date2.getDate() +
      '/' +
      date2.getFullYear() +
      ' ' +
      time2;
    var d1 = new Date(sDate1);
    var d2 = new Date(sDate2);

    //console.log("compareViaPoint d1 = " + d1);
    //console.log("compareViaPoint d2 = " + d2);
    result = datetimeDiff(d2, d1, 'h');

    return result;
  };

  function datetimeDiff(laterdate, earlierdate, mode) {
    //console.log("time Difference  > laterdate = " + laterdate.getTime());
    //console.log("time Difference  > earlierdate = " + earlierdate.getTime());
    var difference = laterdate.getTime() - earlierdate.getTime();
    //console.log("time Difference  = " + difference);

    var result = 0;

    switch (mode) {
    case 'd':
      result = Math.floor(difference / 1000 / 60 / 60 / 24);
      break;
    case 'h':
      result = Math.floor(difference / 1000 / 60 / 60);
      //console.log("time Difference Hours  = " + result);
      break;
    case 'm':
      result = Math.floor(difference / 1000 / 60);
      //console.log("time Difference minutes = " + result);
      break;
    case 's':
      result = Math.floor(difference / 1000);
      break;
    }
    //console.log("dateTimeDiff = " + result);
    return result;
  }
  /* ---------------- Check Exchangable Segments ----------------*/

  /* ---------------- Set Aftersale Flag ----------------*/
  task.getAfterSaleFlag = function() {
    //console.log("getAfterSaleFlag = " + task.afterSaleFlag);
    return task.afterSaleFlag;
  };
  task.setAfterSaleFlag = function(data) {
    //console.log("setAfterSaleFlag = " + data);
    task.afterSaleFlag = data;
  };
  /* ---------------- Set Aftersale Flag ----------------*/

  return task;
});
