import app from '../app';

app.controller('MainController', [ '$scope', '$rootScope', '$location', 'RESOURCES', 'md5', '$translate', function ($scope, $rootScope, $location, RESOURCES, md5, $translate) {
  init();

  function init() {
    $scope.versionNum = RESOURCES.VERSION;
    $scope.descriptionMsg = 'powered by Elgar.';

    if ($rootScope.loggedInUser) {
      $scope.showLogin = false;
    } else {
      $scope.showLogin = true;
      $location.path('/login');
    }
    $translate.use('en');
  }

  /*Add a watch on loggedInUser to determine whether to show Login or Logout*/
  $rootScope.$watch('loggedInUser', function (newVal, oldVal) {
    $scope.showLogin = !($rootScope.loggedInUser);
  }, true);

  $scope.gotoUG = function () {
    var url = 'content/data/Aria_User_Guide.pdf';
    var link = angular.element('<a href="' + url + '" target="_blank"></a>');
    angular.element(document.body).append(link);
    link[0].click();
    link.remove();
  };

  $scope.gotoFAQ = function () {
    var url = 'content/data/FAQ.pdf';
    var link = angular.element('<a href="' + url + '" target="_blank"></a>');
    angular.element(document.body).append(link);
    link[0].click();
    link.remove();
  };

  $scope.logOut = function () {
    $rootScope.loggedInUser = '';
    $location.path('/login');
  };
}]);
