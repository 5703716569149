import app from '../app';
import { closeModal, convetToShortDate, nonceGenerator, openModal } from '../utils';

app.controller('SeatMapController', function ($scope, $rootScope, $location, RESOURCES, globalData,  bookingService, md5, $translate) {
  function init() {
    $scope.currency = $rootScope.loggedInUser.ARIA_User.Default_Currency;
    $scope.booking = globalData.getElgBkgData();
    $scope.paxNames = [];
    // $scope.bkgSegments = globalData.getBookingSegments();
    $scope.seatAvail = [];
    //ng-model for the seat selection per segment.
    $scope.seat = [];
    $scope.enableApply = false;
    $scope.booking.tnr.tnr_segments.forEach(segment => {
      if (segment.segt_carrier === 'ES') {
        var obj = {};
        obj.originFullName = segment.segt_dep_station_full_name;
        obj.origin = segment.segt_dep_station;
        obj.destinationFullName = segment.segt_arr_station_full_name;
        obj.destination = segment.segt_arr_station;
        obj.departDate = segment.segt_dep_date;
        obj.departDateFull = segment.dep_date_full;
        obj.train = segment.segt_train_num;
        obj.ccos = segment.segt_class.substr(0, 1);
        obj.equipment_code = segment.segt_equipment;
        obj.seatsAssigned = false;
        $scope.seatAvail.push(obj);
      }
    });
    $scope.segment = $scope.seatAvail[0];
    // console.log("init => ", $scope.seat);
    setPaxNames();
    $scope.getAvailSeat($scope.segment);
  }

  function setPaxNames(){
    $scope.booking.seats.map(seat => {
      seat.seats_placements.forEach(seatPlacement => {
        let passenger = $scope.booking.tnr.tnr_names.find(name => name.s3_passenger_id === seatPlacement.s3_placement_passenger_id);
        let checkPax = $scope.paxNames.find(paxName => (paxName.firstName === passenger.name_initials && paxName.surName === passenger.name_surname));
        let segment = $scope.booking.tnr.tnr_segments.find(tnr_segment => (tnr_segment.segt_num === seat.seats_seg_num));
        let passengerSeat = seatPlacement.placement_seats[0];

        if (checkPax){
          $scope.paxNames.forEach(paxName => {
            if (paxName.firstName === passenger.name_initials && paxName.surName === passenger.name_surname){
              paxName.seats.push({
                train: segment.segt_train_num,
                seat: parseInt(passengerSeat.seat_num),
                coach: parseInt(passengerSeat.seat_coach_num),
                itemRef: seatPlacement.placement_tcn, seatChanged: false });
            }
          });
        }
        else {
          let pax = {
            Id: $scope.paxNames.length ,
            firstName: passenger.name_initials,
            surName: passenger.name_surname,
            seats: [{
              train: segment.segt_train_num,
              seat: parseInt(passengerSeat.seat_num),
              coach: parseInt(passengerSeat.seat_coach_num),
              itemRef: seatPlacement.placement_tcn,
              seatChanged: false
            }]
          };
          $scope.paxNames.push(pax);
        }
      });
    });
    // console.log("setPaxNames => ", $scope.seat);
  }

  $scope.setSelectedCoach = function (item) {
    $scope.mapImage = $scope.segment.equipment_code + '_' + item.coach_num + '.png';
    $scope.selectedCoach = item;
    // console.log("setSelectedCoach => ", $scope.seat);
  };

  $scope.updateSeats = function (tno, cno, sno, index) {
    var i = 0;
    var j = 0;
    $scope.dupSeatError = '';

    //Check  if seat already assigned to other pax;
    for (i = 0; i < $scope.paxNames.length; i++) {
      for (j = 0; j < $scope.paxNames[i].seats.length; j++) {
        if ($scope.paxNames[i].seats[j].train === tno) {
          if ($scope.paxNames[i].seats[j].seat === sno[index]) {

            $translate('Text253').then(function (txtMsg) {
              $scope.dupSeatError = txtMsg;
            });
            break;
          }
        }
      }
      if ($scope.dupSeatError) {
        break;
      }
    }
    if ($scope.dupSeatError == '') {
      for (i=0; i<$scope.paxNames.length; i++) {
        if (i === index) {
          for (j=0; j<$scope.paxNames[i].seats.length; j++) {
            if ($scope.paxNames[i].seats[j].train === tno) {
              if ($scope.paxNames[i].seats[j].coach != cno || $scope.paxNames[i].seats[j].seat != sno[index]){
                $scope.paxNames[i].seats[j].train = tno;
                $scope.paxNames[i].seats[j].coach = cno;
                $scope.paxNames[i].seats[j].seat = sno[index];
                $scope.paxNames[i].seats[j].seatChanged = true;
              }
            }
          }
        }
      }

      var check = false;
      //Check of all pax have Seats for the segment.
      for (i = 0; i < $scope.paxNames.length; i++) {
        for (j = 0; j < $scope.paxNames[i].seats.length; j++) {
          if ($scope.paxNames[i].seats[j].train === tno) {
            if (($scope.paxNames[i].seats[j].coach === '' || $scope.paxNames[i].seats[j].coach === undefined) &&
            ($scope.paxNames[i].seats[j].seat === '' || $scope.paxNames[i].seats[j].seat === undefined)) {
              check = true;
              break; //for j lo
            }
          }
        }
        // console.log("updateseats = ", check);
        if (check == true) {
          break;
        }
      }

      if (check == false) {
        for (var k = 0; k < $scope.seatAvail.length; k++) {
          if ($scope.seatAvail[k].train === tno) {
            $scope.seatAvail[k].seatsAssigned = true;
            $scope.enableApply = true;
            break;
          }
        }
      }
    } else {
      $scope.seat[index] = '';
    }
    // console.log("updateSeats => ", $scope.seat);
  };

  $scope.clearSeats = function (tno) {
    var blncheck = false;
    //console.log("clearSeats");
    for (var i = 0; i < $scope.paxNames.length; i++) {
      for (var j = 0; j < $scope.paxNames[i].seats.length; j++) {
        if ($scope.paxNames[i].seats[j].train == tno) {
          $scope.paxNames[i].seats[j].coach = '';
          $scope.paxNames[i].seats[j].seat = '';
        }
      }
    }

    for (var k = 0; k < $scope.seatAvail.length; k++) {
      if ($scope.seatAvail[k].train == tno) {
        $scope.seatAvail[k].seatsAssigned = false;
      }

      if ($scope.seatAvail[k].seatsAssigned == true) {
        blncheck = true;
      }

      $scope.enableApply = blncheck;

    }
    $scope.seat = [];
    // console.log("clearSeats =>", $scope.seat);
  };

  $scope.cancelSeats = function (tno) {
    globalData.setRefCode($scope.booking.tnr.tnr_ers_pnr);
    $location.path('/retrieve');
  };

  function setSeats_Local(data) {
    var sCoach = '';
    $scope.seat = [];
    //console.log("setSeats_Local");
    for (var i = 0; i < $scope.paxNames.length; i++) {
      for (var j = 0; j < $scope.paxNames[i].seats.length; j++) {
        if ($scope.paxNames[i].seats[j].train === data) {
          $scope.seat[i] = parseInt($scope.paxNames[i].seats[j].seat);
          if ($scope.paxNames[i].seats[j].coach) {
            sCoach = $scope.paxNames[i].seats[j].coach;
          }
        }
      }
    }
    $scope.activeCoach = sCoach;
    // console.log("setSeats_Local => ", $scope.seat);
  }

  $scope.getAvailSeat = function (item) {
    var mres1 = RESOURCES.HOST;
    var oSeats = {};

    oSeats.dep_station = item.origin;
    oSeats.arr_station = item.destination;
    oSeats.dep_date = convetToShortDate(item.departDate);
    oSeats.train_number = item.train;
    oSeats.s3_currency = $scope.currency;
    setSeats_Local(item.train);
    oSeats.class = item.ccos;
    openModal();

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    bookingService.getSeats(userid, rndString, nnce, oSeats, mres1)
      .then(function (res) {
        if (res.wsi_error) {
          if (res.wsi_error.err_ers_text) {
            $scope.seatsError = res.wsi_error.err_ers_text;
          } else {
            $scope.seatsError = res.wsi_error.err_wsi_text;
          }
          closeModal();
        } else {
          $scope.segment.response = res.SeatMapServiceOut;
          $scope.activeIndex = 0;
          if ($scope.activeCoach) {
            for (var i = 0; i < $scope.segment.response.coaches.length; i++) {
              if ($scope.activeCoach === $scope.segment.response.coaches[i].coach_num) {
                $scope.activeIndex = i;
                break;
              }
            }
          }
          //Set the current seat in the drop downs.
          $scope.seat.forEach(seat_num => {
            if ($scope.segment.response.coaches[$scope.activeIndex].seats){
              $scope.segment.response.coaches[$scope.activeIndex].seats.push(seat_num);
            }
            else {
              $scope.segment.response.coaches[$scope.activeIndex].seats = [];
              $scope.segment.response.coaches[$scope.activeIndex].seats.push(seat_num);
            }
          });

          $scope.setSelectedCoach($scope.segment.response.coaches[$scope.activeIndex]);
          $scope.seatsError = '';
          closeModal();
        }
      }, function (err) {
        closeModal();
      });
    // console.log("getAvail => ", $scope.seat);
  };

  $scope.saveChanges = function () {
    let paxSeats = [];
    let reference = $scope.booking.tnr.tnr_ers_pnr;

    $scope.paxNames.forEach(name => {
      name.seats.forEach(pSeat => {
        if (pSeat.seatChanged){
          paxSeats.push({'number' : pSeat.seat.toString(), 'itemRef' : pSeat.itemRef, 'carriage' : pSeat.coach.toString()});
        }
      });
    });
    if (paxSeats){
      openModal();
      let nnce = nonceGenerator();
      let userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
      let rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);
      let domain = RESOURCES.HOST;

      bookingService.updateSeats(userid, rndString, nnce, domain, paxSeats, reference, $scope.currency)
        .then(function(res) {
          if(res.wsi_error){
            if (res.wsi_error.err_ers_text) {
              $scope.seatsError = res.wsi_error.err_ers_text;
            } else {
              $scope.seatsError = res.wsi_error.err_wsi_text;
            }
          }
          else {
            if (res.updateSeatsServiceOut.reference){
              globalData.setRefCode(reference);
              $location.path('/retrieve');
            }
          }
          closeModal();

        }, function(err){
          closeModal();
        });
    }
    else {
      globalData.setRefCode($scope.booking.tnr.tnr_ers_pnr);
      $location.path('/retrieve');
    }
  };
  init();
});
