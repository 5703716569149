export enum Currency {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD'
}

export enum Location {
    ARIA = 'ARIA'
}

export declare type Environment = 'mock' | 'test' | 'live';


export enum PaymentOption {
  // "Eurostar" payment methods
  VOUCHER_ONLY = 'VOUCHER_ONLY',
  LOYALTY_POINTS_REDEMPTION = 'LOYALTY_POINTS_REDEMPTION',
  // Adyen payment methods
  CARD = 'card',
  PAYPAL = 'paypal',
  IDEAL = 'ideal',
  APPLEPAY = 'applepay',
  BANCONTACT = 'bcmc',
  SOFORT = 'directEbanking'
}

