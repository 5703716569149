
export const SHORT_CODE_TO_UIC_MAP = {
    "GBBSW": "7010060",
    "GBCOV": "7010300",
    "GBNMP": "7010690",
    "GBNUN": "7010770",
    "GBRUG": "7010870",
    "GBBHM": "7011270",
    "GBBHI": "7012150",
    "GBWVH": "7012180",
    "GBCRE": "7012430",
    "GBSTA": "7012680",
    "GBLTV": "7012910",
    "GBSOT": "7013140",
    "GBLBZ": "7013730",
    "GBMKC": "7013780",
    "GBHML": "7013910",
    "GBEUS": "7014440",
    "GBMYB": "7014750",
    "GBBDM": "7015120",
    "GBFLT": "7015320",
    "GBLEA": "7015340",
    "GBLUT": "7015360",
    "GBSPX": "7015400",
    "GBHPD": "7015430",
    "GBEBF": "7015440",
    "GBSAC": "7015480",
    "GBSTP": "7015550",
    "GBDBY": "7018230",
    "GBNOT": "7018260",
    "GBCOR": "7018470",
    "GBKET": "7018570",
    "GBLBO": "7018970",
    "GBEMD": "7019010",
    "GBWEL": "7019400",
    "GBLEI": "7019470",
    "GBCAR": "7021180",
    "GBLIV": "7022460",
    "GBRUN": "7022910",
    "GBWGN": "7023630",
    "GBWBQ": "7023840",
    "GBCTR": "7024120",
    "GBBNG": "7024710",
    "GBLAN": "7026850",
    "GBPRE": "7027530",
    "GBSPT": "7027710",
    "GBWML": "7027740",
    "GBMAC": "7028710",
    "GBMAN": "7029680",
    "GBDID": "7030300",
    "GBBCS": "7030480",
    "GBHWY": "7030540",
    "GBNBY": "7030740",
    "GBPAD": "7030870",
    "GBOXF": "7031150",
    "GBRDG": "7031490",
    "GBBPW": "7032300",
    "GBBRI": "7032310",
    "GBCPM": "7032670",
    "GBBTH": "7032710",
    "GBSWI": "7033330",
    "GBEXD": "7034100",
    "GBNTA": "7034260",
    "GBTAU": "7034710",
    "GBPLY": "7035800",
    "GBLTN": "7036450",
    "GBNWP": "7036740",
    "GBBGN": "7038090",
    "GBCDF": "7038990",
    "GBNTH": "7042160",
    "GBSWA": "7042220",
    "GBBAN": "7045020",
    "GBBMO": "7045150",
    "GBSOL": "7045270",
    "GBLMS": "7045970",
    "GBWRW": "7046000",
    "GBCNM": "7047310",
    "GBGCR": "7047600",
    "GBSTD": "7047710",
    "GBAFK": "7050040",
    "GBCBW": "7050070",
    "GBBFR": "7051120",
    "GBCHX": "7051430",
    "GBLBG": "7051480",
    "GBWAE": "7051580",
    "GBBTN": "7052680",
    "GBDUR": "7052820",
    "GBHRH": "7053090",
    "GBEBT": "7053590",
    "GBGTW": "7054160",
    "GBVIC": "7054260",
    "GBASI": "7054660",
    "GBFTN": "7055090",
    "GBBSK": "7055200",
    "GBFNB": "7055210",
    "GBHAV": "7055320",
    "GBPMS": "7055370",
    "GBPMH": "7055400",
    "GBWAT": "7055980",
    "GBGLD": "7056310",
    "GBLRD": "7056320",
    "GBPTR": "7056570",
    "GBSAL": "7056630",
    "GBAFS": "7056670",
    "GBWOK": "7056850",
    "GBBFN": "7056890",
    "GBAXM": "7057140",
    "GBGIL": "7057960",
    "GBYVJ": "7058380",
    "GBBMH": "7058760",
    "GBPOO": "7058830",
    "GBFRM": "7059000",
    "GBSOA": "7059220",
    "GBWIN": "7059240",
    "GBSOU": "7059320",
    "GBWRM": "7059590",
    "GBDCH": "7059610",
    "GBHIT": "7060860",
    "GBRYS": "7060900",
    "GBSVG": "7060920",
    "GBHUN": "7061080",
    "GBSNO": "7061100",
    "KGSXTEST": "7061210",
    "GBPBO": "7061330",
    "GBBIW": "7061700",
    "GBSDY": "7061720",
    "GBWGC": "7061830",
    "GBDON": "7064170",
    "GBGRA": "7064690",
    "GBNNG": "7064990",
    "GBRET": "7065110",
    "GBCHD": "7066150",
    "GBSHF": "7066910",
    "GBCHM": "7068360",
    "GBCOL": "7068610",
    "GBLST": "7069650",
    "GBCBG": "7070220",
    "GBDOW": "7070550",
    "GBELY": "7070680",
    "GBKLN": "7071150",
    "GBIPS": "7072170",
    "GBNRW": "7073090",
    "GBDIS": "7073480",
    "GBFST": "7074900",
    "GBNCL": "7077280",
    "GBDHM": "7077450",
    "GBALM": "7077870",
    "GBBWK": "7077910",
    "GBDAR": "7078770",
    "GBNTR": "7079960",
    "GBHUL": "7081260",
    "GBHGT": "7082130",
    "GBYRK": "7082630",
    "GBLDS": "7084870",
    "GBWKF": "7085910",
    "GBINV": "7086490",
    "ABERDEENTEST": "7089760",
    "GBEDB": "7093280",
    "GBGLC": "7098130",
    "DEBEC": "8008094",
    "DEFRH": "8011068",
    "DEBDY": "8015345",
    "DEKOH": "8015458",
    "NLAMA": "8400058",
    "NLRTA": "8400530",
    "NLSPH": "8400561",
    "CHGVA": "8501008",
    "FRMLV": "8711184",
    "FRPST": "8711300",
    "FRQYR": "8711800",
    "FRENC": "8714100",
    "FREAM": "8714210",
    "FREPL": "8714400",
    "FRXTD": "8714401",
    "FRRNT": "8714445",
    "FREAJ": "8714732",
    "FRRHE": "8717100",
    "FREAH": "8717192",
    "FRXCR": "8717400",
    "FRAEJ": "8718201",
    "FRAEK": "8718206",
    "FRBOR": "8718400",
    "FRADE": "8719203",
    "FRAEG": "8721202",
    "FRLLE": "8722326",
    "FRPNO": "8727100",
    "FRMLW": "8727149",
    "FRSTH": "8728107",
    "FRADJ": "8728600",
    "FRTJA": "8730082",
    "FRTJB": "8730086",
    "FRAVG": "8731896",
    "FRAIE": "8731901",
    "FRTGO": "8732409",
    "FRPSL": "8738400",
    "FRPMO": "8739100",
    "FRAET": "8739600",
    "FRURD": "8741101",
    "FRCFR": "8744400",
    "FRRNS": "8747100",
    "FRSBK": "8747300",
    "FRLAM": "8747310",
    "FRBES": "8747400",
    "FRUIP": "8747409",
    "FRRSP": "8747415",
    "FRMXN": "8747433",
    "FRLRT": "8747600",
    "FRXUY": "8747620",
    "FRVNE": "8747660",
    "FRACR": "8747840",
    "FRNTE": "8748100",
    "FRACL": "8748400",
    "FRLRH": "8748500",
    "FRAFK": "8754300",
    "FRLAB": "8754301",
    "FRPAZ": "8754700",
    "FRAFW": "8757100",
    "FRXSH": "8757124",
    "FRPIS": "8757500",
    "FRBOJ": "8758100",
    "FRXAC": "8758266",
    "FRANG": "8758300",
    "FRXLR": "8758405",
    "FRAGF": "8758600",
    "FRAGQ": "8759200",
    "FRBVE": "8759400",
    "FRXYT": "8761100",
    "FRXMW": "8761124",
    "FRXTB": "8767100",
    "FRLDE": "8767133",
    "FRAEE": "8767200",
    "FRXBY": "8767300",
    "FRACG": "8767320",
    "FRBIQ": "8767340",
    "FRAEF": "8767700",
    "FRPLY": "8768600",
    "FRPBE": "8768666",
    "FRSUF": "8768888",
    "FRXCC": "8769410",
    "FRABA": "8771304",
    "FRGWE": "8771313",
    "FRACE": "8771341",
    "FRABG": "8771800",
    "FRLPE": "8772202",
    "FRLPD": "8772319",
    "FRXCD": "8772500",
    "FRMLT": "8772570",
    "FRCFE": "8773400",
    "FRCMF": "8774100",
    "FRXAV": "8774164",
    "FRQMU": "8774172",
    "FRQAI": "8774176",
    "FRQBM": "8774179",
    "FRAGR": "8774200",
    "FRHPN": "8774232",
    "FRXBK": "8774300",
    "FRXTS": "8774564",
    "FRXEB": "8774567",
    "FRNCY": "8774600",
    "FRGNB": "8774700",
    "FRMSC": "8775100",
    "FRHZA": "8775365",
    "FRTLN": "8775500",
    "FRXRS": "8775544",
    "FRXHE": "8775562",
    "FRNIC": "8775605",
    "FRXMM": "8775640",
    "FRXMT": "8775648",
    "FRXSK": "8775752",
    "FRJCA": "8775762",
    "FRXAT": "8775767",
    "FRJDQ": "8776290",
    "FRVLA": "8776302",
    "FRXMK": "8776400",
    "FRAES": "8776500",
    "FRMPL": "8777300",
    "FRFNI": "8777500",
    "FRBZR": "8778100",
    "FRXNA": "8778110",
    "FRXAG": "8778127",
    "FRPGF": "8778400",
    "BEBMI": "8814001",
    "BEBRG": "8891009",
    "NLADS2": "84555555",
    "NLADS3": "84666666",
    "NLADS1": "84999999",
    "BEABS": "88999999",
    "BEABC": "8821006",
    "DEDTM": "8010053",
    "BELGG": "8841004"
};
