import React, { useEffect, useState } from 'react';
import { Checkout, CheckoutProvider, CheckoutRedirect, EurostarLegacyThemeProvider } from '@eurostar/ui-components';
import '@adyen/adyen-web/dist/adyen.css';
import { Currency, Environment, Location, PaymentOption } from '../../../util/checkout';

export type CheckoutComponentProps = {
  adyenClientKey: string,
  currency: string,
  amount: number,
  getPaymentMethods: (payload: any) => any,
  initiatePayment: (payload: any) => any,
  onRedirectData: (redirectData?: any | null) => Promise<void>,
  adyenEnvironment: string
}

const CheckoutComponent: React.FC<CheckoutComponentProps> = (props: CheckoutComponentProps) => {
  const [isExternalRedirectFlow, setIsExternalRedirectFlow] = useState(false);

  useEffect(() => {
    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage);
  });

  const handleMessage = (message: MessageEvent) => {
    if (message.data?.type === 'adyen_redirect_data') {
      props.onRedirectData(message.data.message);
    }
  };

    const initiatePayment = (payload: any) => {
        setIsExternalRedirectFlow(payload.paymentMethod.type === PaymentOption.SOFORT);

        return props.initiatePayment(payload);
    }

  return (
    <EurostarLegacyThemeProvider>
      <div>
        <CheckoutProvider>
          <Checkout
            getPaymentMethods={props.getPaymentMethods}
            initiatePayment={initiatePayment}
            market="UK"
            hasHotel={false}
            language="EN"
            location={Location.ARIA}
            clientKey={props.adyenClientKey}
            amount={props.amount}
            currency={props.currency as Currency}
            environment={props.adyenEnvironment as Environment}
            showPayButton={true}
          />
          <CheckoutRedirect isExternalRedirectFlow={isExternalRedirectFlow} />
        </CheckoutProvider>
      </div>
    </EurostarLegacyThemeProvider>
  );
};

export default CheckoutComponent;
