import app from '../app';
import { closeModal, convertDate, nonceGenerator, openModal, sortFares } from '../utils';

app.controller('OutBoundController', function ($scope, $rootScope, $route, $location, RESOURCES, availabilityService, globalData, md5, $translate) {
  init();

  function init() {

    $scope.originList = globalData.getStaionList(globalData.getbookingType());
    $scope.destinationList = globalData.getStaionList(globalData.getbookingType());
    $scope.outBoundError = '';
    $scope.trains = {};

    $scope.departDateValue = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()); //new Date();
    $scope.departTimeValue = new Date('00:00');

    $scope.currency = globalData.getCurrency();
    $scope.currencySign = globalData.getCurrencySign($scope.currency);

    $scope.noTrains = false;
    openModal(); //Open the busy indicator.

    $scope.enqData = globalData.getBookingEnquiryData();
    $scope.originName = $scope.enqData.origin;
    $scope.destinationName = $scope.enqData.destination;
    $scope.departDateValue = $scope.enqData.dDateFull;

    $scope.today = new Date(); //current date to stop date components displaying previous dates
    $scope.maxDate = new Date();
    $scope.maxDate = $scope.maxDate.setDate((new Date()).getDate() + $scope.enqData.bookingHorizon);
    $scope.isER = globalData.getbookingType() == "R";

    if ((globalData.getbookingType() == 'P') || (globalData.getbookingType() == 'T') || (globalData.getbookingType() == 'Y')) {
      $scope.today.setDate($scope.today.getDate() + 1);
    } else if (globalData.getbookingType() == 'R') {
        $scope.today.setDate($scope.today.getDate() + 3);
        $scope.maxDate = new Date();
        $scope.maxDate = $scope.maxDate.setDate($scope.today.getDate() + 220);
    }

    if ($scope.enqData.paxNum == 1) {
      $translate('Text172').then(function (txtMsg) {
        $scope.paxText = txtMsg;
      });
    } else {
      $translate('Text119').then(function (txtMsg) {
        $scope.paxText = txtMsg;
      });
    }

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    var mres1 = RESOURCES.HOST;
    //availabilityService.getAvailability($scope.enqData.origin, $scope.enqData.destination, $scope.enqData.dDate, $scope.enqData.dTime)
    availabilityService.getFareAvailability(userid, rndString, nnce, $scope.enqData.booking_type, $scope.enqData.paxNum, $scope.enqData.origin, $scope.enqData.destination, $scope.enqData.dDate, mres1, $scope.currency)
      .then(function (res) {
        //Check if there is an error.
        if (res.wsi_error) {
          $scope.trains = {};
          if (res.wsi_error.err_ers_text) {
            $scope.outBoundError = res.wsi_error.err_ers_text;
          } else {
            $scope.outBoundError = res.wsi_error.err_wsi_text;
          }
          closeModal();
          //console.log ("outbound enquiry error:- " + $scope.outBoundError);
        } else {
          //if no error then process the response.
          $scope.trains = res;
          if ($scope.trains.FareAvailabilityServiceOut.solutions_out) {
            $scope.noTrains = false;
            filterDutyFare();
            filterAMSBXS();
            filterThCarrier();
            sortFares($scope.trains);
          } else {
            $scope.noTrains = true;
          }
          closeModal(); //Close the busy indicator.
        }
      }, function (err) {

        $translate('Text236').then(function (txtMsg) {
          $scope.outBoundError = txtMsg;
        });
        closeModal(); //Close the busy indicator.
      });
  }

  $scope.search = function () {
    $scope.enqData.dDate = convertDate($scope.departDateValue);
    $scope.enqData.dDateFull = $scope.departDateValue;
    globalData.setBookingEnquiryData($scope.enqData);
    $route.reload();
  };

  $scope.readKey = function (event) {
    var x = event.which || event.keyCode;
    //block all keypress except tab.
    if (x != 9) {
      event.preventDefault();
    }
  };

$scope.getMilesNeeded = function (oTrain, cCos) {
    const oBookingSale = [];

    for (var iCount = 0; iCount <= oTrain.legs.length - 1; iCount++) {
      var oSegments = {};
      oSegments.s3_items = [];

      for (var jCount = 0; jCount <= oTrain.fares.length - 1; jCount++) {
        if (cCos == oTrain.fares[jCount].type) {
          oSegments.fareType = oTrain.fares[jCount].type;

          $scope.trains.FareAvailabilityServiceOut.s3_passengers.forEach(passenger => {
            oTrain.fares[jCount].code.forEach(tCode => {
              let item = { passengerId : '', tariffCode : '' };
              item.passengerId = passenger.id;
              item.tariffCode = tCode.tariffCode;
              oSegments.s3_items.push(item);
            });
          });
        }
      }

      oBookingSale.push(oSegments);
    }

  const stdFareTypes = ['Standard', 'Eurostar Standard'];
  let usedMiles = 0;

    oBookingSale.forEach(seg => {
      usedMiles += seg.s3_items.length * ((stdFareTypes.includes(seg.fareType)) ? 1 : 2);
    });

    return `${usedMiles} miles(s)`;
}

  $scope.goNext = function (oTrain, cCos) {
    var oBookingSale = globalData.getBookingSegments();
    var bReq = globalData.getBookingEnquiryData();
    bReq.fareType = cCos;
    oBookingSale = [];

    globalData.setBookingSegments(oBookingSale); //reset the object.
    globalData.setBookingEnquiryData(bReq);
    for (var iCount = 0; iCount <= oTrain.legs.length - 1; iCount++) {
      var oSegments = {};
      oSegments.origin = oTrain.legs[iCount].dep_station;
      oSegments.originFullName = globalData.getStationName(oTrain.legs[iCount].dep_station); //oTrain.legs[iCount].dep_station_name;
      oSegments.destination = oTrain.legs[iCount].arr_station;
      oSegments.destinationFullName = globalData.getStationName(oTrain.legs[iCount].arr_station); //oTrain.legs[iCount].arr_station_name;

      oSegments.carrier = oTrain.legs[iCount].carrier;
      oSegments.dep_date = $scope.enqData.dDate;
      oSegments.dep_date_full = $scope.enqData.dDateFull;
      oSegments.dep_time = oTrain.legs[iCount].dep_time;
      oSegments.dep_time_full = oTrain.legs[iCount].dep_time; // use filter while displaying full date
      oSegments.arr_time_full = oTrain.legs[iCount].arr_time;
      oSegments.JourneyTime = oTrain.legs[iCount].duration;
      oSegments.train_number = oTrain.legs[iCount].train_number;
      oSegments.equipment_code = oTrain.legs[iCount].equipment_code;
      oSegments.reservation = oTrain.legs[iCount].reservation;

      if ($scope.enqData.origin === 'BEABS' || $scope.enqData.destination === 'BEABS') {
        oSegments.isABS = true;
      } else {
        oSegments.isABS = false;
      }

      oSegments.legType = 'out';
      oSegments.s3_direction = 'outbound';
      oSegments.s3_service_identifier =  oTrain.legs[iCount].s3_service_identifier;
      oSegments.s3_items = [];

      for (var jCount = 0; jCount <= oTrain.fares.length - 1; jCount++) {
        if (cCos == oTrain.fares[jCount].type) {
          oSegments.ccos = oTrain.fares[jCount].ccos[iCount];
          oSegments.fare = (iCount == 0) ? oTrain.fares[jCount].price : 0;
          oSegments.fareType = oTrain.fares[jCount].type;
          oSegments.paxType = oTrain.fares[jCount].paxtype;
          oSegments.fbc = oTrain.fares[jCount].code[0].tariffCode;

          $scope.trains.FareAvailabilityServiceOut.s3_passengers.forEach(passenger => {
            oTrain.fares[jCount].code.forEach(tCode => {
              let item = { passengerId : '', tariffCode : '' };
              item.passengerId = passenger.id;
              item.tariffCode = tCode.tariffCode;
              oSegments.s3_items.push(item);
            });
          });

          // oSegments.s3_items = $scope.trains.FareAvailabilityServiceOut.s3_passengers.map(passenger => {
          //     let item = {passengerId : "", tariffCode : ""};
          //     item.passengerId = passenger.id;
          //     item.tariffCodes = oTrain.fares[jCount].code;
          //     return item;
          //   });
        }
      }
      // console.log("oSegments =", oSegments);
      oBookingSale.push(oSegments);
    }

    const stdFareTypes = ['Standard', 'Eurostar Standard'];
    let usedMiles = 0;

    oBookingSale.forEach(seg => {
      usedMiles += seg.s3_items.length * ((stdFareTypes.includes(seg.fareType)) ? 1 : 2);
    });

    let tsvRemaining = 0;

    if (typeof($rootScope.loggedInUser.ARIA_User.TSV_Balance) !== "undefined") {
        tsvRemaining = parseInt($rootScope.loggedInUser.ARIA_User.TSV_Balance);
    }

    if (globalData.getbookingType() === "R" && tsvRemaining < usedMiles) {
        $scope.notEnoughMiles = true;
        $translate('Text407').then(function (txtMsg) {
            $scope.notEnoughMilesErrMsg = txtMsg; //"There are insufficient vouchers to make this booking.";
        });

        return;
    }

    globalData.setBookingSegments(oBookingSale);

    if ($scope.enqData.isReturnBooking === true) {
      $location.path('/return');
    } else {
      $location.path('/bookingSummary');
    }
  };

  function filterAMSBXS() {
    var i = 0;
    var dataFound = false;
    while (i < $scope.trains.FareAvailabilityServiceOut.solutions_out.length) {
      dataFound = false;
      if (($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].dep_station.toUpperCase() === 'NLAMA' && $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].arr_station.toUpperCase() === 'BEBMI') ||
        ($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].dep_station.toUpperCase() === 'BEBMI' && $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].arr_station.toUpperCase() === 'NLAMA')) {
        if ($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'SB' ||
          $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'NS' ||
          $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'TH') {
          dataFound = true;
        }
      }
      if (dataFound === true) {
        $scope.trains.FareAvailabilityServiceOut.solutions_out.splice(i, 1);
      } else {
        i = i + 1;
      }
    }
    if ($scope.trains.FareAvailabilityServiceOut.solutions_out.length === 0) {
      $scope.noTrains = true;
    }
  }

  function filterThCarrier() {
    var i = 0;
    var dataFound = false;

    while (i < $scope.trains.FareAvailabilityServiceOut.solutions_out.length) {
      dataFound = false;
      if ($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'TH') {
        dataFound = true;
      }
      if (dataFound === true) {
        $scope.trains.FareAvailabilityServiceOut.solutions_out.splice(i, 1);
      } else {
        i = i + 1;
      }
    }
    if ($scope.trains.FareAvailabilityServiceOut.solutions_out.length === 0) {
      $scope.noTrains = true;
    }
  }

  function filterDutyFare() {
    if (globalData.getbookingType() == 'D') {
      for (var i = 0; i < $scope.trains.FareAvailabilityServiceOut.solutions_out.length; i++) {
        if (parseInt($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].dep_time) > parseInt('0800')
          && parseInt($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].arr_time) < parseInt('2000')) {
	    
          let fares = $scope.trains.FareAvailabilityServiceOut.solutions_out[i].fares.filter(fare =>
            (fare.type.toLowerCase() === 'eurostar standard' || fare.type.toLowerCase() === 'eurostar plus' || fare.type.toLowerCase() === 'standard' || fare.type.toLowerCase() === 'standard premier')
          );
	    
          $scope.trains.FareAvailabilityServiceOut.solutions_out[i].fares = fares;
        }
      }
    }
  }

  $scope.incDate = function (data) {
    if (data == -1) {
      globalData.decEnquiryDate(false, $scope.today);
    } else {
      globalData.incEnquiryDate(false, $scope.maxDate);
    }
    $scope.departDateValue = $scope.enqData.dDateFull;
    $scope.search();
  };
});
