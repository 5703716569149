import app from '../app';
import { closeModal, convertToDOB, nonceGenerator, openModal, s3_ProcessPaxInfo, formatQuarterEndDate } from '../utils';

app.controller('SummaryController', function ($scope, $rootScope, $location, RESOURCES, COUNTRIES, globalData, bookingService, eTapServices, md5, $translate) {
  init();

  function init() {
    $scope.countries = COUNTRIES;
    $scope.bkgInProcess = false;
    $scope.tcChecked = false;
    $scope.noNames = true;
    $scope.enqData = globalData.getBookingEnquiryData();

    const languageCodes = {
      [$translate.instant('Text279')]: 'EN',
      [$translate.instant('Text280')]: 'FR',
      [$translate.instant('Text281')]: 'NL',
    };

    $scope.selectedLanguageCode = {
      code: Object.values(languageCodes)
        .find(code => code === $translate.use().toUpperCase()) || "EN",
    };

    $scope.languages = Object.entries(languageCodes).map(([key, value]) => {
      return {key, value};
    });

    $scope.bkgSegments = globalData.getBookingSegments();
    $scope.paxNames = [];
    $scope.showInfant = [];
    $scope.mealFlag = showMeal($scope.bkgSegments);

    $scope.currency = globalData.getCurrency();
    $scope.currencySign = globalData.getCurrencySign($scope.currency);
    $scope.pnr = '';
    $scope.etap_Ticket_type = '';
    $scope.passbookFlag = false;
    $scope.pdfFlag = false;
    $scope.eTapError = false;
    $scope.eTapErrorMsg = '';
    $scope.addEmailOk = false;
    $scope.addPhoneOk = false;
    $scope.showDOB = false;
    $scope.tflBkg = false;
    $scope.isER = globalData.getbookingType() === 'R';
    $scope.milesUsed = 0;
    $scope.enquiryError = false;
    $scope.enquiryErrMsg = '';

    const bookingSegments = $scope.bkgSegments;
    const stdFareTypes = ['Standard', 'Eurostar Standard'];

    for (const seg of bookingSegments) {
      $scope.milesUsed += seg.s3_items.length * ((stdFareTypes.includes(seg.fareType)) ? 1 : 2);
    }


    if (globalData.getbookingType() === 'T') {
      $scope.tflBkg = true;
    } else {
      $scope.tflBkg = false;
    }
    //ShowEtap ONLY FOR DUTY Bookings.

    if (globalData.getbookingType() === 'F') {
      $scope.isFip = true;
      $scope.fipPassengers = [];

      // add self
      const firstPax = $rootScope.loggedInUser.ARIA_User.AccountName.split(' ');
      $scope.fipPassengers.push({
	first_name: firstPax[0],
	last_name: firstPax[firstPax.length - 1]
      });

      // add dependants
      if ($rootScope.loggedInUser.ARIA_User.dependants) {
	$scope.fipPassengers = $scope.fipPassengers.concat($rootScope.loggedInUser.ARIA_User.dependants);
      }

      // adds spouse/partner
      if (($rootScope.loggedInUser.ARIA_User.Spouse_Partner_First_Name || '').trim() !== '' && ($rootScope.loggedInUser.ARIA_User.Spouse_Partner_Last_Name || '').trim() !== '') {
	$scope.fipPassengers.push({
	  first_name: $rootScope.loggedInUser.ARIA_User.Spouse_Partner_First_Name,
	  last_name: $rootScope.loggedInUser.ARIA_User.Spouse_Partner_Last_Name
	});
      }
    }
    
    if (globalData.getbookingType() === 'D') {
      $scope.showEtap = true;
      $scope.showEtapTicketing = true;
    } else if ((globalData.getbookingType() === 'P') || (globalData.getbookingType() === 'F') || (globalData.getbookingType() === 'M') ||
      (globalData.getbookingType() === 'I') || (globalData.getbookingType() === 'T') || (globalData.getbookingType() === 'Y')) {

      $scope.showEtap = true;
      $scope.showEtapTicketing = true;
    } else {
      $scope.showEtap = true;
      $scope.showEtapTicketing = true;
    }

    if ((globalData.getbookingType() == 'B')) {
      if ($rootScope.loggedInUser.ARIA_User.dependants) {
        $scope.dependants = $rootScope.loggedInUser.ARIA_User.dependants;
      }
      if ($scope.dependants) {
        if ($scope.dependants.length > 0) {
          $scope.showDep = true;
        } else {
          $scope.showDep = false;
        }
      } else {
        $scope.showDep = false;
      }
    } else {
      $scope.showDep = false;
    }

    if (globalData.getbookingType() == 'D') {
      $translate('Text245').then(function (txtMsg) {
        $scope.btnCaption = txtMsg;
      });
      $translate('Text328').then(function (txtMsg) {
        $scope.btnCaption1 = txtMsg;
      });
      $translate('Text329').then(function (txtMsg) {
        $scope.btnCaption2 = txtMsg;
      });
    }

    if (globalData.getbookingType() == 'P') {
      $translate('Text247').then(function (txtMsg) {
        $scope.fareConditions = txtMsg;
      });
    } else if (globalData.getbookingType() == 'R') {
      $translate('Text408').then(function (txtMsg) {
        $scope.fareConditions = `${txtMsg} ${formatQuarterEndDate(new Date())})`;
      });
    } else if (globalData.getbookingType() == 'F') {
      $translate('Text248').then(function (txtMsg) {
        $scope.fareConditions = txtMsg;
      });
    } else if (globalData.getbookingType() == 'D') {
      $translate('Text249').then(function (txtMsg) {
        $scope.fareConditions = txtMsg;
      });
    } else if (globalData.getbookingType() == 'B') {
      $translate('Text249_1').then(function (txtMsg) {
        $scope.fareConditions = txtMsg;
      });
    } else if (globalData.getbookingType() == 'T') {
      $translate('Text249_2').then(function (txtMsg) {
        $scope.fareConditions = txtMsg;
      });
    } else if (globalData.getbookingType() == 'Y') {
      $translate('Text247').then(function (txtMsg) {
        $scope.fareConditions = txtMsg;
      });
    }

    if ($scope.mealFlag == true) {
      var mres1 = RESOURCES.HOST;

      var nnce = nonceGenerator();
      var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
      var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

      bookingService.getSSR(userid, rndString, nnce, mres1)
        .then(function (res) {
          if (res.TfaxListServiceOut) {
            if (res.TfaxListServiceOut.tfaxs) {
              $scope.mealList = res.TfaxListServiceOut.tfaxs;
            }
          }
        }, function (err) {
          $scope.mealList = [];
        });
    }

    //Data for requesting seats data
    $scope.seatAvail = [];
    //ng-model for the seat selection per segment.
    $scope.seat = [];
    $scope.enableApply = false;
    $scope.totalFare = 0;

    for (var sno = 0; sno < $scope.bkgSegments.length; sno++) {
      if ($scope.bkgSegments[sno].carrier == 'ES') {
        var obj = {};
        obj.originFullName = $scope.bkgSegments[sno].originFullName;
        obj.origin = $scope.bkgSegments[sno].origin;
        obj.destinationFullName = $scope.bkgSegments[sno].destinationFullName;
        obj.destination = $scope.bkgSegments[sno].destination;
        obj.departDate = $scope.bkgSegments[sno].dep_date;
        obj.departDateFull = $scope.bkgSegments[sno].dep_date_full;
        obj.train = $scope.bkgSegments[sno].train_number;
        obj.ccos = $scope.bkgSegments[sno].ccos.substr(0, 1);
        obj.equipment_code = $scope.bkgSegments[sno].equipment_code;
        obj.seatsAssigned = false;

        $scope.seatAvail.push(obj);
      }
      $scope.totalFare = $scope.totalFare + ($scope.bkgSegments[sno].fare);
    }

    //total fare displayed on the screen.
    $scope.totalFare = ($scope.totalFare * $scope.enqData.paxNum);

    if (globalData.getbookingType() != 'D') {
        if ($scope.totalFare == 0) {
          $translate('Text411').then(function (txtMsg) {
            $scope.btnCaption = txtMsg;
          });
        } else {
          $translate('Text246').then(function (txtMsg) {
            $scope.btnCaption = txtMsg;
          });
        }
    }

    $scope.segment = $scope.seatAvail[0];
    // Initialise PaxNames[] with seats
    Init_Pax();
  }
  $scope.setSurname = function (paxName) {
    for (var i = 0; i < $scope.dependants.length; i++) {
      if (paxName.firstName === $scope.dependants[i].first_name) {
        paxName.surName = $scope.dependants[i].last_name;
        break;
      }
    }
  };

  $scope.setSurnameFip = function (paxName) {
    for (var i = 0; i < $scope.fipPassengers.length; i++) {
      if (paxName.firstName === $scope.fipPassengers[i].first_name) {
        paxName.surName = $scope.fipPassengers[i].last_name;
        break;
      }
    }
  };

  $scope.depRadioClick = function (data) {
    if (data.dependant === true) {
      $('#depModal').modal({
        backdrop: 'static'
      });
      data.firstName = '';
      data.surName = '';
    }
  };

  function checkSegOrigin(segs) {
    var result = false;
    for (var sno = 0; sno < segs.length; sno++) {
      //console.log(segs[sno].origin);
      if (segs[sno].origin.substring(0, 2) == 'FR') {
        if (segs[sno].origin == 'FRPNO') {
          result = false;
        } else {
          result = true;
          break;
        }
      }
    }
    return result;
  }

  function Init_Pax() {
    var pNum = parseInt($scope.enqData.paxNum);
    if (IsPALSBeyond() === true) {
      $scope.showDOB = true;
    } else {
      $scope.showDOB = false;
    }

    for (var iCount = 0; iCount < pNum; iCount++) {
      if ((globalData.getbookingType() === 'T') && iCount === 0) {
        var firstPax = $rootScope.loggedInUser.ARIA_User.AccountName.split(' ');
        $scope.paxNames[iCount] = {
          Id: iCount + 1,
          firstName: firstPax[0],
          surName: firstPax[firstPax.length - 1],
          paxtype: '',
          seats: [],
          etap_id: '',
          etap_email: '',
          etap_phone: '',
          etap_phone_dial: '',
          etap_cin: '',
          etap_Infant_Fname: '',
          etap_Infant_Sname: '',
          dependant: false,
          dob: ''
        };
        $scope.showInfant[iCount] = false;
      } else {
        $scope.paxNames[iCount] = {
          Id: iCount + 1,
          firstName: '',
          surName: '',
          paxtype: '',
          seats: [],
          etap_id: '',
          etap_email: '',
          etap_phone: '',
          etap_phone_dial: '',
          etap_cin: '',
          etap_Infant_Fname: '',
          etap_Infant_Sname: '',
          dependant: false,
          dob: ''
        };
        $scope.showInfant[iCount] = false;
      }

    }
    //clear pax seats.
    for (var i = 0; i < $scope.paxNames.length; i++) {
      $scope.paxNames[i].seats = [];
    }
    for (i = 0; i < $scope.seatAvail.length; i++) {
      for (var j = 0; j < $scope.paxNames.length; j++) {
        var obSeat = {
          train: $scope.seatAvail[i].train,
          seat: '',
          coach: ''
        };
        $scope.paxNames[j].seats.push(obSeat);
      }
    }
  }

  $scope.toggleInfant = function (index) {
    if ($scope.showInfant[index] === false) {
      $scope.showInfant[index] = true;
    } else {
      $scope.showInfant[index] = false;
    }
  };

  $scope.readKey = function (event) {
    var x = event.which || event.keyCode;
    //block all keypress except tab.
    if (x != 9) {
      event.preventDefault();
    }
  };

  $scope.checkEmail = function (pax) {
    var result;
    if (pax.etap_email) {
      result = validateEmail(pax.etap_email);
      if (result === true) {
        pax.eClass1 = '';
        pax.eClass2 = '';
        pax.eOk = true;
      } else {
        pax.eClass1 = 'form-group has-error has-feedback';
        pax.eClass2 = 'glyphicon glyphicon-remove form-control-feedback';
        pax.eOk = false;
      }
    } else {
      pax.eClass1 = '';
      pax.eClass2 = '';
      pax.eOk = false;
    }
    $scope.pdfFlag = true;
    for (var i = 0; i < $scope.paxNames.length; i++) {
      if ((($scope.paxNames[i].etap_email === '') && ($scope.paxNames[i].etap_phone === '')) || (($scope.paxNames[i].etap_email === '') && ($scope.paxNames[i].etap_phone != ''))) {
        $scope.pdfFlag = false;
        break;
      }
    }
    $scope.passbookFlag = true;
    for (var i = 0; i < $scope.paxNames.length; i++) {
      if (($scope.paxNames[i].etap_email === '')) {
        $scope.passbookFlag = false;
        break;
      }
    }
  };

  $scope.checkAddEmail = function (email) {
    var result;
    if (email) {
      result = validateEmail(email);
      if (result === true) {
        $scope.addEmailClass1 = '';
        $scope.addEmailClass2 = '';
        $scope.addEmailOk = true;
      } else {
        $scope.addEmailClass1 = 'form-group has-error has-feedback';
        $scope.addEmailClass2 = 'glyphicon glyphicon-remove form-control-feedback';
        $scope.addEmailOk = false;
      }
    } else {
      $scope.addEmailClass1 = '';
      $scope.addEmailClass2 = '';
      $scope.addEmailOk = true;
    }
  };

  $scope.eTapEmailOk = function () {
    var result = true;
    for (var i = 0; i < $scope.paxNames.length; i++) {
      if (paxNames[i].eOk) {
        if (paxNames[i].eOk === false) {
          result = false;
          break;
        }
      } else {
        result = false;
        break;
      }
    }
    return result;
  };

  $scope.eTapPhoneOk = function () {
    var result = true;
    for (var i = 0; i < $scope.paxNames.length; i++) {
      if (paxNames[i].pOk) {
        if (paxNames[i].pOk === false) {
          result = false;
          break;
        }
      } else {
        result = false;
        break;
      }
    }
    return result;
  };

  function validateEmail(email) {
    var result = false;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }

  $scope.checkPhone = function (pax) {
    var result = false;
    if (pax.etap_phone) {
      result = validatePhone(pax.etap_phone);
      if (result === true) {
        pax.pClass1 = '';
        pax.pClass2 = '';
        pax.pOk = true;
      } else {
        pax.pClass1 = 'form-group has-error has-feedback';
        pax.pClass2 = 'glyphicon glyphicon-remove form-control-feedback';
        pax.pOk = false;
      }
    } else {
      pax.pClass1 = '';
      pax.pClass2 = '';
      pax.pOk = false;
    }

    $scope.pdfFlag = true;
    for (var i = 0; i < $scope.paxNames.length; i++) {
      if ((($scope.paxNames[i].etap_email === '') && ($scope.paxNames[i].etap_phone === '')) || (($scope.paxNames[i].etap_email === '') && ($scope.paxNames[i].etap_phone != ''))) {
        $scope.pdfFlag = false;
        break;
      }
    }

    $scope.passbookFlag = true;
    for (var i = 0; i < $scope.paxNames.length; i++) {
      if (($scope.paxNames[i].etap_email === '')) {
        $scope.passbookFlag = false;
        break;
      }
    }
  };

  $scope.checkAddPhone = function (phone) {
    var result = false;
    if (phone) {
      result = validatePhone(phone);
      if (result === true) {
        addPhoneClass1 = '';
        addPhoneClass2 = '';
        $scope.addPhoneOk = true;
      } else {
        addPhoneClass1 = 'form-group has-error has-feedback';
        addPhoneClass2 = 'glyphicon glyphicon-remove form-control-feedback';
        $scope.addPhoneOk = false;
      }
    } else {
      addPhoneClass1 = '';
      addPhoneClass2 = '';
      addPhoneOk = true;
    }
  };

  function validatePhone(phone) {
    var result;
    if ((phone.length >= 5) && (phone.length <= 20)) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }

  $scope.filterInSegments = function (item) {
    var result = false;
    if (item.legType == 'return') {
      result = true;
    }
    return result;
  };

  $scope.filterOutSegments = function (item) {
    var result = false;
    if (item.legType == 'out') {
      result = true;
    }
    return result;
  };

  function IsPALSBeyond() {
    var result = false;
    const stationList = ['NLAMA', 'NLRTA'];
    var bkgType = globalData.getbookingType();
    if ($rootScope.loggedInUser.ARIA_User.Staff_Voucher === 'true') {
      if (bkgType === 'P') {
        for (var i = 0; i < $scope.bkgSegments.length; i++) {
          if ($scope.bkgSegments[i].carrier === 'SN' || $scope.bkgSegments[i].carrier === 'TH' ) {
            result = true;
            break;
          }
          else if (($scope.bkgSegments[i].carrier === 'ES') &&
          (stationList.includes($scope.bkgSegments[i].origin) || stationList.includes($scope.bkgSegments[i].destination))) {
            result = true;
            break;
          }
        }
      }
    }

    return result;
  }

  function showMeal(segs) {
    var result = false;
    for (var iCount = 0; iCount < segs.length; iCount++) {
      if (segs[iCount].fareType == 'STANDARD PREMIER' || segs[iCount].fareType == 'BUSINESS PREMIER') {
        result = true;
        break;
      }
    }
    return result;
  }

  $scope.makepayment = function () {
    $location.path('/payment');
  };

  function checkPaxNames() {
    var result = false;
    var i = 0;
    var j = 0;
    $scope.nameError = false;
    $scope.nameErrorMsg = '';
    //Check Pax names are non empty.
    for (i = 0; i < $scope.paxNames.length; i++) {
      if (!$scope.paxNames[i].firstName || $scope.paxNames[i].firstName === '' || !$scope.paxNames[i].surName || $scope.paxNames[i].surName === '') {
        $scope.nameError = true;


        if (!$scope.paxNames[i].firstName || $scope.paxNames[i].firstName === '') {
            $translate('Text191').then(function (txtMsg) {
              $scope.nameErrorMsg = txtMsg;
            });
        } else if (!$scope.paxNames[i].surName || $scope.paxNames[i].surName === '') {
            $translate('Text192').then(function (txtMsg) {
              $scope.nameErrorMsg = txtMsg;
            });
        }

        $scope.bkgInProcess = false;
        result = true;
        break;
      } else {
        if ($scope.showDOB === true) {
          if ($scope.paxNames[i].dob === '') {
            $scope.nameError = true;
            $translate('Text377').then(function (txtMsg) {
              $scope.nameErrorMsg = txtMsg;
            });
            $scope.bkgInProcess = false;
            result = true;
            break;
          }
        }
      }
    }
    i = 0;
    j = 0;
    //check for duplicate names
    if ($scope.paxNames.length > 1) {
      for (i = 0; i < $scope.paxNames.length; i++) {
        for (var j = 0; j < $scope.paxNames.length; j++) {
          if (i == j) {
            continue;
          } else {
            if (($scope.paxNames[i].firstName == $scope.paxNames[j].firstName) &&
              ($scope.paxNames[i].surName == $scope.paxNames[j].surName)) {
              $scope.nameError = true;
              $translate('Text251').then(function (txtMsg) {
                $scope.nameErrorMsg = txtMsg;
              });
              $scope.bkgInProcess = false;
              result = true;
              break;
            }
          }
        }
        if (result == true) {
          break;
        }
      }
    }
    return result;
  }

  $scope.showSeats = function () {
    var dataCheck = checkPaxNames();
    if (dataCheck == false) {
      $scope.nameError = false;
      $scope.nameErrorMsg = '';

      $('#specificSeats').modal({
        backdrop: 'static'
      });
      $scope.getAvailSeat($scope.segment);
    } else {
      $scope.nameError = true;
    }
  };

  $scope.setSelectedCoach = function (item) {
    $scope.mapImage = $scope.segment.equipment_code + '_' + item.coach_num + '.png';
    $scope.selectedCoach = item;
  };

  $scope.updateSeats = function (tno, cno, sno, index) {
    var i = 0;
    var j = 0;
    $scope.dupSeatError = '';

    //Check  if seat already assigned to other pax;
    for (i = 0; i < $scope.paxNames.length; i++) {
      for (j = 0; j < $scope.paxNames[i].seats.length; j++) {
        if ($scope.paxNames[i].seats[j].train == tno) {
          if ($scope.paxNames[i].seats[j].seat == sno[index]) {

            $translate('Text253').then(function (txtMsg) {
              $scope.dupSeatError = txtMsg;
            });
            break;
          }
        }
      }
      if ($scope.dupSeatError) {
        break;
      }
    }
    if ($scope.dupSeatError == '') {
      for (i = 0; i < $scope.paxNames.length; i++) {
        if (i == index) {
          for (j = 0; j < $scope.paxNames[i].seats.length; j++) {
            if ($scope.paxNames[i].seats[j].train == tno) {
              $scope.paxNames[i].seats[j].train = tno;
              $scope.paxNames[i].seats[j].coach = cno;
              $scope.paxNames[i].seats[j].seat = sno[index];
            }
          }
        }
      }

      var check = false;
      //Check of all pax have Seats for the segment.
      for (i = 0; i < $scope.paxNames.length; i++) {
        for (j = 0; j < $scope.paxNames[i].seats.length; j++) {
          if ($scope.paxNames[i].seats[j].train == tno) {
            if (($scope.paxNames[i].seats[j].coach == '' || $scope.paxNames[i].seats[j].coach == undefined) && ($scope.paxNames[i].seats[j].seat == '' || $scope.paxNames[i].seats[j].seat == undefined)) {
              check = true;
              break; //for j lo
            }
          }
        }
        if (check == true) {
          break;
        }
      }

      if (check == false) {
        for (var k = 0; k < $scope.seatAvail.length; k++) {
          if ($scope.seatAvail[k].train == tno) {
            $scope.seatAvail[k].seatsAssigned = true;
            $scope.enableApply = true;
            break;
          }
        }
      }
    } else {
      $scope.seat[index] = '';
    }
  };

  $scope.clearSeats = function (tno) {
    var blncheck = false;
    //console.log("clearSeats");
    for (var i = 0; i < $scope.paxNames.length; i++) {
      for (var j = 0; j < $scope.paxNames[i].seats.length; j++) {
        if ($scope.paxNames[i].seats[j].train == tno) {
          $scope.paxNames[i].seats[j].coach = '';
          $scope.paxNames[i].seats[j].seat = '';
        }
      }
    }

    for (var k = 0; k < $scope.seatAvail.length; k++) {
      if ($scope.seatAvail[k].train == tno) {
        $scope.seatAvail[k].seatsAssigned = false;
      }

      if ($scope.seatAvail[k].seatsAssigned == true) {
        blncheck = true;
      }

      $scope.enableApply = blncheck;

    }
    $scope.seat = [];
  };

  $scope.cancelSeats = function (tno) {
    var i = 0;
    var j = 0;
    for (i = 0; i < $scope.paxNames.length; i++) {
      for (var j = 0; j < $scope.paxNames[i].seats.length; j++) {
        $scope.paxNames[i].seats[j].coach = '';
        $scope.paxNames[i].seats[j].seat = '';
      }
    }

    for (i = 0; i < $scope.seatAvail.length; i++) {
      $scope.seatAvail[i].seatsAssigned = false;
    }
    $scope.seat = [];
  };

  function setSeats_Local(data) {
    var sCoach = '';
    //console.log("setSeats_Local");
    for (var i = 0; i < $scope.paxNames.length; i++) {
      for (var j = 0; j < $scope.paxNames[i].seats.length; j++) {
        if ($scope.paxNames[i].seats[j].train == data) {
          $scope.seat[i] = $scope.paxNames[i].seats[j].seat;
          if ($scope.paxNames[i].seats[j].coach) {
            sCoach = $scope.paxNames[i].seats[j].coach;
          }
        }
      }
    }
    $scope.activeCoach = sCoach;
  }

  $scope.getAvailSeat = function (item) {
    var mres1 = RESOURCES.HOST;
    var oSeats = {};

    oSeats.dep_station = item.origin;
    oSeats.arr_station = item.destination;
    oSeats.dep_date = item.departDate;
    oSeats.train_number = item.train;

    setSeats_Local(item.train);

    oSeats.class = item.ccos;
    openModal();

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    bookingService.getSeats(userid, rndString, nnce, oSeats, mres1)
      .then(function (res) {
        if (res.wsi_error) {
          if (res.wsi_error.err_ers_text) {
            $scope.seatsError = res.wsi_error.err_ers_text;
          } else {
            $scope.seatsError = res.wsi_error.err_wsi_text;
          }
          closeModal();
        } else {
          $scope.segment.response = res.SeatMapServiceOut;
          $scope.activeIndex = 0;
          if ($scope.activeCoach) {
            for (var i = 0; i < $scope.segment.response.coaches.length; i++) {
              if ($scope.activeCoach == $scope.segment.response.coaches[i].coach_num) {
                $scope.activeIndex = i;
                break;
              }
            }
          }
          $scope.setSelectedCoach($scope.segment.response.coaches[$scope.activeIndex]);
          $scope.seatsError = '';
          closeModal();

        }
      }, function (err) {
        closeModal();
      });
  };

  $scope.gotoFC = function () {
    var lang = $translate.use();
    switch (lang) {
    case 'fr':
      openInNewTab('content/data/Conditions_Of_Carriage_fr.pdf');
      break;
    case 'be':
      openInNewTab('content/data/Conditions_Of_Carriage_be.pdf');
      break;
    default:
      openInNewTab('content/data/Conditions_Of_Carriage.pdf');
    }
  };

  function openInNewTab(url) {
    var link = angular.element('<a href="' + url + '" target="_blank"></a>');
    angular.element(document.body).append(link);
    link[0].click();
    link.remove();
  }

  $scope.gotoTC = function () {
    var lang = $translate.use();
    switch (lang) {
    case 'fr':
      openInNewTab('content/data/Terms_And_Conditions_fr.pdf');
      break;
    case 'be':
      openInNewTab('content/data/Terms_And_Conditions_be.pdf');
      break;
    default:
      openInNewTab('content/data/Terms_And_Conditions.pdf');
    }
  };

  function checkETAPData() {
    var i = 0;
    var j = 0;
    var result = true;

    for (i = 0; i < $scope.paxNames.length; i++) {
      if ($scope.paxNames[i].etap_phone === '' && $scope.paxNames[i].etap_email === '') {
        result = false;
        break;
      } else {
        if (($scope.paxNames[i].etap_Infant_Fname != '' && $scope.paxNames[i].etap_Infant_Sname === '') ||
          ($scope.paxNames[i].etap_Infant_Fname === '' && $scope.paxNames[i].etap_Infant_Sname != '')) {
          result = false;
          break;
        }
      }
    }
    return result;
  }

  function checkInfantName() {
    var i = 0;
    var j = 0;
    var result = true;

    for (i = 0; i < $scope.paxNames.length; i++) {
      //Check duplicate data
      if (result === true) {
        if ($scope.paxNames[i].etap_Infant_Fname != '' && $scope.paxNames[i].etap_Infant_Sname != '') {
          for (j = 0; j < $scope.paxNames.length; j++) {
            if (i != j) {
              if (($scope.paxNames[i].etap_Infant_Fname.toUpperCase() === $scope.paxNames[j].etap_Infant_Fname.toUpperCase()) && ($scope.paxNames[i].etap_Infant_Sname.toUpperCase() === $scope.paxNames[j].etap_Infant_Sname.toUpperCase())) {
                result = false;
                break;
              }
            }
          }
        }
      }
      if (result === true) {
        if ($scope.paxNames[i].etap_Infant_Fname != '' && $scope.paxNames[i].etap_Infant_Sname != '') {
          for (j = 0; j < $scope.paxNames.length; j++) {
            if (($scope.paxNames[i].etap_Infant_Fname.toUpperCase() === $scope.paxNames[j].firstName.toUpperCase()) &&
              ($scope.paxNames[i].etap_Infant_Sname.toUpperCase() === $scope.paxNames[j].surName.toUpperCase())) {
              result = false;
              break;
            }
          }
        }
      }
      if (result === false) {
        break;
      }
    }
    return result;
  }

  $scope.bookJourney = function (bType) {
   if (['R'].includes(globalData.getbookingType()) === false) {
    $('#myModal').modal({
      backdrop: 'static'
    });

    return;
   }

   $scope.bookJourneyConfirmed(bType);
  };

  $scope.bookJourneyConfirmed = function (bType) {
    $('#myModal').modal('hide');

    $scope.nameError = false;
    $scope.nameErrorMsg = '';
    $scope.bkgInProcess = true;
    $rootScope.pahPax = [];
    var dataCheck = checkPaxNames();

    if (globalData.getbookingType() === 'B') {
      // console.log("$rootScope.loggedInUser.ARIA_User.ESV_remaining = " + $rootScope.loggedInUser.ARIA_User.ESV_remaining);
      let adultPax = $scope.paxNames.filter(p => !p.dependant).length;
      // console.log(`adult pax: ${adultPax}; all pax: ${$scope.paxNames.length}`);

      if (typeof ($rootScope.loggedInUser.ARIA_User.ESV_remaining) !== 'undefined') {
        let dependantsLen = $rootScope.loggedInUser.ARIA_User.dependants?.length || 0;

        if ($rootScope.loggedInUser.ARIA_User.ESV_remaining < adultPax) {
          dataCheck = true;
          $scope.enquiryError = true;
          $translate('Text310').then(function (txtMsg) {
            $scope.enquiryErrMsg = txtMsg; //"There are insufficient vouchers to make this booking.";
          });
        }
      }
    }

    if ((globalData.getbookingType() === 'P') || (globalData.getbookingType() === 'F') || (globalData.getbookingType() === 'D') || (globalData.getbookingType() === 'M') || (globalData.getbookingType() === 'I') || (globalData.getbookingType() === 'T') || (globalData.getbookingType() === 'Y')) {
      if (dataCheck === false) {
        if (checkETAPData() === false) {
          dataCheck = true;
          $scope.eTapError = true;
          $translate('Text330').then(function (txtMsg) {
            $scope.eTapErrorMsg = txtMsg;
          });

        }
      }
      if (dataCheck === false) {
        if (checkInfantName() === false) {
          dataCheck = true;
          $scope.eTapError = true;
          $translate('Text342').then(function (txtMsg) {
            $scope.eTapErrorMsg = txtMsg;
          });

        }
      }
    }

    if (dataCheck === false) {
      switch (globalData.getbookingType()) {
        case 'R':
      case 'D':
        //setJourneyData();
        setBookingData_NoPay();
        var bkgSale = globalData.getBookingSaleData();
        openModal();
        var mres1 = RESOURCES.HOST;
        bookingService.bookJourney(bkgSale, mres1)
          .then(function (res) {
            if (res.wsi_error) {
              if (res.wsi_error.err_ers_text) {
                $scope.bookingError = res.wsi_error.err_ers_text;
              } else {
                $scope.bookingError = res.wsi_error.err_wsi_text;
              }
              // This is just a hack, proper solution is to indetify
              // it before the createbooking call
              if (res.wsi_error.err_wsi_nbr == 60016) {
                $translate('Text209').then(function (txtMsg) {
                  $scope.bookingError = txtMsg;
                });
                $translate('Text210').then(function (txtMsg) {
                  $scope.bookingError1 = txtMsg;
                });
                $scope.bkgInProcess = false;
              }

              $scope.bkgInProcess = false;
              $scope.bookingSuccessful = false;
              closeModal();
            } else {
              $scope.bookingError = '';
              $scope.bookingSuccessful = true;
              $translate('Text254').then(function (txtMsg) {
                $scope.bookingSuccessMsg = txtMsg + res.CreateBookingServiceOut.tnr.tnr_ers_pnr;
              });
              $scope.pnr = res.CreateBookingServiceOut.tnr.tnr_ers_pnr;

              //$scope.bookingSuccessMsg = 'Booking bookingSuccessful. The Booking reference number is ' + res.CreateBookingServiceOut.tnr.tnr_ers_pnr;
              closeModal();
              openModal();
              generateCase(res);
              closeModal();
              res.CreateBookingServiceOut = s3_ProcessPaxInfo(res.CreateBookingServiceOut);

              globalData.setbookingType(''); // set booking type to blank to avoid going to booking by typing URL
              globalData.setRefCode(res.CreateBookingServiceOut.tnr.tnr_ers_pnr);
              globalData.setElgBkgData(res.CreateBookingServiceOut);

              if (bType === '1') {
                $location.path('/retrieve');
              } else {
                //Send ETAP AUTH, update passengers, Passbook or pdf ticket.
                $scope.etap_Ticket_type = bType;
                SendEtap();
              }

              $scope.bkgInProcess = false;

            }
          }, function (err) {
            //error
            $scope.bookingError = err;
            $scope.bkgInProcess = false;
            closeModal();
            //alert(err);
          }); //End web service
        break;
      default:
        setBookingData_WithPay();
        var bkgSale = globalData.getBookingSaleData();
        openModal();
        var mres1 = RESOURCES.HOST;
        bookingService.bookJourney(bkgSale, mres1)
          .then(function (res) {
            if (res.wsi_error) {
              if (res.wsi_error.err_ers_text) {
                $scope.bookingError = res.wsi_error.err_ers_text;
              } else {
                $scope.bookingError = res.wsi_error.err_wsi_text;
              }
              // This is just a hack, proper solution is to indetify
              // it before the createbooking call
              if (res.wsi_error.err_wsi_nbr == 60016) {
                $translate('Text209').then(function (txtMsg) {
                  $scope.bookingError = txtMsg;
                });
                //$scope.bookingError  = "Contact Details Required – Please enter a contact phone" + " in the Additional Phone field below";
                $translate('Text210').then(function (txtMsg) {
                  $scope.bookingError1 = txtMsg;
                });
                $scope.bkgInProcess = false;
                //$scope.bookingError1 = "(You can save a phone number in your profile to prevent" + " this message in the future)";
              }
              $scope.bookingSuccessful = false;
              closeModal();
            } else {
              $scope.bookingError = '';
              $scope.bkgInProcess = false;
              closeModal();
              $scope.bookingSuccessful = false;
              if (res.CreateBookingServiceOut.tnr.tnr_ers_pnr) {
                res.CreateBookingServiceOut = s3_ProcessPaxInfo(res.CreateBookingServiceOut);
                globalData.setElgBkgData(res.CreateBookingServiceOut);
                $location.path('/payment'); //Goto Payment page to pay by card.
              }
            }
          }, function (err) {
            //error
            $scope.bookingError = err;
            $scope.bkgInProcess = false;
            closeModal();
            alert(err);
          }); //End web service
      }
    } else {
      $scope.bkgInProcess = false;
    }
  };

  $scope.copyToAll = function (pax) {
    var i = 0;
    var result_1 = true;
    var result_2 = true;

    if (pax.etap_email != '') {
      result_1 = validateEmail(pax.etap_email);
    }
    if (pax.etap_phone != '') {
      result_2 = validatePhone(pax.etap_phone);
    }

    if (pax.etap_email != '') {
      if (result_1 === true) {
        pax.eClass1 = '';
        pax.eClass2 = '';
      } else {
        pax.eClass1 = 'form-group has-error has-feedback';
        pax.eClass2 = 'glyphicon glyphicon-remove form-control-feedback';
      }
    }

    if (pax.etap_phone != '') {
      if (result_2 === true) {
        pax.pClass1 = '';
        pax.pClass2 = '';
      } else {
        pax.pClass1 = 'form-group has-error has-feedback';
        pax.pClass2 = 'glyphicon glyphicon-remove form-control-feedback';
      }
    }
    if (result_1 === true && result_2 === true) {
      if ((pax.etap_email != '' && pax.etap_phone === '') ||
        (pax.etap_email === '' && pax.etap_phone != '') ||
        (pax.etap_email != '' && pax.etap_phone != '')) {
        for (i = 0; i < $scope.paxNames.length; i++) {
          $scope.paxNames[i].etap_email = pax.etap_email;
          $scope.paxNames[i].etap_phone = pax.etap_phone;
          $scope.paxNames[i].etap_phone_dial = pax.etap_phone_dial;
          pax.eClass1 = '';
          pax.eClass2 = '';
          pax.pClass1 = '';
          pax.pClass2 = '';
        }
      }
    }

    $scope.pdfFlag = true;
    for (var i = 0; i < $scope.paxNames.length; i++) {
      if ((($scope.paxNames[i].etap_email === '') && ($scope.paxNames[i].etap_phone === '')) || (($scope.paxNames[i].etap_email === '') && ($scope.paxNames[i].etap_phone != ''))) {
        $scope.pdfFlag = false;
      }
    }

    $scope.passbookFlag = true;
    for (var i = 0; i < $scope.paxNames.length; i++) {
      if (($scope.paxNames[i].etap_email === '')) {
        $scope.passbookFlag = false;
        break;
      }
    }
  };

  function getEtapTicketType() {
    var result = 3; // E*only = 1,ABS=2,E*+ something=3

    if (isBkgABS() === true) {
      result = 2;
    } else if (isBkgEurostarOnly() === true || isBkgEROnly() === true) {
      result = 1;
    }
    return result;
  }

  function isBkgEurostarOnly() {
    var result = true;
    var booking = globalData.getElgBkgData();
    for (var i = 0; i < booking.tnr.tnr_segments.length; i++) {
      if (booking.tnr.tnr_segments[i].segt_carrier != 'ES') {
        result = false;
        break;
      }
    }
    return result;
  }

  function isBkgEROnly() {
    var result = true;
    var booking = globalData.getElgBkgData();
    for (var i = 0; i < booking.tnr.tnr_segments.length; i++) {
      if (booking.tnr.tnr_segments[i].segt_carrier != 'ER') {
        result = false;
        break;
      }
    }
    return result;
  }

  function isBkgABS() {
    var result = false;
    var booking = globalData.getElgBkgData();
    for (var i = 0; i < booking.tnr.tnr_tkts.length; i++) {
      if (booking.tnr.tnr_tkts[i].tktd_fare_type === 'B') {
        result = true;
        break;
      }
    }
    return result;
  }

  function SendEtap() {
    var mres1 = RESOURCES.HOST;
    var sUrl = mres1 + 'api/etap/authenticate?pos=' + RESOURCES.ETAP_POS;
    var hdrData = {
      'cid': RESOURCES.ETAP_CID
    };
    openModal();
    eTapServices.authenticate($scope.pnr, $scope.paxNames[0].surName, sUrl, hdrData)
      .then(function (res) {
        if (res.messages) {
          if (res.messages.length > 0) {
            for (var i = 0; i < res.messages.length; i++) {
              if (res.messages[i].level === 'ERROR') {
                if (res.messages[i].message) {
                  etapWarning();
                  closeModal();
                }
              }
            }
          }
        }
        if (res.accessToken && res.booking) {
          $scope.eTapAuthRsp = res;
          if (res.booking.detailsCaptured) {
            if (res.booking.detailsCaptured === 'NOT_CAPTURED') {
              UpdatePaxETAP(0);
            }
            else if (res.booking.detailsCaptured === 'CAPTURED'){ // Voyager already adds the details
              UpdatePaxETAP(1);
              $location.path('/retrieve');
              closeModal();
            }
          }
        } else {
          etapWarning();
          closeModal();
        }
      }, function (err) {
        etapWarning();
        closeModal();
      });
  }

  function UpdatePaxETAP(etap_status) {
    var i = 0;
    var j = 0;
    for (i = 0; i < $scope.eTapAuthRsp.booking.passengers.length; i++) {
      for (j = 0; j < $scope.paxNames.length; j++) {
        if (($scope.eTapAuthRsp.booking.passengers[i].firstName.toUpperCase() === $scope.paxNames[j].firstName.toUpperCase()) &&
          ($scope.eTapAuthRsp.booking.passengers[i].lastName.toUpperCase() === $scope.paxNames[j].surName.toUpperCase())) {

          $scope.paxNames[j].etap_id = $scope.eTapAuthRsp.booking.passengers[i].id;
          $scope.paxNames[j].etap_cin = $scope.eTapAuthRsp.booking.passengers[i].cin;
          break;
        }
      }
    }
    if (etap_status === 0) {
      sendPaxUpdate(0);
    }
  }

  function etapWarning() {
    var elgData = globalData.getElgBkgData();
    var msg;
    if (!elgData.tnr.tnr_msg) {
      elgData.tnr.tnr_msg = [];
    }

    $translate('Text344').then(function (txtMsg) {
      msg = {
        'msg_text': txtMsg
      };
    });
    elgData.tnr.tnr_msg.push(msg);
    globalData.setElgBkgData(elgData);
    $location.path('/retrieve');
  }

  function sendPaxUpdate(idx) {

    var sUrl = '';
    var hdrData = {};
    var data = {};
    var paxId = '';
    var hasErr = false;
    if (idx < $scope.paxNames.length) {
      paxId = $scope.paxNames[idx].etap_id;
      sUrl = RESOURCES.ETAP_PATH + $scope.pnr + '/passengers/' + paxId;
      hdrData = {
        'authorization': $scope.eTapAuthRsp.accessToken.token,
        'cid': RESOURCES.ETAP_UPDATE_CID
      };

      if ($scope.paxNames[idx].etap_email != '' && $scope.paxNames[idx].etap_phone != '') {
        data = {
          'cin': $scope.paxNames[idx].cin,
          'email': $scope.paxNames[idx].etap_email,
          'optIn': true,
          'phone': {
            'countryCode': $scope.paxNames[idx].etap_phone_dial.toString(),
            'number': $scope.paxNames[idx].etap_phone.toString()
          }
        };
      } else {
        if ($scope.paxNames[idx].etap_email != '' && $scope.paxNames[idx].etap_phone === '') {
          data = {
            'cin': $scope.paxNames[idx].cin,
            'email': $scope.paxNames[idx].etap_email,
            'optIn': true
          };
        } else if ($scope.paxNames[idx].etap_email === '' && $scope.paxNames[idx].etap_phone != '') {
          data = {
            'cin': $scope.paxNames[idx].cin,
            'optIn': true,
            'phone': {
              'countryCode': $scope.paxNames[idx].etap_phone_dial.toString(),
              'number': $scope.paxNames[idx].etap_phone.toString()
            }
          };
        }
      }
      eTapServices.updatePax(data, sUrl, hdrData)
        .then(function (res) {
          if (res.messages) {
            if (res.messages.length > 0) {
              for (var i = 0; i < res.messages.length; i++) {
                if (res.messages[i].level === 'ERROR') {
                  if (res.messages[i].message) {
                    hasErr = true;
                  }
                }
              }
            }
          }

          if (hasErr === false) {
            if (res.passenger) {
              idx = idx + 1;
              sendPaxUpdate(idx);
            }
          } else {
            etapWarning();
            closeModal();
          }

        }, function (err) {
          etapWarning();
          closeModal();
        });
    } else {
      //ETAP Update is done without errors now proceed to PDF or PASSBOOK Ticket.
      //eTapTicket(0);
      sendCreateInfant(0);
    }
  }

  function sendCreateInfant(idx) {
    var sUrl = '';
    var hdrData = {};
    var data = {};
    var paxId = '';
    var hasErr = false;

    if (idx < $scope.paxNames.length) {
      if ($scope.paxNames[idx].etap_Infant_Fname != '' && $scope.paxNames[idx].etap_Infant_Sname != '') {

        sUrl = RESOURCES.ETAP_PATH + $scope.pnr + '/infants';
        hdrData = {
          'authorization': $scope.eTapAuthRsp.accessToken.token,
          'cid': RESOURCES.ETAP_UPDATE_CID
        };
        data = {
          'guardianId': $scope.paxNames[idx].etap_id,
          'firstName': $scope.paxNames[idx].etap_Infant_Fname,
          'lastName': $scope.paxNames[idx].etap_Infant_Sname
        };

        eTapServices.createInfant(data, sUrl, hdrData)
          .then(function (res) {
            if (res.messages) {
              if (res.messages.length > 0) {
                for (var i = 0; i < res.messages.length; i++) {
                  if (res.messages[i].level === 'ERROR') {
                    if (res.messages[i].message) {
                      // writeTechAudit(res.messages[i].message);
                      // etapWarning();
                      // closeModal();
                      hasErr = true;
                    }
                  }
                }
              }
            }
            if (hasErr === false) {
              if (res.infant) {
                idx = idx + 1;
                sendCreateInfant(idx);
              }
            } else {
              etapWarning();
              closeModal();
            }

          }, function (err) {
            //send error to writeAudit
            etapWarning();
            closeModal();
          });
      } else {
        idx = idx + 1;
        sendCreateInfant(idx);
      }
    } else {
      $location.path('/retrieve');
      closeModal();
    }
  }

  $scope.filterNumValue = function ($event) {
    if (isNaN(String.fromCharCode($event.keyCode))) {
      $event.preventDefault();
    }
  };

  function setBookingData_NoPay() {
    addBookingAuth();
    addBookingAdditionalDetails();
    addBookingSegments();
    addBookingContactDetails();
    addBookingPassengers();
    addBookingPayment();
    addBookingID();
    addBookingTicketingFlag('1');
    addBookingCurrency($scope.currency);
    addBookingDependantsAdditionalDetails();
  }

  function setBookingData_WithPay() {
    addBookingAuth();
    addBookingAdditionalDetails();
    addBookingSegments();
    addBookingContactDetails();
    addBookingPassengers();
    addBookingID();
    addBookingTicketingFlag('0');
    addBookingCurrency($scope.currency);
    addBookingDependantsAdditionalDetails();
  }

  function addBookingAdditionalDetails() {
    var bkgSale = globalData.getBookingSaleData();

    bkgSale.additional_details = [
      {
        key: "customer_language",
        value: $scope.selectedLanguageCode.code,
      }
    ];
  }

  function addBookingDependantsAdditionalDetails() {
    var bkgSale = globalData.getBookingSaleData();

    // Adds dependants
    bkgSale.names.filter(n => n.dependant).forEach(n => {
      bkgSale.additional_details.push({
	"passengerRef": n.s3_passenger_id,
	"key": "dependant",
	"value": "true"
      });
    });
  }

  function addBookingAuth() {
    var bkgSale = globalData.getBookingSaleData();

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    bkgSale.Aria_User_Name = userid;
    bkgSale.Aria_Passcode = rndString;
    bkgSale.Client_Tranx_Id = nnce;
  }

  function addBookingSegments() {
    //Get the booking sale object & add segments
    var bkgSale = globalData.getBookingSaleData();
    var oSegs = globalData.getBookingSegments();

    bkgSale.segments = [];

    for (var iCount = 0; iCount < parseInt(oSegs.length); iCount++) {
      var oSeg = {
        origin: '',
        destination: '',
        carrier: '',
        train_number: '',
        dep_date: '',
        dep_time: '',
        ccos: '',
        s3_service_identifier: '',
        s3_direction: '',
        s3_items:[]

      };

      oSeg.origin = oSegs[iCount].origin;
      oSeg.destination = oSegs[iCount].destination;
      oSeg.carrier = oSegs[iCount].carrier;
      oSeg.train_number = oSegs[iCount].train_number;
      oSeg.dep_date = oSegs[iCount].dep_date;
      oSeg.dep_date_full = oSegs[iCount].dep_date_full;
      oSeg.dep_time = oSegs[iCount].dep_time;
      oSeg.ccos = oSegs[iCount].ccos;
      oSeg.s3_service_identifier = oSegs[iCount].s3_service_identifier;
      oSeg.s3_direction= oSegs[iCount].s3_direction;
      oSeg.s3_items= oSegs[iCount].s3_items;

      if (oSeg.carrier == 'ES' && ((oSegs[iCount].fareType == 'STANDARD PREMIER') || (oSegs[iCount].fareType == 'BUSINESS PREMIER'))) {
        oSeg.tfaxs = getTfax();
      }
      if (oSegs[iCount].reservation == 'N') {
        oSeg.assign_seat = 'N';
      }
      if (oSeg.carrier == 'ES') {
        var cnum = getCoachNum(oSeg.train_number);
        var snum = [];
        snum = getSeatNum(oSeg.train_number);
        if (cnum) {
          if (snum) {
            oSeg.coach_num = cnum;
            oSeg.seat_nums = snum;
          }
        }
      }
      bkgSale.segments.push(oSeg);
    }
    // Set the data to the booking sale object.
    globalData.setBookingSaleData(bkgSale);
  }

  function getCoachNum(tno) {
    var result = '';
    for (var i = 0; i < $scope.paxNames.length; i++) {
      for (var j = 0; j < $scope.paxNames[i].seats.length; j++) {
        if ($scope.paxNames[i].seats[j].train == tno) {
          if ($scope.paxNames[i].seats[j].coach) {
            result = parseInt($scope.paxNames[i].seats[j].coach);
            break;
          }
        }
      }
      if (result) {
        break;
      }
    }

    return result;
  }

  function getSeatNum(tno) {
    var result = [];
    for (var i = 0; i < $scope.paxNames.length; i++) {
      for (var j = 0; j < $scope.paxNames[i].seats.length; j++) {
        if ($scope.paxNames[i].seats[j].train == tno) {
          if ($scope.paxNames[i].seats[j].seat) {
            result.push($scope.paxNames[i].seats[j].seat);
          }
        }
      }
    }
    return result;
  }

  function getTfax() {
    var result = [];
    for (var i = 0; i < $scope.paxNames.length; i++) {
      if ($scope.paxNames[i].paxMealCode) {
        var tfax = {
          code: '',
          paxnums: []
        };

        if (i == 0) {
          tfax.code = $scope.paxNames[i].paxMealCode;
          tfax.paxnums.push(i + 1);
          result.push(tfax);
        } else {
          var found = false;
          for (var k = 0; k < result.length; k++) {
            if ($scope.paxNames[i].paxMealCode == result[k].code) {
              result[k].paxnums.push(i + 1);
              found = true;
              break;
            }
          }
          if (found == false) {
            tfax.code = $scope.paxNames[i].paxMealCode;
            tfax.paxnums.push(i + 1);
            result.push(tfax);
          }
        }
      }
    }
    return result;
  }

  function addBookingContactDetails() {
    var email = '';
    //Get the booking sale object & add segments
    var bkgSale = globalData.getBookingSaleData();
    bkgSale.contact_details = [];

    //Add Contact Details
    var contactName = $rootScope.loggedInUser.ARIA_User.AccountName.replace(/[^a-zA-Z ]/g, '');
    var contactPerson = contactName.split(' ');

    var contactPhone = [];
    var contactEmail = [];

    if ($rootScope.loggedInUser.ARIA_User.MobilePhone) {
      contactPhone.push($rootScope.loggedInUser.ARIA_User.MobilePhone);
    } else {
      if ($scope.showEtap) {
        for (var iCount = 0; iCount < $scope.paxNames.length; iCount++) {
          if ($scope.paxNames[iCount].etap_phone) {
            contactPhone.push($scope.paxNames[iCount].etap_phone);
            break;
          }
        }
      }
    }

    if ($scope.addPhone) {
      contactPhone.push($scope.addPhone); //Additional Phone number
    }
    //1. add disruption email
    if ($rootScope.loggedInUser.ARIA_User.Customer_Email) {
      email = $rootScope.loggedInUser.ARIA_User.Customer_Email.replace('’', '').replace('\'', '');
      contactEmail.push(email);
    }
    else {
      if ($scope.paxNames[0].etap_email) {
        //send etap email as first email in list to set etap email as disruption email.
        email = $scope.paxNames[0].etap_email.replace('’', '').replace('\'', '');
        contactEmail.push(email);
      }
    }

    //2. add manager email
    // if (globalData.getbookingType() == "D") {
    //   $scope.addEmail = $scope.paxNames[0].etap_email;
    //   $scope.addPhone = $scope.paxNames[0].etap_phone;
    //   if ($rootScope.loggedInUser.ARIA_User.Manager_Email) {
    //     email = $rootScope.loggedInUser.ARIA_User.Manager_Email;
    //     email = email.replace("’", "");
    //     email = email.replace("'", "");
    //     //email = email.replace ("-", "");
    //     contactEmail.push(email);
    //   }
    // }

    //3. add user email
    // if ($scope.addEmail) {
    //   if ($scope.addEmail != $scope.paxNames[0].etap_email && $scope.paxNames[0].etap_email != '') {
    //     email = $scope.addEmail;
    //     email = email.replace("’", "");
    //     email = email.replace("'", "");
    //     //email = email.replace ("-", "");
    //     contactEmail.push(email); //Additional Email address.
    //   }
    // }
    // if ($scope.paxNames[0].etap_email) {
    //   if ($rootScope.loggedInUser.ARIA_User.Customer_Email) {
    //     email = $rootScope.loggedInUser.ARIA_User.Customer_Email;
    //     email = email.replace("’", "");
    //     email = email.replace("'", "");
    //     //email = email.replace ("-", "");
    //     contactEmail.push(email);
    //   }
    // }

    var pContact = {
      'firstname': contactPerson[0],
      'surname': contactPerson[1],
      'address': {
        'line_1': $rootScope.loggedInUser.ARIA_User.Street,
        'line_2': $rootScope.loggedInUser.ARIA_User.City,
        'line_3': $rootScope.loggedInUser.ARIA_User.State,
        'country': $rootScope.loggedInUser.ARIA_User.Country,
        'postcode': $rootScope.loggedInUser.ARIA_User.PostalCode
      },
      'phone': contactPhone,
      'email': contactEmail
    };

    bkgSale.contact_details = pContact;

    // Set the data to the booking sale object.
    globalData.setBookingSaleData(bkgSale);
  }

  function addBookingPassengers() {
    var mobile_number = '';

    //Get the booking sale object & add segments
    var bkgSale = globalData.getBookingSaleData();
    var oSegs = globalData.getBookingSegments();
    globalData.setPassengers($scope.paxNames); // Set the passenger to globalData
    bkgSale.names = [];

    //Add Passenger names
    var pNum = parseInt($scope.enqData.paxNum);

    // var email = $rootScope.loggedInUser.ARIA_User.Customer_Email;
    // email = email.replace("’", "");
    // email = email.replace("'", "");
    //email = email.replace ("-", "");

    for (var iCount = 0; iCount < pNum; iCount++) {
      if ($scope.showEtap) {
        if ($scope.paxNames[iCount].etap_phone) {
          mobile_number = `${$scope.paxNames[iCount].etap_phone_dial.replace('+', '')}${$scope.paxNames[iCount].etap_phone}`;
        } else {
          mobile_number = '';
        }
      } else {
        mobile_number = '';
      }
      if (mobile_number === '' && $rootScope.loggedInUser.ARIA_User.MobilePhone) {
        mobile_number = $rootScope.loggedInUser.ARIA_User.MobilePhone;
      }
      var pName;
      let email;
      if ($scope.paxNames[iCount].etap_email){
        email = $scope.paxNames[iCount].etap_email.replace('’', '').replace('\'', '');
      }
      else {
        email = $rootScope.loggedInUser.ARIA_User.Customer_Email.replace('’', '').replace('\'', '');
      }
      if ($scope.paxNames[iCount].dob === '') {
        pName = {
          's3_passenger_id': 'passenger_' + $scope.paxNames[iCount].Id ,
          'firstname': $scope.paxNames[iCount].firstName,
          'surname': $scope.paxNames[iCount].surName,
          'paxtype': oSegs[0].paxType,
          'mobile_number': mobile_number,
          'email_address': email,
          'dependant': $scope.paxNames[iCount].dependant
        };
      } else {
        pName = {
          's3_passenger_id': 'passenger_' + $scope.paxNames[iCount].Id,
          'firstname': $scope.paxNames[iCount].firstName,
          'surname': $scope.paxNames[iCount].surName,
          'paxtype': oSegs[0].paxType,
          'mobile_number': mobile_number,
          'email_address': email,
          'dependant': $scope.paxNames[iCount].dependant,
          'date_of_birth': convertToDOB($scope.paxNames[iCount].dob)
        };
      }

      //var pName = { "firstname" : $scope.paxNames[iCount].firstName, "surname" : $scope.paxNames[iCount].surName, "paxtype" : oSegs[0].paxType, "mobile_number": $rootScope.loggedInUser.ARIA_User.MobilePhone,"email_address": email}
      bkgSale.names.push(pName);
    }

    // Set the data to the booking sale object.
    globalData.setBookingSaleData(bkgSale);
  }

  function addBookingPayment() {
    //Get the booking sale object & add segments
    var bkgSale = globalData.getBookingSaleData();
    var bkgCurrency = globalData.getCurrency();
    bkgSale.payments = [];
    //add Payment data
    var bkg_payment = {
      'type': '1',
      'amount': '0',
      'currency': bkgCurrency,
      'indicator': 'D'
    };
    bkgSale.payments.push(bkg_payment);
    // Set the data to the booking sale object.
    globalData.setBookingSaleData(bkgSale);
  }

  function addBookingID() {
    //Get the booking sale object & add segments
    var bkgSale = globalData.getBookingSaleData();

    bkgSale.id = $rootScope.loggedInUser.ARIA_User.Aria_User_Name;
    bkgSale.booking_type = globalData.getbookingType();

    // Set the data to the booking sale object.
    globalData.setBookingSaleData(bkgSale);
  }

  function addBookingTicketingFlag(flag) {
    //Get the booking sale object & add segments
    //0 = Provisional, 1 = Ticket.
    var bkgSale = globalData.getBookingSaleData();
    //Set Ticket
    bkgSale.ticket = flag;
    // Set the data to the booking sale object.
    globalData.setBookingSaleData(bkgSale);
  }

  function addBookingCurrency(data) {
    var bkgSale = globalData.getBookingSaleData();
    //Set Ticket
    bkgSale.currency = data;
    // Set the data to the booking sale object.
    globalData.setBookingSaleData(bkgSale);
  }

    function getERMilesRequired() {
      const stdFareTypes = ['Standard', 'Eurostar Standard'];
        let usedMiles = 0;

        globalData.getBookingSegments().forEach(seg => {
          usedMiles += seg.s3_items.length * ((stdFareTypes.includes(seg.fareType)) ? 1 : 2);
        });

        return usedMiles;
    }

  function generateCase(bookingData) {
    var caseData = {};
    var enqData = globalData.getBookingEnquiryData();

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    caseData.Aria_User_Name = userid;
    caseData.Aria_Passcode = rndString;
    caseData.Client_Tranx_Id = nnce;

    caseData.booking_type = globalData.getbookingType();
    caseData.booking_reference = bookingData.CreateBookingServiceOut.tnr.tnr_ers_pnr;

    if (caseData.booking_type === "R") {
       caseData.es_vo_used = getERMilesRequired();
    }

    caseData.lead_passenger_title = '';
    caseData.lead_passenger_first_name = bookingData.CreateBookingServiceOut.tnr.tnr_names[0].name_initials;
    caseData.lead_passenger_last_name = bookingData.CreateBookingServiceOut.tnr.tnr_names[0].name_surname;
    caseData.number_of_passengers = enqData.paxNum;

    var oDate = new Date(enqData.dDateFull);
    caseData.outbound_dep_date_time = oDate.getDate() + '-' + (oDate.getMonth() + 1) + '-' + oDate.getFullYear();
    caseData.outbound_origin_station_code = enqData.originNumeric;
    caseData.outbound_origin_station_name = enqData.originFullName;
    caseData.outbound_destination_station_code = enqData.destinationNumeric;
    caseData.outbound_destination_station_name = enqData.destinationFullName;
    if (enqData.fareType === 'STANDARD') {
      caseData.outbound_cos_id = 'B';
    } else if (enqData.fareType === 'STANDARD PREMIER') {
      caseData.outbound_cos_id = 'H';
    } else if (enqData.fareType === 'BUSINESS PREMIER') {
      caseData.outbound_cos_id = 'A';
    }

    if (enqData.isReturnBooking) {
      var oRDate = new Date(enqData.returndDateFull);
      //caseData.inbound_dep_date_time = enqData.returndDate;
      caseData.inbound_dep_date_time = oRDate.getDate() + '-' + (oRDate.getMonth() + 1) + '-' + oRDate.getFullYear();
      caseData.inbound_origin_station_code = enqData.returnOriginNumeric;
      caseData.inbound_origin_station_name = enqData.returnOriginFullName;
      caseData.inbound_destination_station_code = enqData.returnDestinationNumeric;
      caseData.inbound_destination_station_name = enqData.returnDestinationFullName;
      if (enqData.returnFareType === 'STANDARD') {
        caseData.inbound_cos_id = 'B';
      } else if (enqData.returnFareType === 'STANDARD PREMIER') {
        caseData.inbound_cos_id = 'H';
      } else if (enqData.returnFareType === 'BUSINESS PREMIER') {
        caseData.inbound_cos_id = 'A';
      }
    }

    var mres1 = RESOURCES.HOST;
    bookingService.createCase(caseData, mres1)
      .then(function (res) {
        if (res.Booking_Case_Response.status == 'FAILURE') {
          $scope.bookingError = res.Booking_Case_Response.status;
        } else {
          if (caseData.booking_type === "R") {
            const tsvRemaining = parseInt($rootScope.loggedInUser.ARIA_User.TSV_Balance);
            $rootScope.loggedInUser.ARIA_User.TSV_Balance = (tsvRemaining - caseData.es_vo_used).toString();
          }
        }
      }, function (err) {
        //error
        $scope.bookingError = err;
      });
  }
});
