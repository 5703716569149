import app from '../app';
import {convertDate, convertTime} from '../utils';

app.controller('EnquiryController', ['$scope', '$rootScope', '$location', 'RESOURCES', 'CURRENCIES', '$routeParams', 'globalData', 'md5', '$translate', function ($scope, $rootScope, $location, RESOURCES, CURRENCIES, $routeParams, globalData, md5, $translate) {

  init();

  function init() {
    $scope.currencies = CURRENCIES;
    $scope.currency = $rootScope.loggedInUser.ARIA_User.Default_Currency;
    globalData.setCurrency($rootScope.loggedInUser.ARIA_User.Default_Currency);
    $scope.bkgType = globalData.getBookingTypeText(globalData.getbookingType());
    $translate($scope.bkgType).then(function (txtMsg) {
      $scope.bkgType = txtMsg;
    });
    $scope.today = new Date(); //current date to stop date components displaying previous dates
    $scope.maxDate = new Date();
    $scope.maxDate.setDate($scope.today.getDate() + 180);
    // Make return as default
    $scope.btnReturnState = 'active';
    // re-route to  HOME page if booking type is not available.
    if (($scope.bkgType == '') || ($scope.bkgType == undefined)) {
      $location.path('/');
      return false;
    }
    // Display currency on  the page only if not duty, contractor voucher, Momentum, or Eurostar Red
    switch (globalData.getbookingType()) {
      case 'D':
        $scope.maxPax = 9;
        $scope.currency = 'GBP';
        globalData.setCurrency($scope.currency);
        $scope.showCurr = false;
        $('#dutyModal').modal({
          backdrop: 'static'
        });
        $scope.hideOneWay = false;
        $scope.showDependantMsg = false;
        break;
      case 'F':
	// adds self
        $scope.maxPax = 1;

	// adds dependants, if they exist
	if ($rootScope.loggedInUser.ARIA_User.dependants) {
	  $scope.maxPax += $rootScope.loggedInUser.ARIA_User.dependants.length;
	}

	// adds possible spouse/partner, if they exist
	if (($rootScope.loggedInUser.ARIA_User.Spouse_Partner_First_Name || '').trim() !== '' && ($rootScope.loggedInUser.ARIA_User.Spouse_Partner_Last_Name || '').trim() !== '') {
	  $scope.maxPax += 1;
	}
	      
	      
        $scope.showCurr = true;
        $('#myModal').modal({
          backdrop: 'static'
        });
        $scope.hideOneWay = false;
        $scope.showDependantMsg = false;
        break;
      case 'P':
        $scope.maxPax = 5;
        $scope.showCurr = true;
        $scope.today.setDate($scope.today.getDate() + 1);
        $scope.hideOneWay = false;
        if ($rootScope.loggedInUser.ARIA_User.Staff_Voucher === 'true') {

          if ($rootScope.loggedInUser.ARIA_User.dependants) {
            if ($rootScope.loggedInUser.ARIA_User.dependants.length >= 1) {
              $scope.showDependantMsg = true;
            } else {
              $scope.showDependantMsg = false;
            }
          } else {
            $scope.showDependantMsg = false;
          }
        }
        break;
      case 'R':
        // Eurostar Red
        $scope.maxPax = 9;
        $scope.currency = "EUR";
        globalData.setCurrency($scope.currency);
        $scope.showCurr = false;
        $scope.today.setDate($scope.today.getDate() + 3);
        $scope.maxDate = new Date();
        $scope.maxDate.setDate($scope.today.getDate() + 220);
        $scope.hideOneWay = false;
        if ($rootScope.loggedInUser.ARIA_User.Staff_Voucher === 'true') {
          if ($rootScope.loggedInUser.ARIA_User.dependants) {
            if ($rootScope.loggedInUser.ARIA_User.dependants.length >= 1) {
              $scope.showDependantMsg = true;
            } else {
              $scope.showDependantMsg = false;
            }
          } else {
            $scope.showDependantMsg = false;
          }
        }
        break;
      case 'T':
        //TFL Booking.
        $scope.maxPax = 6;
        $scope.currency = 'GBP';
        globalData.setCurrency($scope.currency);
        $scope.showCurr = false;
        $scope.today.setDate($scope.today.getDate() + 1);
        $scope.hideOneWay = false;
        $scope.showDependantMsg = false;
        break;
      case 'Y':
        //MICE Booking.
        $scope.maxPax = 5;
        globalData.setCurrency($scope.currency);
        $scope.showCurr = true;
        $scope.today.setDate($scope.today.getDate() + 1);
        $scope.hideOneWay = false;
        $scope.showDependantMsg = false;
        break;
      case 'M':
        $scope.maxPax = 9;
        $scope.currency = 'GBP';
        globalData.setCurrency($scope.currency);
        $scope.showCurr = false;
        $scope.hideOneWay = true;
        $scope.showDependantMsg = false;
        break;
      case 'I':
        $scope.maxPax = 9;
        $scope.currency = 'GBP';
        globalData.setCurrency($scope.currency);
        $scope.showCurr = false;
        $scope.hideOneWay = true;
        $scope.showDependantMsg = false;
        break;
      case 'B':
        $scope.maxPax = 9;
        //$scope.currency ="GBP";
        globalData.setCurrency($scope.currency);
        $scope.showCurr = true;
        $scope.hideOneWay = false;
        if ($rootScope.loggedInUser.ARIA_User.dependants) {
          if ($rootScope.loggedInUser.ARIA_User.dependants.length > 1) {
            $scope.showDependantMsg = true;
          } else {
            $scope.showDependantMsg = false;
          }
        } else {
          $scope.showDependantMsg = false;
        }
        break;
      case 'G':
        $scope.maxPax = 1;
        $scope.showCurr = false;
        $scope.hideOneWay = false;
        break;
    }

    $scope.originList = globalData.getStaionList(globalData.getbookingType());
    $scope.destinationList = globalData.getStaionList(globalData.getbookingType());
    $scope.departDateValue = new Date($scope.today.getFullYear(), $scope.today.getMonth(), $scope.today.getDate()); //$scope.today; //new Date();
    $scope.returnDateValue = new Date($scope.today.getFullYear(), $scope.today.getMonth(), $scope.today.getDate()); //$scope.today; //new Date();
    //console.log($scope.departDateValue + "-" + $scope.returnDateValue);
    $scope.departTimeValue = new Date('00:00');
    $scope.returnTimeValue = new Date('00:00');
    $scope.adultPaxValue = 1;
    $scope.enquiryError = false;
    $scope.enquiryErrMsg = '';
    $scope.returnJourney = true;

    if ($scope.currency === 'GBP') {
      $scope.StaffVoucherUnitValue = Number(RESOURCES.VOUCHER_UNIT_VALUE.GBP);
    } else if ($scope.currency === 'EUR') {
      $scope.StaffVoucherUnitValue = Number(RESOURCES.VOUCHER_UNIT_VALUE.EUR);
    }

  }

  $scope.onOriginSelect = function (item) {
    //console.log(item);
    $scope.originHorizon = item.stn_bkg_hrzn;
  };
  $scope.onDestinationSelect = function (item) {
    //console.log(item);
    $scope.destinationHorizon = item.stn_bkg_hrzn;
    var today1 = new Date(); //new Date();

    if ($scope.originHorizon) {
      if ($scope.originHorizon <= $scope.destinationHorizon) {
        $scope.maxDate = new Date();
        if ((globalData.getbookingType() == 'P') || (globalData.getbookingType() == 'T') || (globalData.getbookingType() == 'Y')) {
          $scope.maxDate.setDate(today1.getDate() + $scope.originHorizon);
        } else if (globalData.getbookingType() == 'R') {
          $scope.maxDate.setDate(today1.getDate() + 220);
        } else {
          $scope.maxDate.setDate($scope.today.getDate() + $scope.originHorizon);
        }
        $scope.bookingHorizon = $scope.originHorizon;
      } else if ($scope.destinationHorizon <= $scope.originHorizon) {
        $scope.maxDate = new Date();
        if ((globalData.getbookingType() == 'P') || (globalData.getbookingType() == 'T') || (globalData.getbookingType() == 'Y')) {
          $scope.maxDate.setDate(today1.getDate() + $scope.destinationHorizon);
        } else if (globalData.getbookingType() == 'R') {
          $scope.maxDate.setDate(today1.getDate() + 220);
        } else {
          $scope.maxDate.setDate($scope.today.getDate() + $scope.destinationHorizon);
        }
        $scope.bookingHorizon = $scope.destinationHorizon;
      }
    }
  };

  $scope.changeCurrency = function () {
    globalData.setCurrency($scope.currency);
  };

  $scope.goBack = function () {
    $location.path('/');
    $('.modal-backdrop').hide();
  };

  $scope.startsWith = function (city, viewValue) {
    return city.substr(0, viewValue.length).toLowerCase() == viewValue.toLowerCase();
  };

  $scope.dateChange = function () {
    $scope.returnDateValue = $scope.departDateValue;
  };

  $scope.readKey = function (event) {
    var x = event.which || event.keyCode;
    //block all keypress except tab.
    if (x != 9) {
      event.preventDefault();
    }
  };

  $scope.setReturn = function (value) {
    $scope.returnJourney = value;
    $scope.btnReturnState = '';
    $scope.btnOneWayState = '';
    if ($scope.returnJourney == true) {
      $scope.btnReturnState = 'active';
    } else {
      $scope.btnOneWayState = 'active';
    }
  };

  $scope.search = function (shouldBypassABSCheckOnBlueVoucher = false) {
    var dataCheck = false;
    if (globalData.getStationCode($scope.originName) == '') {
      $scope.noResults1 = true;
      dataCheck = true;
    }
    if (globalData.getStationCode($scope.destinationName) == '') {
      $scope.noResults2 = true;
      dataCheck = true;
    }

    if ($scope.adultPaxValue == undefined) {
      $scope.noPax = true;
	  
      switch (globalData.getbookingType()) {
	case 'P':
	  $translate('Text438').then(function (txtMsg) {
	    $scope.paxNumError = txtMsg;
	  });
	  break;
	case 'F':
	  $translate('Text439').then(function (txtMsg) {
	    $scope.paxNumError = txtMsg;
	  });
	  break;
	case 'D':
	  $translate('Text440').then(function (txtMsg) {
	    $scope.paxNumError = txtMsg;
	  });
	  break;
	case 'B':
	  $translate('Text441').then(function (txtMsg) {
	    $scope.paxNumError = txtMsg;
	  });
	  break;
	case 'Y':
	  $translate('Text442').then(function (txtMsg) {
	    $scope.paxNumError = txtMsg;
	  });
	  break;
	case 'R':
	  $translate('Text443').then(function (txtMsg) {
	    $scope.paxNumError = txtMsg;
	  });
	  break;
	case 'T':
	  $translate('Text444').then(function (txtMsg) {
	    $scope.paxNumError = txtMsg;
	  });
	  break;
      }
      
      dataCheck = true;
    }
    if (globalData.getbookingType() === 'B') {
      // console.log("$rootScope.loggedInUser.ARIA_User.ESV_remaining = " + $rootScope.loggedInUser.ARIA_User.ESV_remaining);

      if(!shouldBypassABSCheckOnBlueVoucher && (globalData.getStationCode($scope.originName) == 'BEABS' || globalData.getStationCode($scope.destinationName) == 'BEABS')) {
        $('#searchABSModal').modal({
          backdrop: 'static'
        });
              
        return;
      }

      if (typeof ($rootScope.loggedInUser.ARIA_User.ESV_remaining) !== 'undefined') {
        let dependantsLen = $rootScope.loggedInUser.ARIA_User.dependants?.length || 0;

        if (($rootScope.loggedInUser.ARIA_User.ESV_remaining) < ($scope.adultPaxValue - dependantsLen)) {
          dataCheck = true;
          $scope.enquiryError = true;
          $translate('Text310').then(function (txtMsg) {
            $scope.enquiryErrMsg = txtMsg; //"There are insufficient vouchers to make this booking.";
          });
        }
      }
    } else if (globalData.getbookingType() === 'R') {
      if ($scope.adultPaxValue == undefined) {
        $translate('Text406').then(function (txtMsg) {
          $scope.paxNumError = txtMsg;
        });
      }
      if (typeof ($rootScope.loggedInUser.ARIA_User.TSV_Balance) !== 'undefined') {
        if (parseInt($rootScope.loggedInUser.ARIA_User.TSV_Balance) < ($scope.adultPaxValue * ($scope.returnJourney ? 2 : 1))) {
          dataCheck = true;
          $scope.enquiryError = true;
          $translate('Text310').then(function (txtMsg) {
            $scope.enquiryErrMsg = txtMsg; //"There are insufficient vouchers to make this booking.";
          });
        }
      }
    } else if (globalData.getbookingType() === 'M') {
      if ($rootScope.loggedInUser.ARIA_User.Momentum_Voucher_Current_Balance < ($scope.adultPaxValue * ($scope.StaffVoucherUnitValue * 2))) {
        dataCheck = true;
        $scope.enquiryError = true;
        $translate('Text310').then(function (txtMsg) {
          $scope.enquiryErrMsg = txtMsg; //"There are insufficient vouchers to make this booking.";
        });
      }
      if (dataCheck === false) {
        if ($scope.returnJourney === false) {
          dataCheck = true;
          $scope.enquiryError = true;
          $translate('Text311').then(function (txtMsg) {
            $scope.enquiryErrMsg = txtMsg; // "Only Return journey can be booked with Momentum Voucher.";
          });
        }
      }
    } else if (globalData.getbookingType() === 'I') {
      if ($rootScope.loggedInUser.ARIA_User.Mitie_Voucher_Current_Balance < ($scope.adultPaxValue * ($scope.StaffVoucherUnitValue * 2))) {
        dataCheck = true;
        $scope.enquiryError = true;
        $translate('Text310').then(function (txtMsg) {
          $scope.enquiryErrMsg = txtMsg; //"There are insufficient vouchers to make this booking.";
        });
      }
    }

    if (dataCheck == false) {
      if (($scope.returnJourney === true && ($scope.departTimeValue !== '' || $scope.returnTimeValue !== '')) ||
        ($scope.returnJourney === false && ($scope.departTimeValue !== ''))) {
          if ($scope.showCurr == false) {
            if (globalData.getbookingType() === 'R') {
              $scope.currency = 'EUR';
            } else {
              $scope.currency = 'GBP';
            }
          }

          globalData.setCurrency($scope.currency);

          var enquiryRequest = {
            isReturnBooking: '',
            origin: '',
            originNumeric: '',
            destination: '',
            destinationNumeric: '',
            dDate: '',
            dTime: '',
            paxNum: '',
            returnOrigin: '',
            returnOriginNumeric: '',
            returnDestination: '',
            returnDestinationNumeric: '',
            returndDate: '',
            returndTime: '',
            booking_type: ''
          };
          enquiryRequest.isReturnBooking = $scope.returnJourney;
          enquiryRequest.booking_type = globalData.getbookingType();
          enquiryRequest.originFullName = $scope.originName;
          enquiryRequest.origin = globalData.getStationCode($scope.originName);
          enquiryRequest.originNumeric = globalData.getStationCodeNumeric($scope.originName);
          enquiryRequest.destinationFullName = $scope.destinationName;
          enquiryRequest.destination = globalData.getStationCode($scope.destinationName);
          enquiryRequest.destinationNumeric = globalData.getStationCodeNumeric($scope.destinationName);
          enquiryRequest.dDate = convertDate($scope.departDateValue);
          enquiryRequest.dDateFull = $scope.departDateValue;
          enquiryRequest.bookingHorizon = $scope.bookingHorizon;
          enquiryRequest.dTime = convertTime($scope.departTimeValue);
          enquiryRequest.paxNum = $scope.adultPaxValue;

          if ($scope.returnJourney) {
            enquiryRequest.returnOriginFullName = $scope.destinationName;
            enquiryRequest.returnOrigin = globalData.getStationCode($scope.destinationName);
            enquiryRequest.returnOriginNumeric = globalData.getStationCodeNumeric($scope.destinationName);
            enquiryRequest.returnDestinationFullName = $scope.originName;
            enquiryRequest.returnDestination = globalData.getStationCode($scope.originName);
            enquiryRequest.returnDestinationNumeric = globalData.getStationCodeNumeric($scope.originName);
            enquiryRequest.returndDate = convertDate($scope.returnDateValue);
            enquiryRequest.returndDateFull = $scope.returnDateValue;
            enquiryRequest.bookingHorizon = $scope.bookingHorizon;
            enquiryRequest.returndTime = convertTime($scope.returnTimeValue);
          } else {
            enquiryRequest.returnOrigin = '';
            enquiryRequest.returnOriginNumeric = '';
            enquiryRequest.returnDestination = '';
            enquiryRequest.returnDestinationNumeric = '';
            enquiryRequest.returnOriginFullName = '';
            enquiryRequest.returnDestinationFullName = '';
            enquiryRequest.returndDate = '';
            enquiryRequest.returndTime = '';
            enquiryRequest.returndDateFull = '';
          }
          globalData.setBookingEnquiryData(enquiryRequest);
          $location.path('/outbound');
        } else {
          $scope.enquiryError = true;
        }
    }
  };
}]);
