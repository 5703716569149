import moment from 'moment';

export function openModal() {
  //console.log('openModal');
  document.getElementById('modal').style.display = 'block';
  document.getElementById('fade').style.display = 'block';
}

export function closeModal() {
  //console.log('closeModal');
  document.getElementById('modal').style.display = 'none';
  document.getElementById('fade').style.display = 'none';
}

var month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export function convertDate(sDate) {
  var result;
  //  console.log(sDate);
  if (sDate.getDate() < 10) {
    result = '0' + '' + sDate.getDate() + '' + month[sDate.getMonth()] + sDate.getFullYear();
  }
  else {
    result = sDate.getDate() + '' + month[sDate.getMonth()] + sDate.getFullYear();
  }
  return result;
}

export function convertToDOB(sDate) {
  var result;
  if (sDate.getDate() < 10) {
    result = '0' + '' + sDate.getDate() + month[sDate.getMonth()] + sDate.getFullYear();
  }
  else {
    result = sDate.getDate() + month[sDate.getMonth()] + sDate.getFullYear();
  }
  return result;
}
export function convertTime(sTime) {
  var result;
  if (sTime.getHours() < 10 ) {
    if (sTime.getMinutes() < 10) {
      result =   '0' + '' + sTime.getHours() + '' + '0' + '' + sTime.getMinutes();
    }
    else if (sTime.getMinutes() >= 10 )  {
      result =   '0' + '' + sTime.getHours() + '' + sTime.getMinutes();
    }
    else {
      result =   '';
    }
  }
  else if(sTime.getHours() >= 10 ) {
    if (sTime.getMinutes() < 10) {
      result =   sTime.getHours() + '' + '0' + '' + sTime.getMinutes();
    }
    else if (sTime.getMinutes() >= 10){
      result =   sTime.getHours() + '' + sTime.getMinutes();
    }
    else {
      result = '';
    }
  }
  else {
    result =   '';
  }
  return result;
}

export function dateDiff(date1, date2) {
  //assumung date1 and date2 are date
  var diffDays = (date2 - date1) / (1000 * 60 * 60 * 24);
  return Math.floor(diffDays);
}

export function convetToShortDate(input){
  let output;
  const months = { 'january': 'JAN', 'february': 'FEB', 'march': 'MAR',
    'april': 'APR','may': 'MAY', 'june': 'JUN',
    'july': 'JUL','august': 'AUG','september': 'SEP',
    'october': 'OCT', 'november': 'NOV', 'december': 'DEC' };
  input = input.toLowerCase();
  output = `${input.split(' ')[0]}${months[input.split(' ')[1]]}`;
  return output;
}

export function getPaxName(tnr_names, paxID){
  let result;
  result = tnr_names.find(function(item){
    return (item.s3_passenger_id === paxID);
  });
  return `${result.name_surname}/${result.name_initials}`;
}

export function getPaxNumber(tnr_names, paxID){
  let result;
  result = tnr_names.findIndex(function(item){
    return (item.s3_passenger_id === paxID);
  });
  return result + 1;
}

export function sortFares(data){
  data.FareAvailabilityServiceOut.solutions_out.forEach(train => {
    let fares = [];
    let result;
    result = train.fares.findIndex(findThirdClass);
    if (result>-1){
      fares.push(train.fares[result]);
    }
    result = train.fares.findIndex(findSecondClass);
    if (result>-1){
      fares.push(train.fares[result]);
    }
    result = train.fares.findIndex(findFirstClass);
    if (result>-1){
      fares.push(train.fares[result]);
    }
    train.fares = fares;
  });
}

export function findFirstClass(item){
  return (item.usage === 'BP' || item.usage === 'PREMIUM' || item.usage === 'PREMIER');
}

export function findSecondClass(item){
  return (item.usage === 'SP' || item.usage === 'COMFORT' || item.usage === 'PLUS');
}

export function findThirdClass(item){
  return (item.usage === 'STD' || item.usage === 'ER_STANDARD' || item.usage === 'STANDARD');
}

export function nonceGenerator () {
  var text = '';
  var iLength = 20;

  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for(var i = 0; i < iLength; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function s3_ProcessPaxInfo(data) {
  if (data){
    if (data.seats){
      data.seats.map(seat => {
        if (seat.seats_placements){
          seat.seats_placements.map(seatPlacement => {
            seatPlacement.placement_pax_name = getPaxName(data.tnr.tnr_names,seatPlacement.s3_placement_passenger_id);
          });
        }
      });
    }
    if (data.tnr){
      if (data.tnr.tnr_tkts){
        data.tnr.tnr_tkts.map(tkt => {
          tkt.tktd_pax_num = getPaxNumber(data.tnr.tnr_names, tkt.s3_tktd_passenger_id);
        });
      }
    }
    // console.log("s3_ProcessPaxInfo = ", data)
  }
  return data;
}

export function getQuarter(date) {
  const quartersMonth = [
    { start: 0, end: 9 },
    { start: 3, end: 6 },
    { start: 6, end: 3 },
    { start: 9, end: 0 }
  ];

  const dateInMoment = moment(date);

  if (!date || !dateInMoment.isValid()) {
    return 0;
  }

  let quarter = 0;

  quartersMonth.forEach(({ start, end }, i) => {
    const startDate = moment().startOf('year').add(start, 'month');
    const endDate = moment().endOf('year').subtract(end, 'month');

    if (dateInMoment.isBetween(startDate, endDate, undefined, '[]')) {
      quarter = i + 1;
    }
  });

  return quarter;
}

export function formatQuarterEndDate(date) {
  const quartersMonth = [
    { start: 0, end: 9 },
    { start: 3, end: 6 },
    { start: 6, end: 3 },
    { start: 9, end: 0 }
  ];

  const dateInMoment = moment(date);

  if (!date || !dateInMoment.isValid()) {
    return 0;
  }

  let endDateStr = 0;

  quartersMonth.forEach(({ start, end }, i) => {
    const startDate = moment().startOf('year').add(start, 'month');
    const endDate = moment().endOf('year').subtract(end, 'month');

    if (dateInMoment.isBetween(startDate, endDate, undefined, '[]')) {
      endDateStr = endDate.format("Do MMMM YYYY");
    }
  });

  return endDateStr;
}
